import type { Row, SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import { Button, Loading } from '@fleet/components';
import { Checkbox } from '@fleet/components';

import { DataTable, DeviceStatusIndicator } from '../../atoms';
import { DeviceStatus } from '../../atoms';
import { Routes } from '../../../const';
import type { Device, GetDevicesQueryFilters } from '../../../hooks';
import { useGetDevices } from '../../../hooks';
import { useUserHasFullAccess } from '../../../hooks';
import { timeAgoFromNow } from '../../../utils';

interface DeviceColumns extends Device {
  lastOnline: React.ReactNode;
}

const columnHelper = createColumnHelper<DeviceColumns>();

const DEFAULT_SORTING: SortingState = [{ id: 'lastOnline', desc: false }];

type DevicesProps = {
  deviceQueryFilters: GetDevicesQueryFilters;
  enableRowSelection?: boolean;
  selectedRows?: string[];
  handleCheckboxChange?: (id: string) => void;
  handleMainCheckboxChange?: (isChecked: boolean, data: Device[]) => void;
  refreshDevices?: boolean;
  setRefreshDevices?: (value: boolean) => void;
  handleCheckboxToggle?: () => void;
  showTableCheckboxes?: boolean;
};

const getLastOnlineSortPriority = (value: string | object) => {
  if (typeof value === 'object') return 0;
  if (value === 'Never') return 3;
  if (value === 'Today') return 1;
  return 2;
};

export const Devices: React.FC<DevicesProps> = ({
  deviceQueryFilters,
  enableRowSelection = false,
  selectedRows = [],
  handleCheckboxChange = () => {},
  handleMainCheckboxChange = () => {},
  refreshDevices = false,
  setRefreshDevices = () => {},
  handleCheckboxToggle = () => {},
  showTableCheckboxes = false
}) => {
  const { data, isLoading, mutate: mutateDevices } = useGetDevices(deviceQueryFilters);
  const router = useRouter();
  const hasUserFullAccess = useUserHasFullAccess();

  if (refreshDevices) {
    mutateDevices();
    setRefreshDevices(false);
  }

  // TODO: move the checkbox component logic below, to be injected as a prop
  const dataSet = useMemo(() => data?.results || [], [data]);
  const columns = useMemo(() => {
    const columns = [
      ...(enableRowSelection && showTableCheckboxes
        ? [
            columnHelper.display({
              id: 'selectDevices',
              header: () => (
                <Checkbox
                  checked={selectedRows.length === dataSet.length}
                  onChange={isChecked => {
                    handleMainCheckboxChange(isChecked, dataSet);
                  }}
                />
              ),
              cell: ({ row }) => (
                <Checkbox
                  checked={selectedRows.includes(row.original.id)}
                  onChange={() => {
                    handleCheckboxChange(row.original.id);
                  }}
                />
              )
            })
          ]
        : []),
      columnHelper.accessor(device => device.name, {
        header: 'Name',
        cell: props => props.getValue(),
        id: 'deviceName'
      }),
      columnHelper.accessor(device => device.serialNumber, {
        header: 'Serial Number',
        cell: props => props.getValue()
      }),
      columnHelper.accessor(device => device.softwareVersion, {
        header: 'Version',
        cell: props => props.getValue() || '-'
      }),
      columnHelper.accessor(device => device.project.name, {
        header: 'Project',
        cell: props => props.getValue(),
        id: 'projectName'
      }),
      columnHelper.accessor(device => device.lastOnline, {
        id: 'lastOnline',
        header: 'Last Online',
        cell: props => props.getValue(),
        sortingFn: (rowA, rowB, columnId) => {
          const priorityA = getLastOnlineSortPriority(rowA.getValue(columnId));
          const priorityB = getLastOnlineSortPriority(rowB.getValue(columnId));

          if (priorityA === priorityB) {
            // If both values have the same priority and are dates, compare values for relative times
            if (priorityA === 2 && priorityB === 2) {
              return dayjs(rowA.original.lastSeen).isAfter(dayjs(rowB.original.lastSeen)) ? -1 : 1;
            }
            return 0; // They are the same
          }

          // Sort by priority
          return priorityA - priorityB;
        }
      })
    ];
    return columns;
  }, [
    handleCheckboxChange,
    handleMainCheckboxChange,
    selectedRows,
    dataSet,
    enableRowSelection,
    showTableCheckboxes
  ]);

  const onRowClick = useCallback(
    (row: Row<Device>) => {
      showTableCheckboxes
        ? handleCheckboxChange(row.original.id)
        : router.push(Routes.DEVICE.replace('[deviceId]', row.original.id));
    },
    [router, showTableCheckboxes, handleCheckboxChange]
  );

  const dataTable = useMemo(() => {
    return dataSet.map(dataRow => {
      let lastOnline;
      if (dataRow.presence)
        lastOnline = <DeviceStatusIndicator status={DeviceStatus.Online} displayDot={false} />;
      if (!dataRow.presence)
        lastOnline = dataRow.lastSeen ? timeAgoFromNow(dataRow.lastSeen) : 'Never';

      return {
        ...dataRow,
        lastOnline
      };
    });
  }, [dataSet]);

  if (isLoading) {
    return <Loading />;
  }

  // todo: remove hasUserFullAccess once move devices is ready to deploy
  return (
    <>
      {dataTable.length > 0 ? (
        <div className='w-full flex-col'>
          <div className='mb-5 flex h-11 items-center justify-between'>
            <h2 className='text-lg'>
              {dataTable.length} Device{dataTable.length > 1 && 's'}
            </h2>
            {hasUserFullAccess && enableRowSelection && (
              <Button variant='solid' onClick={handleCheckboxToggle}>
                Move devices
              </Button>
            )}
          </div>
          <DataTable
            columns={columns}
            data={dataTable}
            enableSorting
            defaultSorting={DEFAULT_SORTING}
            {...(hasUserFullAccess && { onRowClick })}
          />
        </div>
      ) : (
        <div className='text-center text-gray-500'>No devices to show.</div>
      )}
    </>
  );
};
