import type { BarDatum, BarTooltipProps } from '@nivo/bar';

import { DateAggregation, dayJS } from '../../../const';
import type { BlindsightIndexAggregate } from '../../../hooks';
import { generateCSVDates, generateGraphDates } from '../../../utils';

export const generateAggregatedBlindsightIndex = ({
  indexData,
  count,
  periodEndAt,
  dateAggregation
}: {
  indexData: BlindsightIndexAggregate[] | undefined;
  count: number;
  periodEndAt: string;
  dateAggregation: DateAggregation;
}) => {
  const aggregatedIndex = new Array<number>(count).fill(0);

  const diffUnit = dateAggregation === DateAggregation.WEEKLY ? 'week' : 'day';

  indexData?.map(({ periodStartAt, blindsightIndex }) => {
    const offset = Math.floor(
      count - dayJS({ date: periodEndAt }).diff(dayJS({ date: periodStartAt }), diffUnit, true)
    );
    aggregatedIndex[offset] += blindsightIndex === null ? 0 : blindsightIndex;
  });

  return aggregatedIndex;
};

export const generateGraphData = ({
  aggregatedIndex,
  periodEndAt,
  dateAggregation,
  count
}: {
  aggregatedIndex: number[];
  periodEndAt: string;
  dateAggregation: DateAggregation;
  count: number;
}) => {
  return aggregatedIndex.map((value, index) => ({
    ...(dateAggregation === DateAggregation.DAILY
      ? { day: generateGraphDates({ count, index, periodEndAt, dateAggregation }) }
      : { week: generateGraphDates({ count, index, periodEndAt, dateAggregation }) }),
    value
  }));
};

export const generateCSVData = ({
  aggregatedIndex,
  org,
  project,
  device,
  periodEndAt,
  count,
  dateAggregation
}: {
  aggregatedIndex: number[];
  org?: string;
  project?: string;
  device?: string;
  periodEndAt: string;
  count: number;
  dateAggregation: DateAggregation;
}) => {
  return aggregatedIndex.map((value, index) => ({
    ...(org ? { 'Organization name': org } : {}),
    ...(project ? { 'Project name': project } : {}),
    ...(device ? { 'Device name': device } : {}),
    ...generateCSVDates({ count, index, periodEndAt, dateAggregation }),
    'Blindsight Index': value
  }));
};

export const getCustomTooltip = ({ value }: BarTooltipProps<BarDatum>) => (
  <p>Blindsight Index: {Math.round(value).toLocaleString()}</p>
);
