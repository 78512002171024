import { useCallback, useContext, useMemo } from 'react';

import { MultiSelect } from '@fleet/components';

import { ENABLED_FILTERS_PER_ROUTE, FilterLabels } from '../../../../const';
import { useAuthContext, useGetCurrentRoute, useGetProjects } from '../../../../hooks';
import { SelectedFiltersContext } from '../../../../providers';
import { trackEvent } from '../../../../utils';
import type { SelectOption } from '../../../atoms';

export const ProjectFilters: React.FC = () => {
  const { currentOrganizationId, roles } = useAuthContext();
  const { selectedFilters, setSelectedFilters } = useContext(SelectedFiltersContext);
  const currentRoute = useGetCurrentRoute();
  const { data: projects } = useGetProjects({
    organizationIds: currentOrganizationId
  });

  const projectsUserHasAccessTo = useMemo(() => {
    const projectIds = Object.keys(roles?.projects || {});
    return projects?.results.filter(({ id }) => projectIds.includes(id));
  }, [projects?.results, roles?.projects]);

  const projectOptions: SelectOption[] = useMemo(() => {
    const sortedProjects = projectsUserHasAccessTo?.sort((a, b) => a.name.localeCompare(b.name)) || [];
    return sortedProjects.length > 1
      ? [
        { label: 'All', value: 'All' },
        ...sortedProjects.map(({ name: projectName, id: projectId }) => ({
          label: projectName,
          value: projectId
        }))
      ]
      : [
        {
          label: sortedProjects?.[0]?.name ?? 'No Projects',
          value: sortedProjects?.[0]?.id
        }
      ];
  }, [projectsUserHasAccessTo]);

  const handleProjectChange = useCallback(
    (value: string) => {
      trackEvent('project-filter-changed', {});
      setSelectedFilters({
        ...selectedFilters,
        [FilterLabels.PROJECT]: value.includes('All') ? undefined : value,
      });
    },
    [selectedFilters, setSelectedFilters]
  );

  const filterDisabled =
    !ENABLED_FILTERS_PER_ROUTE[currentRoute].includes(FilterLabels.PROJECT) ||
    !projects ||
    projects?.results.length < 2;

  return (
    <MultiSelect
      label='Project'
      options={projectOptions}
      onChange={handleProjectChange}
      value={selectedFilters.projectIds || ''}
      disabled={filterDisabled}
      dataPw='button-filter-project'
    />
  );
};