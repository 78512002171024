import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { Button, Error, Loading } from '@fleet/components';
import { snakeToSentenceCase } from '@fleet/utils';

import { DeleteProjectUserModal, EditProjectUserModal, InviteProjectUserModal } from '../..';
import { InviteOrganizationUserModal } from '../..';
import { DataTable } from '../../atoms';
import { OrganizationUserRole, ProjectUserRole, UserTypes } from '../../../const';
import { SUPPORT_EMAIL_DOMAINS } from '../../../const/Users';
import type { ProjectUser } from '../../../hooks';
import { useAuthContext, useGetProjects } from '../../../hooks';
import { timeAgoFromNow } from '../../../utils';
import { sortTimeAgoFromNowColumn } from '../../../utils';
import { getUserFullName } from '../../../utils/User';

const columnHelper = createColumnHelper<ProjectUser>();

const DEFAULT_SORTING: SortingState = [{ id: 'lastActive', desc: false }];

type ProjectUsers = {
  projectId: string;
};

export const ProjectUsers: React.FC<ProjectUsers> = ({ projectId }) => {
  const [inviteProjectUserModalOpen, setInviteProjectUserModalOpen] = useState<boolean>(false);
  const [editProjectUser, setEditProjectUser] = useState<ProjectUser | undefined>();
  const [deleteProjectUser, setDeleteProjectUser] = useState<ProjectUser | undefined>();
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState<boolean>(false);

  const { currentOrganizationRole, roles, user } = useAuthContext();
  const { data, isLoading, mutate } = useGetProjects({
    projectIds: projectId
  });

  const defaultColumns: ColumnDef<ProjectUser, string>[] = useMemo(
    () => [
      columnHelper.accessor(
        link =>
          (link.user.email === user?.email
            ? `${getUserFullName(link.user)} (You)`
            : getUserFullName(link.user)) || 'No name',
        {
          header: 'Name',
          cell: props => props.getValue(),
          id: 'name'
        }
      ),
      columnHelper.accessor(link => link.user.email, {
        header: 'Email',
        cell: props => props.getValue(),
        id: 'email'
      }),
      columnHelper.accessor(
        link => (link.user.lastActive ? timeAgoFromNow(link.user.lastActive) : 'Never'),
        {
          header: 'Last Active',
          cell: props => props.getValue(),
          id: 'lastActive',
          sortingFn: sortTimeAgoFromNowColumn
        }
      ),
      columnHelper.accessor(link => link.role.toString(), {
        header: 'Role',
        cell: props => snakeToSentenceCase(props.getValue()) || 'No Role',
        id: 'role'
      })
    ],
    [user?.email]
  );

  const canEdit =
    currentOrganizationRole === OrganizationUserRole.ADMIN ||
    roles?.projects?.[projectId] === ProjectUserRole.ADMIN;

  const project = data?.results[0];

  const columns = useMemo(() => {
    const columns = [...defaultColumns];

    if (!canEdit) return columns;

    columns.push(
      columnHelper.display({
        header: 'Actions',
        cell: props => {
          return (
            <div className='flex space-x-2'>
              <PencilIcon
                className='h-9 w-9 cursor-pointer p-2 text-brand'
                onClick={() => setEditProjectUser(props.row.original)}
              />
              <TrashIcon
                className='h-9 w-9 cursor-pointer p-2 text-brand'
                onClick={() => setDeleteProjectUser(props.row.original)}
              />
            </div>
          );
        }
      })
    );

    return columns;
  }, [canEdit, defaultColumns]);

  const defaultProjectsRoles = useMemo(
    () => [
      {
        projectId,
        role: ProjectUserRole.REVIEWER
      }
    ],
    [projectId]
  );

  const tableData = useMemo(() => {
    let projectUsers = project?.projectUsers || [];
    if (user?.type === UserTypes.CUSTOMER)
      projectUsers = projectUsers.filter(({ user }) => user.type === UserTypes.CUSTOMER);
    if (!SUPPORT_EMAIL_DOMAINS.some(domain => user?.email.endsWith(domain))) {
      projectUsers = projectUsers.filter(
        ({ user }) => !SUPPORT_EMAIL_DOMAINS.some(domain => user.email.endsWith(domain))
      );
    }

    return projectUsers.map(projectUser => {
      return {
        ...projectUser,
        ...(projectUser.user.email === user?.email && { className: 'font-medium' })
      };
    });
  }, [project?.projectUsers, user?.email, user?.type]);

  if (isLoading) {
    return <Loading />;
  }

  if (!project) {
    return <Error error='Project users not found' />;
  }

  return (
    <>
      <div className='mb-5 flex h-11 items-center justify-between'>
        <h2 className='text-lg'>{tableData.length} Project Users</h2>
        <Button
          icon={<PlusIcon className='h-5 w-4' />}
          variant='solid'
          onClick={() => setInviteProjectUserModalOpen(true)}
          disabled={!canEdit}
        >
          Invite User
        </Button>
      </div>

      {project.projectUsers.length > 0 ? (
        <DataTable
          columns={columns}
          data={tableData}
          enableSorting
          defaultSorting={DEFAULT_SORTING}
        />
      ) : (
        <div className='text-center text-gray-500'>This project has no users.</div>
      )}

      {canEdit && (
        <>
          <EditProjectUserModal
            projectUser={editProjectUser}
            isOpen={Boolean(editProjectUser)}
            onClose={() => {
              setEditProjectUser(undefined);
              mutate();
            }}
          />
          <DeleteProjectUserModal
            projectUser={deleteProjectUser}
            isOpen={Boolean(deleteProjectUser)}
            onClose={() => {
              setDeleteProjectUser(undefined);
              mutate();
            }}
          />
          <InviteProjectUserModal
            project={project}
            isOpen={inviteProjectUserModalOpen}
            setInviteUserModalOpen={setInviteUserModalOpen}
            onClose={() => {
              setInviteProjectUserModalOpen(false);
              mutate();
            }}
          />

          <InviteOrganizationUserModal
            isOpen={inviteUserModalOpen}
            defaultProjectsRoles={defaultProjectsRoles}
            onClose={() => {
              mutate();
              setInviteUserModalOpen(false);
            }}
          />
        </>
      )}
    </>
  );
};
