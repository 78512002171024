import { useRouter } from 'next/router';
import { memo, useCallback, useMemo } from 'react';

import { TruckIconSVG } from '@fleet/components';

import { getLabelValueColorClass, SummaryGraph } from '../../molecules';
import {
  AlertType,
  FilterLabels,
  ReviewStatus,
  Routes,
  SHORT_DEFAULT_FILTERS,
  twoWeeksAgo,
  WEEK_IN_S
} from '../../../const';
import {
  AggregatedType,
  type Device,
  useAuthContext,
  useGetAggregatedAlerts,
  useGetBlindsightIndex
} from '../../../hooks';
import type { QueryParams } from '../../../types';
import {
  getAggregatedAlerts,
  getAggregatedAlertsChange,
  getAggregatedCriticalAlertsChange,
  getAggregatedIndex,
  getAggregatedIndexChange,
  getAlertsChangeTooltipText,
  getCurrentAggregatedIndexAverage,
  getIndexChangeTooltipText,
  getPreviousAggregatedIndexAverage,
  getStoredTimezone
} from '../../../utils';

interface DeviceSummarySectionProps {
  device: Device;
}

export const DeviceSummarySection: React.FC<DeviceSummarySectionProps> = memo(
  ({
    device: {
      name: deviceName,
      serialNumber: deviceSerial,
      id: deviceId,
      organizationId,
      projectId
    }
  }) => {
    const { currentOrganizationId } = useAuthContext();
    const router = useRouter();

    const aggregatedParams = {
      organizationIds: currentOrganizationId,
      projectIds: projectId,
      deviceIds: deviceId,
      startAt: twoWeeksAgo.toISOString(),
      reportingPeriodSecs: WEEK_IN_S * 2,
      timezone: getStoredTimezone()
    };

    const { data: alertsData } = useGetAggregatedAlerts({
      queryParams: {
        ...aggregatedParams,
        alertTypes: [AggregatedType.ANY]
      },
      variant: 'daily'
    });

    const { data: indexData } = useGetBlindsightIndex({
      queryParams: aggregatedParams,
      variant: 'daily'
    });

    const navigateToDeviceDetections = useCallback(() => {
      router.push({
        pathname: Routes.DETECTIONS,
        query: {
          ...SHORT_DEFAULT_FILTERS,
          [FilterLabels.REVIEW_STATUS]: [ReviewStatus.CONFIRMED, ReviewStatus.NOT_REVIEWED].join(','),
          projectIds: projectId,
          deviceIds: deviceId
        } satisfies QueryParams
      });
    }, [router, projectId, deviceId]);

    const navigateToDeviceAnalytics = useCallback(() => {
      router.push({
        pathname: Routes.ANALYTICS,
        query: {
          [FilterLabels.REVIEW_STATUS]: [ReviewStatus.CONFIRMED, ReviewStatus.NOT_REVIEWED].join(','),
          projectIds: projectId,
          deviceIds: deviceId
        } satisfies QueryParams
      });
    }, [router, projectId, deviceId]);

    const aggregatedIndex = useMemo(() => getAggregatedIndex(indexData), [indexData]);
    const prevIndexAverage = useMemo(
      () => getPreviousAggregatedIndexAverage(aggregatedIndex),
      [aggregatedIndex]
    );
    const currIndexAverage = useMemo(
      () => getCurrentAggregatedIndexAverage(aggregatedIndex),
      [aggregatedIndex]
    );
    const aggregatedIndexChange = getAggregatedIndexChange(prevIndexAverage, currIndexAverage);

    const currAggregatedIndex = aggregatedIndex
      .slice(7, 14)
      .map(value => (value === null ? 0 : value));

    const { aggregatedAlerts, aggregatedCriticalAlerts } = useMemo(
      () => getAggregatedAlerts(alertsData),
      [alertsData]
    );
    const aggregatedAlertsChange = useMemo(
      () => getAggregatedAlertsChange(aggregatedAlerts),
      [aggregatedAlerts]
    );
    const aggregatedCriticalAlertsChange = useMemo(
      () => getAggregatedCriticalAlertsChange(aggregatedCriticalAlerts),
      [aggregatedCriticalAlerts]
    );

    const currAggregatedAlerts = aggregatedAlerts.slice(7, 14);
    const currAggregatedCriticalAlerts = aggregatedCriticalAlerts.slice(7, 14);

    const aggregatedAlertsSum = currAggregatedAlerts.reduce((a, b) => a + b, 0);
    const aggregatedCriticalAlertsSum = currAggregatedCriticalAlerts.reduce((a, b) => a + b, 0);

    const labelValueColorClass = getLabelValueColorClass(currIndexAverage);

    return (
      <div className='flex h-44 w-full flex-row items-center'>
        <TruckIconSVG className='mr-6 flex-[0_0_2.75rem]' />
        <div className='flex flex-grow items-center justify-between pr-6'>
          <div className='flex w-60 flex-col space-y-2'>
            <p className='truncate font-medium'>{deviceName}</p>
            <p className='text-sm text-secondary-text'>{deviceSerial}</p>
            <div className='flex space-x-3 text-sm text-hyperlink'>
              <button
                onClick={navigateToDeviceDetections}
                id='device-summary-detections'
                data-pw={`button-project-card-detections-${deviceName}`}
              >
                Detections {'>'}
              </button>
              <button
                onClick={navigateToDeviceAnalytics}
                id='device-summary-analytics'
                data-pw={`button-project-card-summary-analytics-${deviceName}`}
              >
                Detailed Analytics {'>'}
              </button>
            </div>
          </div>
          <div className='flex flex-grow items-center justify-around'>
            <SummaryGraph
              aggregatedData={currAggregatedIndex}
              labelValue={currIndexAverage}
              label='Blindsight Index'
              percentChange={aggregatedIndexChange}
              changeTooltipText={getIndexChangeTooltipText(aggregatedIndex, aggregatedIndexChange)}
              labelValueColorClass={labelValueColorClass}
            />
            <SummaryGraph
              aggregatedData={currAggregatedCriticalAlerts}
              labelValue={aggregatedCriticalAlertsSum}
              label={`Critical detection${aggregatedCriticalAlertsSum === 1 ? '' : 's'}`}
              percentChange={aggregatedCriticalAlertsChange}
              changeTooltipText={getAlertsChangeTooltipText(
                aggregatedCriticalAlerts,
                aggregatedCriticalAlertsChange
              )}
              labelValueColorClass={labelValueColorClass}
              isLink={true}
              projectId={projectId}
              deviceId={deviceId}
              query={{ alertType: AlertType.CRITICAL }}
            />
            <SummaryGraph
              aggregatedData={currAggregatedAlerts}
              labelValue={aggregatedAlertsSum}
              label={`Detection${aggregatedAlertsSum === 1 ? '' : 's'}`}
              percentChange={aggregatedAlertsChange}
              changeTooltipText={getAlertsChangeTooltipText(
                aggregatedAlerts,
                aggregatedAlertsChange
              )}
              labelValueColorClass={labelValueColorClass}
              isLink={true}
              projectId={projectId}
              deviceId={deviceId}
              query={{ alertType: AlertType.GENERAL }}
            />
          </div>
        </div>
      </div>
    );
  }
);
