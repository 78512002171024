import { useCallback, useEffect, useState } from 'react';

const getColoursFromVariant = (variant: string) => {
  switch (variant) {
    case 'alert':
      return ['rgba(255, 125, 51, 0.5)', 'rgba(255, 125, 51, 0.2)'];
    case 'critical':
      return ['rgba(84, 84, 250, 0.5)', 'rgba(84, 84, 250, 0.2)'];
    default:
      return ['rgba(255, 75, 75, 0.5)', 'rgba(255, 75, 75, 0.2)'];
  }
};

interface RoiOverlayProps {
  zones: number[][][] | null | undefined;
  variant: 'alert' | 'critical' | 'exclusion';
}

export const RoiOverlay: React.FC<RoiOverlayProps> = ({ zones, variant }) => {
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);

  const drawPolygon = useCallback(
    (ctx: CanvasRenderingContext2D, points: number[][]) => {
      if (!canvas) return;

      const [strokeColour, fillColour] = getColoursFromVariant(variant);

      ctx.beginPath();
      ctx.moveTo(points[0][0] * canvas.width, points[0][1] * canvas.height);
      for (const point of points) ctx.lineTo(point[0] * canvas.width, point[1] * canvas.height);
      ctx.closePath();
      ctx.strokeStyle = strokeColour;
      ctx.stroke();
      ctx.fillStyle = fillColour;
      ctx.fill();
    },
    [canvas, variant]
  );

  useEffect(() => {
    if (!canvas || !zones) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    for (const zone of zones) drawPolygon(ctx, zone);
  }, [canvas, drawPolygon, zones]);

  return (
    <canvas
      ref={setCanvas}
      className='w-full h-full absolute top-0 left-0 pointer-events-none'
      width={576}
      height={432}
    />
  );
};
