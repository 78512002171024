import { BackspaceIcon, PlayIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { ButtonProps } from '@fleet/components';
import { Button, FiltersIconSVG } from '@fleet/components';
import { getEnumKeyFromValue, snakeToSentenceCase } from '@fleet/utils';

import { filterCloseDisabled, removeFilter } from './FilterRow.utils';
import { Chip } from '../../atoms';
import { FilterModal } from '../../organisms';
import {
  AlertType,
  DateOptions,
  ENABLED_FILTERS_PER_ROUTE,
  FilterLabels,
  ReviewStatus,
  ReviewStatusLabel,
  RiskState,
  Routes
} from '../../../const';
import {
  useAuthContext,
  useGetCurrentRoute as useGetCurrentRoute,
  useGetDevices,
  useGetProjects,
  useRetrieveFilters
} from '../../../hooks';
import type { Filters } from '../../../types';
import { displayDateFilterLabel, generateURLSearchParams, preloadResponse } from '../../../utils';

interface FilterRowProps {
  reviewButtonProps?: Omit<ButtonProps, 'children'>;
}

export const FilterRow: React.FC<FilterRowProps> = ({ reviewButtonProps }) => {
  const { currentOrganizationProjectIds } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState<Filters>({});

  const queryFilters = useRetrieveFilters();
  const router = useRouter();
  const currentRoute = useGetCurrentRoute();

  const { data: projects } = useGetProjects({
    projectIds: currentOrganizationProjectIds
  });
  const { data: devices } = useGetDevices({});

  projects?.results.map(project =>
    preloadResponse('get', `/devices${generateURLSearchParams({ projectIds: project.id })}`)
  );

  useEffect(() => {
    if (queryFilters !== undefined) setFilters(queryFilters);
  }, [queryFilters]);

  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const clearFilters = useCallback(() => {
    router.replace({
      query: {}
    });
  }, [router]);

  const displayFilterLabel = useCallback(
    (filter: FilterLabels) => {
      switch (filter) {
        case FilterLabels.PROJECT: {
          const projectIds = filters[filter]?.split(',') || [];
          const projectNames = projectIds
            .map(projectId => projects?.results.find(({ id }) => id === projectId)?.name)
            .filter(Boolean)
            .join(', ');
          return projectNames || 'Unknown Project';
        }
        case FilterLabels.DEVICE: {
          const deviceIds = filters[filter]?.split(',') || [];
          const deviceNames = deviceIds
            .map(deviceId => devices?.results?.find(({ id }) => id === deviceId)?.name)
            .filter(Boolean)
            .join(', ');
          return deviceNames || 'Unknown Device';
        }
        case FilterLabels.DATE:
          if (currentRoute === Routes.ACTION_CENTER) return;
          if (Object.values(DateOptions).includes(filters[filter] as DateOptions)) {
            return filters[filter];
          }
          return displayDateFilterLabel(filters[filter]);
        case FilterLabels.REVIEW_STATUS:
          if (!filters[filter] || filters[filter] === ReviewStatus.ALL) return 'All Review Statuses';
          return filters[filter]
            .split(',')
            .map(status => ReviewStatusLabel[status as ReviewStatus])
            .filter(Boolean)
            .join(', ');
        case FilterLabels.RISK_STATE: {
          if (!filters[filter] || filters[filter] === RiskState.ANY_RISK) return 'Any risk';
          return filters[filter]
            .split(',')
            .map(state => snakeToSentenceCase(getEnumKeyFromValue(RiskState, state as RiskState)))
            .filter(Boolean)
            .join(', ');
        }
        case FilterLabels.ALERT_TYPE:
          if (!filters[filter]) return;
          if (filters[filter] === AlertType.CRITICAL) return 'Critical detections';
          if (filters[filter] === AlertType.GENERAL) return 'General detections';
          if ((filters[filter]).split(',').length === 2) return 'All detections';
          return;
        case FilterLabels.DETECTION_TYPE:
          if (filters[filter]?.split(',').length === 3) return 'All detection types';
          return snakeToSentenceCase(filters[filter]?.replace(',', ', '));
        case FilterLabels.GEOLOCATION_BOUNDS:
          if (filters[filter]) return 'Selected Map Area';
          return;
        default:
          if (filters[filter]?.toLowerCase() === 'all') return;
          return snakeToSentenceCase(filters[filter]);
      }
    },
    [currentRoute, devices, filters, projects]
  );

  const clearFiltersIcon = useMemo(() => <BackspaceIcon className='h-5 w-5' />, []);
  const filtersIcon = useMemo(() => <FiltersIconSVG />, []);
  const reviewModeIcon = useMemo(() => <PlayIcon className='h-5 w-5' />, []);

  const getDataPwAttribute = (filter: FilterLabels) => {
    const attributeMap: { [key in FilterLabels]?: string } = {
      [FilterLabels.PROJECT]: 'label-filter-project-name',
      [FilterLabels.DEVICE]: 'label-filter-device-name',
      [FilterLabels.ALERT_TYPE]: 'label-filter-alert-type',
      [FilterLabels.DETECTION_TYPE]: 'label-filter-detection-type',
      [FilterLabels.REVIEW_STATUS]: 'label-filter-review-status',
      [FilterLabels.RISK_STATE]: 'label-filter-risk-state',
      [FilterLabels.DATE]: 'label-filter-date'
    };
    return attributeMap[filter];
  };

  return (
    <>
      <FilterModal isOpen={isModalOpen} onClose={closeModal} />
      <div className='flex h-16 items-center justify-between px-6 py-4'>
        <div className='flex min-w-0 items-center space-x-2'>
          {Object.values(FilterLabels).map(filter => {
            if (!filters[filter]) return null;

            const filterEnabled = ENABLED_FILTERS_PER_ROUTE[currentRoute].includes(filter);
            if (!filterEnabled && filter !== FilterLabels.DATE) return null;

            const text = displayFilterLabel(filter);

            const onClose = filterCloseDisabled(filter, filters)
              ? undefined
              : () => removeFilter(filter, filters, router);

            return (
              <Chip
                key={filter}
                onClose={onClose}
                className='max-w-[16rem] capitalize'
                text={text}
                dataPw={getDataPwAttribute(filter)}
              />
            );
          })}
        </div>
        <div className='ml-2 flex items-center space-x-3'>
          <Button onClick={clearFilters} icon={clearFiltersIcon} id='clear-filters'>
            Clear all
          </Button>
          <div className='h-6 w-[1px] bg-gray-200' />
          <Button onClick={openModal} icon={filtersIcon} id='filters-modal' dataPw='button-filter'>
            Filters
          </Button>
          {reviewButtonProps ? (
            <Button
              variant='solid'
              icon={reviewModeIcon}
              {...reviewButtonProps}
              id='review-mode'
              dataPw='button-review-mode'
            >
              Review Mode
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );
};
