import { ReviewStatus } from '../const';

export const handleReviewStatus = (reviewStatus: string | undefined) => {
  let statusArray: ReviewStatus[] = [];

  if (reviewStatus) {
    statusArray = reviewStatus.split(',') as ReviewStatus[];
  }

  statusArray = statusArray.flatMap(status => {
    switch (status) {
      case ReviewStatus.ALL:
        return [
          ReviewStatus.CONFIRMED,
          ReviewStatus.NOT_REVIEWED,
          ReviewStatus.REJECTED,
          ReviewStatus.IGNORED
        ];
      case 'DEFAULT' as ReviewStatus:
        return [
          ReviewStatus.CONFIRMED,
          ReviewStatus.NOT_REVIEWED
        ];
      default:
        return [status];
    }
  });

  const uniqueStatuses = [...new Set(statusArray)];

  return uniqueStatuses.join(',') as ReviewStatus;
};