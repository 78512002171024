import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import type { Routes } from '../const';
import { DEFAULT_FILTERS, ENABLED_FILTERS_PER_ROUTE, QueryParamKeys } from '../const';
import type { Filters, QueryParams } from '../types';

export const useRetrieveFilters = () => {
  const [filters, setFilters] = useState<Filters>();

  const router = useRouter();
  const query = router.query as QueryParams | undefined;
  const isReady = router.isReady;
  const currentRoute = router.route as Routes;

  useEffect(() => {
    if (!isReady || !currentRoute || !query) return;

    // Get allowed filters for the current route
    const enabledFilters = ENABLED_FILTERS_PER_ROUTE[currentRoute] || [];

    // Create an object that merges default filters with any filters from the query
    const retrievedFilters: Filters = { ...DEFAULT_FILTERS, ...query };

    // Remove the key if it's not part of the enabled filters for the current route or if its value is an empty string
    for (const key of Object.keys(retrievedFilters)) {
      if (
        !enabledFilters.includes(key as keyof QueryParams) ||
        retrievedFilters[key as keyof Filters] === ''
      ) {
        delete retrievedFilters[key as keyof Filters];
      }
    }

    // Clear query parameters that are not in enabledFilters, except for those in QueryParamKeys
    const preservedKeys = Object.values(QueryParamKeys);

    // Remove query parameters that are not in enabledFilters and are not included in QueryParamKeys
    const filteredQuery: QueryParams = {};
    for (const key of Object.keys(query)) {
      if (
        enabledFilters.includes(key as keyof QueryParams) ||
        preservedKeys.includes(key as keyof QueryParams)  // Always keep keys listed in QueryParamKeys
      ) {
        filteredQuery[key as keyof QueryParams] = query[key as keyof QueryParams];
      }
    }

    // Check if filteredQuery is different from the current query
    const isQueryChanged =
      Object.keys(filteredQuery).length !== Object.keys(query).length ||
      Object.keys(filteredQuery).some(
        key => filteredQuery[key as keyof QueryParams] !== query[key as keyof QueryParams]
      );


    if (isQueryChanged) {
      router.replace(
        {
          pathname: router.pathname,
          query: filteredQuery,
        },
        undefined,
        { shallow: true }
      );
    }

    setFilters(retrievedFilters);
  }, [query, router, isReady, currentRoute]);

  return filters;
};