import { useCallback, useEffect, useState } from 'react';

import { Button } from '@fleet/components';

import { Modal } from '../../atoms';
import type { Organization } from '../../../hooks';
import { useAuthContext } from '../../../hooks';

interface SwitchOrganizationsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SwitchOrganizationsModal: React.FC<SwitchOrganizationsModalProps> = ({
  isOpen,
  onClose
}) => {
  const { allOrganizations, currentOrganizationId, changeOrganization } = useAuthContext();
  const [orgsToShow, setOrgsToShow] = useState<Organization[]>([]);

  const handleOrganizationChange = useCallback(
    (organizationId: string) => {
      changeOrganization(organizationId);
      onClose();
      window.location.replace('/'); // Hacky workaround. I think we need to decicate more time to know whats really going on with router.push()
    },
    [changeOrganization, onClose]
  );

  useEffect(() => {
    if (allOrganizations && isOpen) {
      setOrgsToShow(
        allOrganizations
          .filter(organization => organization.id !== currentOrganizationId)
          .sort((a, b) => a.name.localeCompare(b.name))
      );
    }
  }, [allOrganizations, currentOrganizationId, isOpen]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} title='Switch Organizations'>
      <p>Please select an organization below:</p>
      <ul className='py-5'>
        {orgsToShow.map(organization => (
          <li key={organization.id} className='pb-4'>
            <Button
              type='button'
              onClick={() => handleOrganizationChange(organization.id)}
              className='text-md w-full text-left'
            >
              {organization.name}
            </Button>
          </li>
        ))}
      </ul>
    </Modal>
  );
};
