export const ForkliftSideSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='186'
    height='104'
    viewBox='0 0 186 104'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_176_575)'>
      <mask id='mask0_176_575' maskUnits='userSpaceOnUse' x='0' y='-1' width='186' height='106'>
        <path d='M0.166016 -0.00593185H185.166V104.006H0.166016V-0.00593185Z' fill='white' />
      </mask>
      <g mask='url(#mask0_176_575)'>
        <path
          d='M73.941 77.631C74.2434 77.7991 74.6248 77.69 74.7927 77.3876C74.9608 77.0853 74.8519 76.704 74.5495 76.5359C74.3566 76.4288 74.1215 76.4309 73.9307 76.5417'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M87.2238 77.0268C87.2238 73.167 84.0947 70.038 80.2349 70.038C76.3751 70.038 73.2461 73.167 73.2461 77.0268C73.2461 80.8866 76.3751 84.0156 80.2349 84.0156C84.0947 84.0156 87.2238 80.8866 87.2238 77.0268Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M86.5586 77.0269C86.5586 73.5347 83.7275 70.7037 80.2354 70.7037C76.7432 70.7037 73.9121 73.5347 73.9121 77.0269C73.9121 80.5192 76.7432 83.3501 80.2354 83.3501C83.7275 83.3501 86.5586 80.5192 86.5586 77.0269Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M91.8831 77.0272C91.8831 70.5942 86.668 65.3791 80.235 65.3791C73.802 65.3791 68.5869 70.5942 68.5869 77.0272C68.5869 83.4602 73.802 88.6753 80.235 88.6753C86.668 88.6753 91.8831 83.4602 91.8831 77.0272Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M90.2191 77.0272C90.2191 71.5131 85.749 67.0431 80.235 67.0431C74.7209 67.0431 70.251 71.5131 70.251 77.0272C70.251 82.5413 74.7209 87.0112 80.235 87.0112C85.749 87.0112 90.2191 82.5413 90.2191 77.0272Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M82.6337 72.042H77.8213'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M82.5645 77.0269C82.5645 75.7403 81.5216 74.6973 80.235 74.6973C78.9484 74.6973 77.9053 75.7403 77.9053 77.0269C77.9053 78.3135 78.9484 79.3564 80.235 79.3564C81.5216 79.3564 82.5645 78.3135 82.5645 77.0269Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M81.393 77.0271C81.393 76.3876 80.8746 75.8692 80.2351 75.8692C79.5956 75.8692 79.0771 76.3876 79.0771 77.0271C79.0771 77.6666 79.5956 78.1851 80.2351 78.1851C80.8746 78.1851 81.393 77.6666 81.393 77.0271Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M79.9655 76.6882C79.9655 76.6025 79.8961 76.5331 79.8105 76.5331C79.7248 76.5331 79.6553 76.6025 79.6553 76.6882C79.6553 76.7739 79.7248 76.8433 79.8105 76.8433C79.8961 76.8433 79.9655 76.7739 79.9655 76.6882Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M79.9655 77.4787C79.9655 77.393 79.8961 77.3236 79.8105 77.3236C79.7248 77.3236 79.6553 77.393 79.6553 77.4787C79.6553 77.5644 79.7248 77.6338 79.8105 77.6338C79.8961 77.6338 79.9655 77.5644 79.9655 77.4787Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M77.7725 82.0117H82.6472'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M84.2284 77.0269C84.2284 74.8212 82.4405 73.0333 80.2348 73.0333C78.0292 73.0333 76.2412 74.8212 76.2412 77.0269C76.2412 79.2325 78.0292 81.0205 80.2348 81.0205C82.4405 81.0205 84.2284 79.2325 84.2284 77.0269Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M77.6342 82.7905C77.8962 82.5648 77.9256 82.1692 77.6997 81.9071C77.4739 81.645 77.0785 81.6157 76.8164 81.8416C76.707 81.9357 76.6336 82.0647 76.6084 82.2068'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M76.5986 71.8537C76.6003 72.1995 76.8821 72.4787 77.228 72.477C77.574 72.4753 77.853 72.1936 77.8514 71.8476C77.8503 71.6246 77.7307 71.419 77.5374 71.3077'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M74.7363 77.4721L77.1726 81.6919'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M80.8171 76.6882C80.8171 76.6025 80.7476 76.5331 80.662 76.5331C80.5763 76.5331 80.5068 76.6025 80.5068 76.6882C80.5068 76.7739 80.5763 76.8433 80.662 76.8433C80.7476 76.8433 80.8171 76.7739 80.8171 76.6882Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M80.8171 77.4787C80.8171 77.393 80.7476 77.3236 80.662 77.3236C80.5763 77.3236 80.5068 77.393 80.5068 77.4787C80.5068 77.5644 80.5763 77.6338 80.662 77.6338C80.7476 77.6338 80.8171 77.5644 80.8171 77.4787Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M83.8729 82.1989C83.8753 81.853 83.5969 81.5705 83.2508 81.5682C82.9049 81.5657 82.6226 81.8442 82.6201 82.1901C82.6185 82.4188 82.7418 82.6304 82.9416 82.7417'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M82.9951 71.3379C82.6783 71.4769 82.5341 71.8463 82.673 72.1631C82.812 72.48 83.1814 72.6241 83.4983 72.4852C83.7455 72.3767 83.8954 72.1221 83.8704 71.8532'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M86.5261 76.3881C86.2428 76.1895 85.8522 76.2581 85.6536 76.5414C85.455 76.8247 85.5236 77.2153 85.8069 77.4138C86.0308 77.5708 86.3305 77.5644 86.5476 77.3981'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M77.1113 72.4669L74.6963 76.6499'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M83.3633 81.5791L85.7797 77.3937'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M85.6869 76.499L83.3906 72.5218'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M88.2261 77.1042C88.2261 81.5173 84.6487 85.0947 80.2356 85.0947C75.8226 85.0947 72.2451 81.5173 72.2451 77.1042C72.2451 72.6912 75.8226 69.1137 80.2356 69.1137C84.6487 69.1137 88.2261 72.6912 88.2261 77.1042Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M89.1739 77.104C89.1739 82.0406 85.172 86.0425 80.2354 86.0425C75.2988 86.0425 71.2969 82.0406 71.2969 77.104C71.2969 72.1673 75.2988 68.1655 80.2354 68.1655C85.172 68.1655 89.1739 72.1673 89.1739 77.104Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M132.264 79.6857C132.51 79.8225 132.821 79.7338 132.958 79.4877C133.094 79.2415 133.006 78.9311 132.76 78.7943C132.603 78.7071 132.411 78.709 132.256 78.7991'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M143.076 79.1941C143.076 76.0524 140.529 73.5054 137.387 73.5054C134.245 73.5054 131.698 76.0524 131.698 79.1941C131.698 82.3359 134.245 84.8828 137.387 84.8828C140.529 84.8828 143.076 82.3359 143.076 79.1941Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M142.534 79.1939C142.534 76.3513 140.23 74.0469 137.387 74.0469C134.544 74.0469 132.24 76.3513 132.24 79.1939C132.24 82.0364 134.544 84.3408 137.387 84.3408C140.23 84.3408 142.534 82.0364 142.534 79.1939Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M146.869 79.1941C146.869 73.9578 142.624 69.7129 137.387 69.7129C132.151 69.7129 127.906 73.9578 127.906 79.1941C127.906 84.4304 132.151 88.6753 137.387 88.6753C142.624 88.6753 146.869 84.4304 146.869 79.1941Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M145.514 79.1941C145.514 74.7059 141.876 71.0673 137.387 71.0673C132.899 71.0673 129.261 74.7059 129.261 79.1941C129.261 83.6823 132.899 87.3208 137.387 87.3208C141.876 87.3208 145.514 83.6823 145.514 79.1941Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M139.34 75.1362H135.423'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M139.284 79.1941C139.284 78.1469 138.435 77.298 137.388 77.298C136.34 77.298 135.491 78.1469 135.491 79.1941C135.491 80.2414 136.34 81.0903 137.388 81.0903C138.435 81.0903 139.284 80.2414 139.284 79.1941Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M138.329 79.1938C138.329 78.6732 137.907 78.2513 137.387 78.2513C136.866 78.2513 136.444 78.6732 136.444 79.1938C136.444 79.7144 136.866 80.1362 137.387 80.1362C137.907 80.1362 138.329 79.7144 138.329 79.1938Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M137.168 78.9182C137.168 78.8485 137.111 78.792 137.041 78.792C136.972 78.792 136.915 78.8485 136.915 78.9182C136.915 78.988 136.972 79.0444 137.041 79.0444C137.111 79.0444 137.168 78.988 137.168 78.9182Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M137.168 79.5618C137.168 79.492 137.111 79.4355 137.041 79.4355C136.972 79.4355 136.915 79.492 136.915 79.5618C136.915 79.6315 136.972 79.688 137.041 79.688C137.111 79.688 137.168 79.6315 137.168 79.5618Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M135.383 83.2515H139.351'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M140.638 79.1937C140.638 77.3984 139.183 75.9431 137.387 75.9431C135.592 75.9431 134.137 77.3984 134.137 79.1937C134.137 80.989 135.592 82.4443 137.387 82.4443C139.183 82.4443 140.638 80.989 140.638 79.1937Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M135.27 83.8853C135.484 83.7015 135.508 83.3796 135.324 83.1662C135.14 82.9529 134.818 82.929 134.605 83.1127C134.516 83.1894 134.456 83.2945 134.436 83.4102'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M134.428 74.9834C134.429 75.265 134.659 75.4921 134.94 75.4907C135.222 75.4894 135.449 75.2601 135.447 74.9785C135.447 74.7969 135.349 74.6296 135.192 74.539'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M132.911 79.5565L134.894 82.9912'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M137.861 78.9182C137.861 78.8485 137.805 78.792 137.735 78.792C137.665 78.792 137.608 78.8485 137.608 78.9182C137.608 78.988 137.665 79.0444 137.735 79.0444C137.805 79.0444 137.861 78.988 137.861 78.9182Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M137.861 79.5618C137.861 79.492 137.805 79.4355 137.735 79.4355C137.665 79.4355 137.608 79.492 137.608 79.5618C137.608 79.6315 137.665 79.688 137.735 79.688C137.805 79.688 137.861 79.6315 137.861 79.5618Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M140.349 83.4034C140.351 83.1219 140.124 82.892 139.843 82.89C139.561 82.888 139.331 83.1147 139.329 83.3963C139.328 83.5825 139.428 83.7546 139.591 83.8452'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M139.634 74.5632C139.376 74.6763 139.259 74.977 139.372 75.2349C139.485 75.4928 139.786 75.6101 140.044 75.497C140.245 75.4087 140.367 75.2015 140.347 74.9826'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M142.508 78.6738C142.277 78.5121 141.959 78.568 141.797 78.7985C141.636 79.0291 141.692 79.3471 141.922 79.5087C142.104 79.6364 142.349 79.6313 142.525 79.4958'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M134.845 75.482L132.879 78.8867'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M139.934 82.8989L141.9 79.4921'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M141.824 78.7642L139.955 75.5268'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M143.892 79.2568C143.892 82.8488 140.98 85.7607 137.388 85.7607C133.796 85.7607 130.884 82.8488 130.884 79.2568C130.884 75.6647 133.796 72.7528 137.388 72.7528C140.98 72.7528 143.892 75.6647 143.892 79.2568Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M144.664 79.2565C144.664 83.2748 141.406 86.5322 137.388 86.5322C133.37 86.5322 130.112 83.2748 130.112 79.2565C130.112 75.2383 133.37 71.9809 137.388 71.9809C141.406 71.9809 144.664 75.2383 144.664 79.2565Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M94.0879 71.8926H96.9157H104.327L105.302 65.7277H104.083L102.988 71.8222'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M96.8943 71.8223L96.3797 65.3791L84.4346 58.9562'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M83.6214 62.6128H74.8184V62.0711H83.6214V62.6128Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M83.6212 61.9923L92.4243 23.2019C92.4243 23.2019 93.6432 18.1909 98.1124 17.2429H127.637V15.3252H97.9553C97.9553 15.3252 93.2369 15.7531 90.9345 21.5767L81.996 60.5811H80.2354L75.9536 57.3849H75.2678L72.9221 56.3827H68.832V57.3308H71.2969L71.9741 58.2788V61.3937L71.2969 62.4555L69.5363 62.4772V63.1543H71.2969L75.2244 65.7275H77.2559'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M85.6535 52.3197C85.6535 52.7686 85.2897 53.1323 84.8409 53.1323C84.3921 53.1323 84.0283 52.7686 84.0283 52.3197C84.0283 51.8709 84.3921 51.5071 84.8409 51.5071C85.2897 51.5071 85.6535 51.8709 85.6535 52.3197Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M84.1633 59.0917C84.1633 59.5405 83.7995 59.9043 83.3507 59.9043C82.9019 59.9043 82.5381 59.5405 82.5381 59.0917C82.5381 58.6429 82.9019 58.2791 83.3507 58.2791C83.7995 58.2791 84.1633 58.6429 84.1633 59.0917Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M95.9461 70.3326C95.9461 70.7065 95.6429 71.0098 95.269 71.0098C94.895 71.0098 94.5918 70.7065 94.5918 70.3326C94.5918 69.9587 94.895 69.6554 95.269 69.6554C95.6429 69.6554 95.9461 69.9587 95.9461 70.3326Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M66.6922 70.4C66.6922 70.6618 66.4799 70.874 66.2182 70.874C65.9564 70.874 65.7441 70.6618 65.7441 70.4C65.7441 70.1382 65.9564 69.926 66.2182 69.926C66.4799 69.926 66.6922 70.1382 66.6922 70.4Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M66.6922 84.8912C66.6922 85.153 66.4799 85.3652 66.2182 85.3652C65.9564 85.3652 65.7441 85.153 65.7441 84.8912C65.7441 84.6294 65.9564 84.4172 66.2182 84.4172C66.4799 84.4172 66.6922 84.6294 66.6922 84.8912Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M78.747 60.1074C78.747 60.8181 78.171 61.394 77.4604 61.394C76.7498 61.394 76.1738 60.8181 76.1738 60.1074C76.1738 59.3968 76.7498 58.8208 77.4604 58.8208C78.171 58.8208 78.747 59.3968 78.747 60.1074Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M75.9018 62.0708H74.8184V61.1228H75.9018V62.0708Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M75.3604 57.4664V61.123'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M81.4551 63.0405L92.2896 67.2172L92.5605 69.5195L81.4551 64.43V63.0405Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M76.1729 62.6126V65.7275'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M76.8496 62.6126V65.7275'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M81.4551 62.6126V65.3213'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M96.3518 80.7607H91.3408'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M91.4766 80.0835L95.8104 78.7292'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M95.8105 78.729H125.199'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M141.9 67.9405H148.402C148.402 67.9405 150.742 68.5517 151.717 71.0671V80.3732C151.717 80.3732 151.744 82.6836 149.306 82.7378V76.6975C148.223 69.2488 141.9 67.9405 141.9 67.9405ZM141.9 67.9405H131.699C124.926 70.966 125.145 80.7605 125.145 80.7605H96.3525L92.6742 67.0428L83.7275 62.0709'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M131.7 67.8945L127.096 17.243'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M123.438 17.2429L127.231 65.7275'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M121.065 17.2431C121.065 17.2431 122.355 17.7848 122.626 20.6289H123.703'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M126.418 73.9888L128.856 75.0723'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M126.012 75.0723L128.314 76.1558'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M127.908 71.1449L130.752 72.3638'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M128.855 70.0617L131.97 71.416'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M139.961 67.8946V70.0615'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M143.889 68.7073L149.306 80.7607'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M146.191 70.4673L149.306 77.5098'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M141.451 70.603H144.702'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M146.699 77.4025C146.699 77.4025 148.223 81.3019 149.306 81.5728'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M149.306 82.7378C149.306 82.7378 147.274 81.1322 146.868 79.9479'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M129.939 68.9781L134.815 70.0615'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M144.024 70.7386V72.3638'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M66.8277 85.5005H64.5254V46.2252H66.8277V85.5005Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M66.8283 71.0093H65.6094V62.3416H66.8283V71.0093Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M66.8283 85.5005H65.6094V83.469H66.8283V85.5005Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M64.5254 67.0432H63.9241V87.0113H28.4951L48.409 88.4805H65.0618C65.0618 88.4805 66.8278 86.8553 66.8278 85.501'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M65.6094 85.5009V87.8032'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M66.8281 83.4692H70.4848'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M71.4125 84.6325C71.4125 84.6325 69.4553 85.7711 68.3447 83.4688'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M71.8604 85.1232C71.8604 85.1232 69.282 87.6678 67.9873 83.4694'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M66.8281 85.501H69.1305'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M79.5591 22.3892H69.1309V21.1703H79.5591V22.3892Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M78.6103 31.0571H70.8906V30.1091H78.6103V31.0571Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M77.7981 30.1089H72.7871V22.3893H77.7981V30.1089Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M75.3605 25.5044H73.7354V22.9312H75.3605V25.5044Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M75.9017 26.3169H73.0576V25.5043H75.9017V26.3169Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M74.8186 30.1089H72.7871V27.8065H74.8186V30.1089Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M73.5995 34.7139H72.1883C71.7709 34.7139 71.4326 34.3756 71.4326 33.9582V33.3027C71.4326 32.8853 71.7709 32.547 72.1883 32.547H73.5995V34.7139Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M72.8914 21.1707V20.3798H67.6299V83.4375'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M74.8186 32.5469H72.7871V31.0571H74.8186V32.5469Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M72.7871 34.7137V56.3828'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M74.8184 32.5467V57.1953'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M75.9023 31.0572V57.3311'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M76.7149 31.0572L75.9023 34.7139'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M73.5996 34.7139H75.902'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M76.8496 22.3893V30.1089'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M75.3604 23.6083L76.8501 25.5044'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M74.8184 27.8066H76.8498'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M75.3604 22.9307L76.0375 22.3889'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M72.7871 22.9307H74.1414'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M72.1104 21.1703V22.3892'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M74.6826 21.1703V22.3892'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M72.3809 30.1091V31.0571'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M73.4639 30.1091V31.0571'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M76.8496 30.1091V31.0571'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M73.7354 33.6304H74.6834'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M74.8184 32.5469L75.9018 32.0051'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M72.7871 46.9028H75.7666'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M81.9961 60.5812V61.9355'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M80.3712 60.581L79.5586 62.0708'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M72.9229 64.2377V68.0298'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M72.9229 65.9985C72.9229 65.9985 74.7672 65.8437 75.4702 66.395'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M92.5605 69.5199L95.9463 80.7607'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M92.2891 67.2173V66.9464'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M146.184 82.7377H153.797C153.797 82.7377 155.379 82.9882 156.203 79.9478H155.553V75.3431H156.837V74.1079H156.512V71.1447L146.869 71.0669'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M153.196 82.7378C153.196 82.7378 155.265 81.3022 154.994 78.9999C154.994 78.9999 154.71 78.2956 154.107 78.5123C153.504 78.729 153.369 79.4062 153.369 79.4062C153.369 79.4062 152.909 81.4647 151.717 82.7378'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M149.306 79.9478H153.098'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M155.536 79.9482H154.994'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M149.306 81.5732H151.337'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M152.963 80.896H154.588'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M152.285 82.1147H153.775'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M152.963 80.896V81.9795'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M152.285 71.1447V79.9478'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M151.548 62.7482L152.286 66.134C152.286 66.134 152.015 67.082 156.078 67.082C156.078 67.082 156.484 67.082 156.484 67.4883'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M130.026 49.4861C130.026 49.4861 147.949 48.5928 153.368 50.998L154.994 53.8096C154.994 53.8096 155.401 54.6222 154.588 55.0285H150.693L149.983 55.5702L150.81 59.3623'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M148.629 59.3622H154.994L155.536 62.748H149.577'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M152.692 59.3622L153.234 62.748'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M150.693 55.0284C150.693 55.0284 148.088 55.0284 147.817 56.2473L150.254 65.7275H105.291'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M150.254 65.7277C150.254 65.7277 151.066 67.4884 156.484 67.4884L156.511 71.145'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M152.286 67.0817L151.744 71.0093'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M152.285 66.1338H150.66'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M153.234 50.9653L130.075 50.1527'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M130.645 56.2891L143.452 55.0287C143.452 55.0287 147.287 54.5608 148.5 50.7995'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M130.26 52.0489H142.258L143.577 55.0088'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M134.814 49.4756V52.0488'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M136.304 50.4238L135.763 51.9136'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M136.305 49.3399V50.4233'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M148.087 51.9136H153.775'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M148.899 50.8299L150.66 55.0283'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M151.066 50.9654L152.962 55.0283'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M147.816 56.2476H150.119'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M89.9324 21.9829H88.4155C88.1612 21.9829 87.9551 21.7768 87.9551 21.5224V20.0056C87.9551 19.7513 88.1612 19.5451 88.4155 19.5451H89.9324C90.1867 19.5451 90.3929 19.7513 90.3929 20.0056V21.5224C90.3929 21.7768 90.1867 21.9829 89.9324 21.9829Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M87.3058 26.8584H86.6016V23.2017H87.3058C88.1884 23.2017 88.9039 23.9172 88.9039 24.7998V25.2603C88.9039 26.1429 88.1884 26.8584 87.3058 26.8584Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M89.9865 22.9312H88.3613V21.9831H89.9865V22.9312Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M87.3057 26.8586C87.3057 26.8586 87.6937 27.5345 89.5853 27.4675'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M89.0391 23.0663V26.4521'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M88.3613 26.4521H89.7156'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M89.9873 22.9312H90.529'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M126.182 52.3198L107.788 54.3431L104.375 58.8206V65.1182H127.183'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M126.466 55.9572L109.489 57.7373C109.489 57.7373 106.509 58.1436 106.374 60.7168'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M104.375 60.7166H116.808C116.808 60.7166 118.021 60.5812 118.021 61.8001C118.021 63.019 116.893 65.1182 116.893 65.1182'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M104.375 61.6511H116.54C116.54 61.6511 117.073 61.6646 116.938 62.2064C116.802 62.7481 115.93 65.1182 115.93 65.1182'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M104.984 65.1182V62.5043H106.106L107.349 63.3846H113.785V65.1182'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M107.999 65.0503H105.832V64.1023H107.999V65.0503Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M107.999 60.7167V64.1025'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M126.467 56.5808L109.218 58.55C109.218 58.55 107.219 58.8119 107.203 60.7124'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M110.437 54.0805L108.812 57.8726'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M104.479 59.4976H107.593'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M94.3203 64.2378H104.342'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M115.573 53.4863H105.529C105.529 53.4863 103.194 51.9132 105.529 50.4234C105.529 50.4234 107.143 49.5776 117.458 49.3234C117.458 49.3234 118.157 48.2565 119.511 48.1211C119.511 48.1211 121.759 47.9856 121.651 50.4234C121.542 52.8612 120.843 52.9067 120.843 52.9067'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M121.542 49.4756L123.167 39.3182C123.167 39.3182 124.35 38.8453 123.732 37.3471C123.617 37.0693 123.39 36.8472 123.105 36.7522C122.849 36.6667 122.491 36.6219 122.041 36.745C122.041 36.745 116.071 38.9119 115.962 42.5686C115.854 46.2252 118.415 48.4715 118.415 48.4715'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linejoin='round'
        />
        <path
          d='M122.201 45.8061L123.167 46.4963L122.577 50.5591H121.654'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M105.529 53.4867H104.245V54.3431H107.788L105.832 58.4143V65.1182'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M109.896 49.7467V53.4033'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M112.875 49.6112V53.4033'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M123.168 39.3183C123.168 39.3183 121.136 40.4018 118.834 40.9435C116.532 41.4852 118.292 44.4647 118.292 44.4647C118.292 44.4647 119.636 46.4962 121.943 46.9702'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M118.021 41.4849H122.762'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M90.8112 30.3126L92.9423 32.8857C92.9423 32.8857 93.3729 33.3597 93.1021 34.3077L91.206 42.1628C91.206 42.1628 91.1311 42.5373 90.8699 42.9519C90.708 43.209 90.466 43.4065 90.1837 43.5181L87.5811 44.5464'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M90.3863 32.2761L91.8832 33.495C91.8832 33.495 92.1541 33.7659 92.0187 34.5785C91.8832 35.3911 90.258 41.7564 90.258 41.7564C90.258 41.7564 90.2625 42.6607 87.958 42.8857'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M87.5075 44.8711L89.0393 47.6827V48.5115C89.0393 48.5115 90.9788 47.7152 93.1917 50.4238C93.1917 50.4238 93.552 50.8301 93.1917 51.575L89.1747 56.7891H88.205L86.8398 58.3368V60.1812'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M87.6641 57.4015C87.6641 57.4015 88.2801 58.6074 89.6751 58.6463C91.07 58.685 91.8826 59.6032 91.8826 59.6032V60.181H90.7321L93.1015 62.6748V63.5605H104.342'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M90.7996 60.1748H86.8721'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M85.1113 59.2271L91.4766 48.9342'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M89.0392 48.5113C89.0392 48.5113 87.0752 49.1514 86.1611 50.8032'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M88.7412 47.137C88.7412 47.137 93.3723 38.9119 94.8621 39.7245L97.5003 41.4851C97.5003 41.4851 98.2479 42.1623 97.7061 43.2458L93.0935 50.3066'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M100.974 42.145L93.8561 37.4405C93.669 37.3168 93.6175 37.0647 93.7412 36.8775C93.8649 36.6903 94.117 36.6389 94.3041 36.7626L101.422 41.467C101.609 41.5908 101.661 41.8428 101.537 42.03C101.413 42.2173 101.161 42.2688 100.974 42.145Z'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M95.1338 39.8599L96.0818 39.0473'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M97.2998 41.2144L97.977 40.2663'
          stroke='black'
          stroke-width='0.203148'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_176_575'>
        <rect
          width='185'
          height='104.012'
          fill='white'
          transform='translate(0.166016 -0.00585938)'
        />
      </clipPath>
    </defs>
  </svg>
);
