import { useApi } from './useApiHooks';
import type { Device } from './useGetDevices';
import type { User } from './useGetOrganizations';
import type { ProjectUserRole } from '../../const';
import { generateURLSearchParams } from '../../utils';

export interface ProjectUser {
  id: string;
  userId: string;
  user: User;
  projectId: string;
  role: ProjectUserRole;
  createdAt: string;
  className?: string;
}

export interface ProjectBase {
  id: string;
  name: string;
  organizationId: string;
  createdAt: string;
  address: string;
  xid?: number;
  street?: string;
  city?: string;
  state?: string;
  postcode?: string;
  country?: string;
  fullAddress?: string;
  externalId?: string;
}

export interface Project extends ProjectBase {
  projectUnique: string;
  projectUsers: ProjectUser[];
  devices: Device[];
  organization: {
    id: string;
    name: string;
  };
}

export interface GetProjectsQuery {
  organizationIds?: string;
  projectIds?: string;
  select?: (keyof Project)[];
}

export interface GetProjectsResponse {
  results: Project[];
}

export const useGetProjects = (queryParams: GetProjectsQuery = {}, disableQuery?: boolean) =>
  useApi<GetProjectsResponse>(
    'get',
    disableQuery ? null : `/projects${generateURLSearchParams(queryParams)}`
  );
