import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import { Button } from '@fleet/components';

import { ReviewStatus, ReviewStatusLabel } from '../../../const';

interface ReviewButtonsProps {
  onReview: (reviewStatus: ReviewStatus) => void;
  mutatingStatus: ReviewStatus | undefined;
  isMutating: boolean;
  reviewStatus: ReviewStatus | undefined;
  onPrev: () => void;
  onNext: () => void;
  prevAlertId: string;
  nextAlertId: string;
}

export const ReviewButtons: React.FC<ReviewButtonsProps> = ({
  onReview,
  onPrev,
  onNext,
  mutatingStatus,
  isMutating,
  reviewStatus,
  prevAlertId,
  nextAlertId
}) => {
  const getReviewButtonClasses = (reviewBtn: ReviewStatus) => {
    return reviewBtn === reviewStatus
      ? 'bg-brand text-gray-100 hover:opacity-80 active:opacity-70'
      : 'bg-primary-element';
  };

  const onReviewRejected = useCallback(() => onReview(ReviewStatus.REJECTED), [onReview]);
  const onReviewIgnored = useCallback(() => onReview(ReviewStatus.IGNORED), [onReview]);
  const onReviewNotReviewed = useCallback(() => onReview(ReviewStatus.NOT_REVIEWED), [onReview]);
  const onReviewConfirmed = useCallback(() => onReview(ReviewStatus.CONFIRMED), [onReview]);

  return (
    <div className='mt-2 flex w-full space-x-1 [&>*]:place-content-center [&>*]:border-0 [&>*]:py-3 [&>*]:text-sm'>
      <Button
        className='w-[11.25%] bg-primary-element px-3'
        onClick={onPrev}
        disabled={prevAlertId === '' || isMutating}
        id='detection-modal-prev'
        dataPw='button-detection-modal-prev'
      >
        <ChevronLeftIcon className='h-5 w-5' strokeWidth={2} />
      </Button>
      <Button
        className={twMerge('w-[15%]', getReviewButtonClasses(ReviewStatus.REJECTED))}
        onClick={onReviewRejected}
        disabled={isMutating}
        loading={mutatingStatus === ReviewStatus.REJECTED}
        id='detection-modal-reject'
        dataPw='button-detection-modal-reject'
      >
        {ReviewStatusLabel.REJECTED}
      </Button>
      <Button
        className={twMerge('w-[15%]', getReviewButtonClasses(ReviewStatus.IGNORED))}
        onClick={onReviewIgnored}
        disabled={isMutating}
        loading={mutatingStatus === ReviewStatus.IGNORED}
        id='detection-modal-ignore'
        dataPw='button-detection-modal-ignore'
      >
        {reviewStatus === ReviewStatus.IGNORED ? 'Ignored' : 'Ignore'}
      </Button>
      <Button
        className={twMerge('w-[25%]', getReviewButtonClasses(ReviewStatus.NOT_REVIEWED))}
        onClick={onReviewNotReviewed}
        disabled={isMutating}
        loading={mutatingStatus === ReviewStatus.NOT_REVIEWED}
        id='detection-modal-not-reviewed'
        dataPw='button-detection-modal-not-reviewed'
      >
        {reviewStatus === ReviewStatus.NOT_REVIEWED ? 'Not Reviewed' : 'Clear Review'}
      </Button>
      <Button
        className={twMerge('w-[17.5%]', getReviewButtonClasses(ReviewStatus.CONFIRMED))}
        onClick={onReviewConfirmed}
        disabled={isMutating}
        loading={mutatingStatus === ReviewStatus.CONFIRMED}
        id='detection-modal-confirm'
        dataPw='button-detection-modal-confirm'
      >
        {reviewStatus === ReviewStatus.CONFIRMED ? 'Confirmed' : 'Confirm'}
      </Button>
      <Button
        className='w-[11.25%] bg-primary-element px-3'
        onClick={onNext}
        disabled={nextAlertId === '' || isMutating}
        id='detection-modal-next'
        dataPw='button-detection-modal-next'
      >
        <ChevronRightIcon className='h-5 w-5' strokeWidth={2} />
      </Button>
    </div>
  );
};
