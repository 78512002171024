import { AxiosError } from 'axios';
import { useCallback } from 'react';
import toast from 'react-hot-toast';

import { Button } from '@fleet/components';

import { Modal } from '../../atoms';
import { type ProjectUser, useAuthContext } from '../../../hooks';
import { useDeleteProjectUser } from '../../../hooks/api/useDeleteProjectUser';
import type { AxiosErrorResponseBody } from '../../../utils';
import { getUserFullName } from '../../../utils/User';

interface DeleteProjectUserModalProps {
  projectUser?: ProjectUser;
  isOpen: boolean;
  onClose: () => void;
}

export const DeleteProjectUserModal: React.FC<DeleteProjectUserModalProps> = ({
  projectUser,
  isOpen,
  onClose
}) => {
  const { refetchAllQueries } = useAuthContext();

  const { trigger: deleteProjectUser } = useDeleteProjectUser({
    projectId: projectUser?.projectId || '',
    userId: projectUser?.userId || ''
  });

  const onSubmit = useCallback(async () => {
    try {
      await deleteProjectUser();
      refetchAllQueries();
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error((error.response?.data as AxiosErrorResponseBody).message);
      }
      return;
    }
    toast.success('User removed successfully');
    onClose();
  }, [deleteProjectUser, onClose, refetchAllQueries]);

  return (
    <Modal title='Remove a project user' isOpen={isOpen} onClose={onClose}>
      <div className='pb-2'>
        Are you sure you want to remove{' '}
        {getUserFullName(projectUser?.user) || projectUser?.user.email} from this project?
      </div>
      <div className='flex justify-between gap-4 pt-3'>
        <Button variant='mono' className='h-14 w-1/2 justify-center text-lg' onClick={onClose}>
          Cancel
        </Button>
        <Button
          type='submit'
          variant='solid'
          className='h-14 w-1/2 justify-center text-lg'
          onClick={onSubmit}
        >
          Remove
        </Button>
      </div>
    </Modal>
  );
};
