import type { OffsetPaginatedQuery, OffsetPaginatedResponse } from './useApiHooks';
import { useOffsetApi } from './useApiHooks';
import type { Alert } from './useGetAlert';
import type { FeaturedBy } from '../../const';
import { generateURLSearchParams } from '../../utils';

export interface GetAlertsQuery extends OffsetPaginatedQuery {
  organizationIds?: string;
  projectIds?: string;
  deviceIds?: string;
  startAt: string;
  reportingPeriodSecs: number;
  alertTypes?: string;
  detectionTypes?: string;
  reviewStatuses?: string;
  riskState?: string;
  geolocationBounds?: string;
  featuredBy?: FeaturedBy;
  requireGeolocation?: boolean;
}

export interface GetAlertsResponse extends OffsetPaginatedResponse {
  results: Alert[];
}

export const useGetAlerts = (queryParams: GetAlertsQuery, disableQuery?: boolean) =>
  useOffsetApi<GetAlertsResponse>(
    'get',
    disableQuery ? null : `/alerts${generateURLSearchParams(queryParams)}`,
    queryParams.limit,
    undefined,
    { keepPreviousData: true }
  );
