import { useCallback, useState } from 'react';

import { Devices } from '../Devices/Devices';
import { MoveDevicesPanel } from '../MoveDevicesPanel/MoveDevicesPanel';
import { OrganizationUserRole } from '../../../const';
import type { Device } from '../../../hooks';
import { useAuthContext } from '../../../hooks';

type OrganisationDevicesProps = {
  organizationIds: string;
};

export const OrganisationDevices: React.FC<OrganisationDevicesProps> = ({ organizationIds }) => {
  // TODO: move state logic to context
  const { currentOrganizationRole } = useAuthContext();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [refreshDevices, setRefreshDevices] = useState(false);
  const [showTableCheckboxes, setShowTableCheckboxes] = useState(false);
  const canMoveDevices = currentOrganizationRole === OrganizationUserRole.ADMIN;

  // Checkbox handler
  const handleCheckboxChange = useCallback(
    (id: string) => {
      if (selectedRows.includes(id)) {
        setSelectedRows(selectedRows.filter(rowId => rowId !== id));
      } else {
        setSelectedRows([...selectedRows, id]);
      }
    },
    [selectedRows]
  );

  const handleCheckboxToggle = () =>
    setShowTableCheckboxes(prevState => {
      if (prevState) setSelectedRows([]);
      return !prevState;
    });

  // Main checkbox handler
  const handleMainCheckboxChange = useCallback((isChecked: boolean, data: Device[]) => {
    setSelectedRows(isChecked ? data.map(row => row.id) : []);
  }, []);

  // TODO: move checkbox logic out of device and pass a component instead
  // TODO: when enabling move devices set the value enableRowSelection to canMoveDevices
  return (
    <div className='flex justify-center'>
      <Devices
        deviceQueryFilters={{ organizationIds }}
        enableRowSelection
        selectedRows={selectedRows}
        handleCheckboxChange={handleCheckboxChange}
        refreshDevices={refreshDevices}
        setRefreshDevices={setRefreshDevices}
        handleMainCheckboxChange={handleMainCheckboxChange}
        handleCheckboxToggle={handleCheckboxToggle}
        showTableCheckboxes={showTableCheckboxes}
      />
      <MoveDevicesPanel
        devices={selectedRows}
        closeHandler={() => {
          setShowTableCheckboxes(false);
          setSelectedRows([]);
        }}
        setRefreshDevices={setRefreshDevices}
        setSelectedRows={setSelectedRows}
        isVisible={showTableCheckboxes}
      />
    </div>
  );
};
