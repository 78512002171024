import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@fleet/components';

import {
  AlertTypeFilter,
  DateFilter,
  DetectionTypeFilter,
  DeviceFilters,
  ProjectFilters,
  ReviewStatusFilter,
  RiskFilter
} from './Filters';
import { Modal } from '../../atoms';
import { DEFAULT_FILTERS } from '../../../const';
import { useRetrieveFilters } from '../../../hooks';
import { SelectedFiltersContext } from '../../../providers';
import type { Filters, QueryParams } from '../../../types';

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FilterModal: React.FC<FilterModalProps> = ({ isOpen, onClose }) => {
  const [selectedFilters, setSelectedFilters] = useState<Filters>({});

  const router = useRouter();
  const filters = useRetrieveFilters();

  useEffect(() => {
    if (isOpen && filters) setSelectedFilters(filters);
  }, [isOpen, filters]);

  const clearFilters = useCallback(() => {
    onClose();
    router.replace({
      query: {}
    });
  }, [onClose, router]);

  const saveFilters = useCallback(() => {
    onClose();
    const tempSelectedFilters = { ...selectedFilters };
    delete tempSelectedFilters.geolocationBounds;

    const differentFilters = Object.fromEntries(
      Object.entries(tempSelectedFilters).filter(
        ([key, value]) => value !== DEFAULT_FILTERS[key as keyof typeof DEFAULT_FILTERS]
      )
    );

    const query = { ...router.query };

    // Iterate over both query and differentFilters
    for (const key of Object.keys(query)) {
      if (differentFilters[key]) {
        // Update the query with the differentFilters value
        query[key] = differentFilters[key];
      } else {
        // Remove the key if it's undefined or an empty string in differentFilters
        delete query[key];
      }
    }

    router.replace({ query: { ...query, ...differentFilters } satisfies QueryParams });
  }, [onClose, router, selectedFilters]);

  const providerValue = useMemo(
    () => ({ selectedFilters, setSelectedFilters }),
    [selectedFilters, setSelectedFilters]
  );

  return (
    <Modal onClose={onClose} isOpen={isOpen} title='Filters'>
      <SelectedFiltersContext.Provider value={providerValue}>
        <div className='space-y-4'>
          <DateFilter />
          <ProjectFilters />
          <DeviceFilters />
          <AlertTypeFilter />
          <DetectionTypeFilter />
          <RiskFilter />
          {/* <FeaturedFilter /> */}
          <ReviewStatusFilter />
        </div>
      </SelectedFiltersContext.Provider>
      <div className='mt-6 flex justify-between space-x-6'>
        <Button
          variant='outline'
          onClick={clearFilters}
          id='clear-filters'
          className='w-1/2 place-content-center py-3 text-xl'
        >
          Clear filters
        </Button>
        <Button
          variant='solid'
          onClick={saveFilters}
          className='w-1/2 place-content-center py-3 text-xl'
          data-pw='button-filter-save'
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};
