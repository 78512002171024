import type { BarDatum, BarTooltipProps } from '@nivo/bar';
import { useCallback, useContext, useMemo } from 'react';

import {
  generateAggregatedRuntime,
  generateCSVData,
  generateGraphData,
  getBarColor,
  legends,
  ProcessedChangeAndLabel
} from './AnalyticsRuntime.utils';
import { Tooltip } from '../../atoms';
import { AnalyticsCard, AnalyticsGraph } from '../../molecules';
import { DAY_IN_S, dayJS } from '../../../const';
import { useAuthContext, useGetDeviceMetrics } from '../../../hooks';
import { AnalyticsContext } from '../../../providers';
import { getFormattedDuration, getPercentChange, getStoredTimezone } from '../../../utils';

type AnalyticsRuntimeProps = {
  version?: number;
};

export const AnalyticsRuntime: React.FC<AnalyticsRuntimeProps> = ({ version = 1 }) => {
  const { currentOrganizationId } = useAuthContext();
  const {
    analyticsFilters,
    dateAggregation,
    comparisonCurrText,
    comparisonPrevText,
    comparison,
    count
  } = useContext(AnalyticsContext);

  const { reportingPeriodSecs, startAt, ...rest } = analyticsFilters;

  const {
    data: deviceMetrics,
    error,
    isLoading
  } = useGetDeviceMetrics({
    queryParams: {
      ...(comparison
        ? {
            startAt: dayJS({ date: startAt })
              .subtract(reportingPeriodSecs, 'seconds')
              .toISOString(),
            reportingPeriodSecs: reportingPeriodSecs * 2
          }
        : { startAt: dayJS({ date: startAt }).toISOString(), reportingPeriodSecs }),
      ...rest,
      timezone: getStoredTimezone(),
      organizationIds: currentOrganizationId
    },
    variant: dateAggregation,
    version
  });

  const periodEndAt = dayJS({ date: analyticsFilters.startAt })
    .add(analyticsFilters.reportingPeriodSecs, 's')
    .toISOString();

  const totalCount = comparison ? count * 2 : count;

  const aggregatedRuntimes = useMemo(
    () =>
      generateAggregatedRuntime({
        deviceMetrics: deviceMetrics?.results,
        count: totalCount,
        periodEndAt,
        dateAggregation
      }),
    [dateAggregation, deviceMetrics?.results, periodEndAt, totalCount]
  );

  const { aggregatedPoweredOn, aggregatedDetectionsEnabled, aggregatedDetectionsDisabled } =
    aggregatedRuntimes;

  const comparisonRange = analyticsFilters.reportingPeriodSecs > DAY_IN_S * 8 ? 'month' : 'day';

  const daysInCurrRange =
    comparisonRange === 'month'
      ? dayJS({ date: periodEndAt }).subtract(1, 'millisecond').daysInMonth()
      : 7;

  const daysInPrevRange =
    comparisonRange === 'month'
      ? dayJS({ date: periodEndAt }).subtract(1, 'millisecond').subtract(1, 'month').daysInMonth()
      : 7;

  const currEnabledTimes = comparison
    ? aggregatedDetectionsEnabled.slice(-daysInCurrRange)
    : aggregatedDetectionsEnabled;

  const prevEnabledTimes = comparison ? aggregatedDetectionsEnabled.slice(0, daysInPrevRange) : [];

  const currDisabledTimes = comparison
    ? aggregatedDetectionsDisabled.slice(-daysInCurrRange)
    : aggregatedDetectionsDisabled;

  const currRunTimes = comparison
    ? aggregatedPoweredOn.slice(-daysInCurrRange)
    : aggregatedPoweredOn;

  const prevRunTimes = comparison ? aggregatedPoweredOn.slice(0, daysInPrevRange) : [];

  const totalCurrEnabled = currEnabledTimes.reduce((a, b) => a + b, 0);
  const totalPrevEnabled = prevEnabledTimes.reduce((a, b) => a + b, 0);
  const totalCurrRun = currRunTimes.reduce((a, b) => a + b, 0);
  const totalPrevRun = prevRunTimes.reduce((a, b) => a + b, 0);

  const graphData = useMemo(
    () =>
      generateGraphData({
        currDisabledTime: currDisabledTimes,
        currEnabledTime: currEnabledTimes,
        currRunTime: currRunTimes,
        dateAggregation,
        count,
        periodEndAt
      }),
    [currDisabledTimes, currEnabledTimes, currRunTimes, dateAggregation, count, periodEndAt]
  );

  const csvData = useMemo(
    () =>
      generateCSVData({
        currDisabledTime: currDisabledTimes,
        currEnabledTime: currEnabledTimes,
        currRunTime: currRunTimes,
        count,
        periodEndAt,
        dateAggregation
      }),
    [currDisabledTimes, currEnabledTimes, currRunTimes, count, periodEndAt, dateAggregation]
  );

  const poweredTitle = useMemo(
    () => (
      <ProcessedChangeAndLabel
        percentChange={getPercentChange(totalCurrRun, totalPrevRun)}
        label='Device powered'
        labelValue={getFormattedDuration(totalCurrRun)}
        comparisonCurrText={comparisonCurrText}
      />
    ),
    [comparisonCurrText, totalCurrRun, totalPrevRun]
  );

  const enabledTitle = useMemo(
    () => (
      <ProcessedChangeAndLabel
        percentChange={getPercentChange(totalCurrEnabled, totalPrevEnabled)}
        label='Detections enabled'
        labelValue={getFormattedDuration(totalCurrEnabled)}
        comparisonCurrText={comparisonCurrText}
      />
    ),
    [comparisonCurrText, totalCurrEnabled, totalPrevEnabled]
  );

  const getTooltip = useCallback(
    (curr: number, prev: number) => {
      const percentChange = getPercentChange(curr, prev);

      const percentDirection =
        percentChange === 0
          ? 'Unchanged'
          : `${percentChange > 0 ? 'Up' : 'Down'} ${
              percentChange === Number.POSITIVE_INFINITY
                ? ''
                : `${Math.round(Math.abs(percentChange))}%`
            }`;

      return (
        <>
          <p>
            Total {getFormattedDuration(curr)} {comparisonCurrText}
          </p>
          <p>
            {percentDirection} from {getFormattedDuration(prev)} {comparisonPrevText}
          </p>
        </>
      );
    },
    [comparisonCurrText, comparisonPrevText]
  );

  const graphProps = useMemo(
    () => ({
      data: graphData,
      keys: ['Detections disabled', 'Detections enabled'],
      colors: getBarColor,
      axisLeft: { format: (value: string) => `${value}h` }
    }),
    [graphData]
  );

  const graphTooltip = useCallback(
    ({ data, index }: BarTooltipProps<BarDatum>) => (
      <>
        <p>Device powered: {getFormattedDuration(currRunTimes[index])}</p>
        {['Detections enabled', 'Detections disabled'].map(key => (
          <p key={key}>
            {key}: {getFormattedDuration(Number(data[key]) * 3600 ?? 0)}
          </p>
        ))}
      </>
    ),
    [currRunTimes]
  );

  return (
    <AnalyticsCard
      header={`Operating hour${version === 1 ? '' : ` ${version}.0`}`}
      loading={isLoading}
      error={error?.response?.data.message}
      className={version === 1 ? '' : 'border-2 border-emerald-300'}
    >
      {deviceMetrics?.results?.length && graphData.length > 0 ? (
        <div>
          {comparison ? (
            <div className='flex w-full items-center justify-evenly space-x-2'>
              <Tooltip title={poweredTitle}>{getTooltip(totalCurrRun, totalPrevRun)}</Tooltip>
              <Tooltip title={enabledTitle}>
                {getTooltip(totalCurrEnabled, totalPrevEnabled)}
              </Tooltip>
            </div>
          ) : null}
          <AnalyticsGraph
            csvData={csvData}
            csvLabel='runtime'
            count={count}
            yLabel='Time'
            legends={legends}
            graphProps={graphProps}
            customTooltip={graphTooltip}
            periodEndAt={periodEndAt}
            dateAggregation={dateAggregation}
          />
        </div>
      ) : (
        <p className='text-center'>No data available for this time period</p>
      )}
    </AnalyticsCard>
  );
};
