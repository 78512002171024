import { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';

import { AnalyticsCard } from '../../molecules';
import { DetectionType, ReviewStatus, Routes, SHORT_DEFAULT_FILTERS } from '../../../const';
import { AggregatedType, useAuthContext, useGetAggregatedAlerts } from '../../../hooks';
import { AnalyticsContext } from '../../../providers';
import type { QueryParams } from '../../../types';
import { getStoredTimezone } from '../../../utils';

interface SectionData {
  [DetectionType.PERSON]: number;
  [DetectionType.VEHICLE]: number;
  [DetectionType.SAFETY_CONE]: number;
}

interface SectionProps {
  label: string;
  data: SectionData;
  percentage: string;
  query: Record<string, string>;
}

const Section: React.FC<SectionProps> = ({ label, data, percentage, query }) => {
  const router = useRouter();
  const sumData = Object.values(data).reduce<number>((acc, curr: number) => acc + curr, 0);

  const navigateToDetections = (detectionType?: DetectionType) => {
    router.push({
      pathname: Routes.DETECTIONS,
      query: {
        ...SHORT_DEFAULT_FILTERS,
        ...router.query,
        ...query,
        ...(detectionType && { detectionType })
      } satisfies QueryParams
    });
  };

  return (
    <div className='flex flex-grow flex-col justify-around space-y-2'>
      <div
        className='group relative flex cursor-pointer font-medium text-primary-text/75 xl:justify-between'
        onClick={() => navigateToDetections()}
      >
        <div>
          <p className='text-2xl group-hover:underline'>{sumData.toLocaleString()}</p>
          <p className='text-sm group-hover:underline'>{label}</p>
        </div>
        <div className='absolute left-1/2 self-center rounded-full bg-primary-element p-2 xl:static'>
          <p className='text-xl'>{Number.isNaN(Number(percentage)) ? 0 : percentage}%</p>
        </div>
      </div>
      <div className='text-sm'>
        <div
          className={`flex items-center space-x-1 ${data[DetectionType.PERSON] > 0 ? 'cursor-pointer hover:underline' : ''}`}
          onClick={() =>
            data[DetectionType.PERSON] > 0 && navigateToDetections(DetectionType.PERSON)
          }
        >
          <div className='h-2 w-2 rounded-full bg-enterprise' />
          <p>People: {data[DetectionType.PERSON].toLocaleString()}</p>
        </div>
        <div
          className={`flex items-center space-x-1 ${data[DetectionType.VEHICLE] > 0 ? 'cursor-pointer hover:underline' : ''}`}
          onClick={() =>
            data[DetectionType.VEHICLE] > 0 && navigateToDetections(DetectionType.VEHICLE)
          }
        >
          <div className='h-2 w-2 rounded-full bg-blue-500' />
          <p>Vehicles: {data[DetectionType.VEHICLE].toLocaleString()}</p>
        </div>
        <div
          className={`flex items-center space-x-1 ${data[DetectionType.SAFETY_CONE] > 0 ? 'cursor-pointer hover:underline' : ''}`}
          onClick={() =>
            data[DetectionType.SAFETY_CONE] > 0 && navigateToDetections(DetectionType.SAFETY_CONE)
          }
        >
          <div className='h-2 w-2 rounded-full bg-green-500' />
          <p>Cones: {data[DetectionType.SAFETY_CONE].toLocaleString()}</p>
        </div>
      </div>
    </div>
  );
};

export const AnalyticsReview: React.FC = () => {
  const { currentOrganizationId } = useAuthContext();
  const { analyticsFilters, dateAggregation } = useContext(AnalyticsContext);

  const { data, isLoading, error } = useGetAggregatedAlerts({
    queryParams: {
      timezone: getStoredTimezone(),
      detectionTypes: [AggregatedType.ANY],
      organizationIds: currentOrganizationId,
      ...analyticsFilters
    },
    variant: dateAggregation
  });

  const reviewData = useMemo(() => {
    const notReviewedData: Record<DetectionType, number> = {
      [DetectionType.PERSON]: 0,
      [DetectionType.VEHICLE]: 0,
      [DetectionType.SAFETY_CONE]: 0
    };

    const confirmedData: Record<DetectionType, number> = {
      [DetectionType.PERSON]: 0,
      [DetectionType.VEHICLE]: 0,
      [DetectionType.SAFETY_CONE]: 0
    };

    const ignoredData: Record<DetectionType, number> = {
      [DetectionType.PERSON]: 0,
      [DetectionType.VEHICLE]: 0,
      [DetectionType.SAFETY_CONE]: 0
    };

    const rejectedData: Record<DetectionType, number> = {
      [DetectionType.PERSON]: 0,
      [DetectionType.VEHICLE]: 0,
      [DetectionType.SAFETY_CONE]: 0
    };

    if (!data?.results)
      return {
        notReviewedData,
        confirmedData,
        ignoredData,
        rejectedData,
        notReviewedPercentage: '0',
        confirmedPercentage: '0',
        ignoredPercentage: '0',
        rejectedPercentage: '0'
      };

    for (const aggregatedAlert of data.results) {
      const { detectionType } = aggregatedAlert;

      notReviewedData[detectionType] += aggregatedAlert.notReviewedAlertCount;
      confirmedData[detectionType] += aggregatedAlert.confirmedAlertCount;
      ignoredData[detectionType] += aggregatedAlert.ignoredAlertCount;
      rejectedData[detectionType] += aggregatedAlert.rejectedAlertCount;
    }

    const notReviewedSum = Object.values(notReviewedData).reduce((acc, curr) => acc + curr, 0);
    const confirmedSum = Object.values(confirmedData).reduce((acc, curr) => acc + curr, 0);
    const ignoredSum = Object.values(ignoredData).reduce((acc, curr) => acc + curr, 0);
    const rejectedSum = Object.values(rejectedData).reduce((acc, curr) => acc + curr, 0);

    const total = notReviewedSum + confirmedSum + ignoredSum + rejectedSum;

    const notReviewedPercentage = total > 0 ? ((notReviewedSum / total) * 100).toFixed(1) : '0';
    const confirmedPercentage = total > 0 ? ((confirmedSum / total) * 100).toFixed(1) : '0';
    const ignoredPercentage = total > 0 ? ((ignoredSum / total) * 100).toFixed(1) : '0';
    const rejectedPercentage = total > 0 ? ((rejectedSum / total) * 100).toFixed(1) : '0';

    return {
      notReviewedData,
      confirmedData,
      ignoredData,
      rejectedData,
      notReviewedPercentage,
      confirmedPercentage,
      ignoredPercentage,
      rejectedPercentage
    };
  }, [data]);

  return (
    <AnalyticsCard
      header='Review'
      loading={isLoading}
      error={error?.response?.data.message}
      data-intercom-target='analytics-review-card'
    >
      <div className='grid grid-cols-2 gap-12 xl:flex xl:gap-0 xl:space-x-12 xl:divide-x-2 xl:[&>:not(:first-child)]:pl-12'>
        <Section
          percentage={reviewData.notReviewedPercentage}
          label='Not reviewed'
          data={reviewData.notReviewedData}
          query={{ reviewStatus: ReviewStatus.NOT_REVIEWED }}
        />
        <Section
          percentage={reviewData.confirmedPercentage}
          label='Confirmed'
          data={reviewData.confirmedData}
          query={{ reviewStatus: ReviewStatus.CONFIRMED }}
        />
        <Section
          percentage={reviewData.ignoredPercentage}
          label='Ignored'
          data={reviewData.ignoredData}
          query={{ reviewStatus: ReviewStatus.IGNORED }}
        />
        <Section
          percentage={reviewData.rejectedPercentage}
          label='AI Error'
          data={reviewData.rejectedData}
          query={{ reviewStatus: ReviewStatus.REJECTED }}
        />
      </div>
    </AnalyticsCard>
  );
};
