import { Switch } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

interface SageSwitch {
  checked: boolean;
  onToggled: (checked: boolean) => void;
  className?: string;
}

export const SageSwitch: React.FC<SageSwitch> = ({ checked, onToggled, className }) => {
  return (
    <Switch
      checked={checked}
      onChange={onToggled}
      className={twMerge(
        'ui-not-checked:bg-system-600 relative inline-flex h-5 w-8 items-center rounded-full bg-brand-100 px-1 duration-200 ease-in-out',
        className
      )}
    >
      <span
        className={twMerge(
          'pointer-events-none inline-block size-3 transform rounded-full bg-system-900 shadow-lg ring-0 transition-transform duration-200 ease-in-out',
          checked ? 'translate-x-3' : 'translate-x-0'
        )}
      />
    </Switch>
  );
};
