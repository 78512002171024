import React, { useCallback } from 'react';

import type { AlertType } from '../../../const';
import type { Frame } from '../../../hooks';
import { getRiskColors } from '../../../utils';

interface BoundingBoxProps {
  boxes: Frame[] | undefined;
  alertType?: AlertType;
}

export const BoundingBoxes: React.FC<BoundingBoxProps> = ({ boxes, alertType }) => {
  const getStyles = useCallback(
    (box: Frame): React.CSSProperties => {
      const { hex } = getRiskColors(box.risk_state, alertType);
      return {
        position: 'absolute',
        top: `${box.min_y * 100}%`,
        left: `${box.min_x * 100}%`,
        width: `${(box.max_x - box.min_x) * 100}%`,
        height: `${(box.max_y - box.min_y) * 100}%`,
        border: `2px solid ${hex}`,
        outline: `1px solid black`
      };
    },
    [alertType]
  );

  if (!boxes) return null;

  return (
    <div className='w-full h-full relative pointer-events-none'>
      {boxes.map(box => {
        const styles = getStyles(box);

        return <div key={`${box.min_y}-${box.min_x}-${box.max_y}-${box.max_x}`} style={styles} />;
      })}
    </div>
  );
};
