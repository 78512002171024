import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import type { SelectOption } from '@fleet/components';
import { Button, Input, Select } from '@fleet/components';
import { Loading } from '@fleet/components';

import { Modal } from '../../atoms';
import { ProjectsRoles } from '../../organisms';
import { OrganizationUserRole } from '../../../const';
import { useAuthContext } from '../../../hooks';
import type { ProjectRole } from '../../../hooks/api/useAddUser';
import { useAddUser } from '../../../hooks/api/useAddUser';
import type { AxiosErrorResponseBody } from '../../../utils';

interface InviteOrganizationUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  defaultProjectsRoles?: ProjectRole[];
}

const roleOptions: SelectOption<OrganizationUserRole>[] = Object.values(OrganizationUserRole)
  .map(option => ({
    label: option.charAt(0).toUpperCase() + option.slice(1).toLowerCase(),
    value: option
  }))
  .filter(option => option !== null) as SelectOption<OrganizationUserRole>[];

export const InviteOrganizationUserModal: React.FC<InviteOrganizationUserModalProps> = ({
  isOpen,
  onClose,
  defaultProjectsRoles = []
}) => {
  const [email, setEmail] = useState<string>('');
  const [projectsRoles, setProjectsRoles] = useState<ProjectRole[]>([]);

  const [selectedRole, setSelectedRole] = useState<OrganizationUserRole | string>('');

  const { currentOrganizationId } = useAuthContext();
  const { trigger: addUser, isMutating } = useAddUser({
    organizationId: currentOrganizationId
  });

  const resetForm = useCallback(() => {
    setEmail('');
    setSelectedRole('');
    const defProjectsRoles =
      defaultProjectsRoles && defaultProjectsRoles.length > 0 ? [...defaultProjectsRoles] : [];
    setProjectsRoles(defProjectsRoles);
  }, [defaultProjectsRoles]);

  const onSubmit = useCallback(async () => {
    try {
      await addUser({
        email,
        role: selectedRole as OrganizationUserRole,
        projectsRoles
      });
      toast.success('User invited successfully.');
      resetForm();
      onClose();
    } catch (error: unknown) {
      const message =
        error instanceof AxiosError
          ? (error.response?.data as AxiosErrorResponseBody).message
          : 'Failed to invite user.';
      toast.error(message);
    }
  }, [addUser, email, onClose, selectedRole, projectsRoles, resetForm]);

  useEffect(() => {
    if (isOpen) resetForm();
  }, [isOpen]);

  useEffect(() => {
    if (defaultProjectsRoles && defaultProjectsRoles.length > 0)
      setProjectsRoles([...defaultProjectsRoles]);
  }, [defaultProjectsRoles]);

  return (
    <Modal title='Invite Organization User' isOpen={isOpen} onClose={onClose}>
      <div className='space-y-4 [&_input]:w-full'>
        <Input label='Email *' value={email} onChange={e => setEmail(e.target.value)} />
        {/* Project permissions */}

        <Select
          options={roleOptions}
          value={selectedRole}
          label='Organization Role'
          onChange={setSelectedRole}
        />
        <ProjectsRoles projectsRoles={projectsRoles} setProjectsRoles={setProjectsRoles} />

        {isMutating ? (
          <Loading />
        ) : (
          <div className='flex items-center justify-between gap-4 pt-3'>
            <Button variant='mono' className='h-14 w-1/2 justify-center text-lg' onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant='solid'
              className='h-14 w-1/2 justify-center text-lg'
              onClick={onSubmit}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
