import { RiskState, TIME_DATE_FORMAT } from '../../../const';
import { AlertType, DAY_IN_S, dayJS, ReviewStatus } from '../../../const';
import type { GetProjectsResponse, Insight, InsightsData } from '../../../hooks';
import { InsightSeverity } from '../../../hooks';
import { getFormattedTZDate } from '../../../utils';

export const handleInsightData = (key: string, data: InsightsData) => {
  const value = data[key.slice(2, -2)];

  if (value === undefined || value === null) return 'N/A';
  if (!key.endsWith('_at}}')) return value.toString();

  return getFormattedTZDate({
    date: dayJS({ date: value }),
    dateFormat: TIME_DATE_FORMAT
  });
};

export const getInsightSummary = ({ summaryTemplate, data }: Insight) => {
  return summaryTemplate.replaceAll(/{{(.*?)}}/gm, x => handleInsightData(x, data));
};

export const getInsightDetails = ({ detailsTemplate, data }: Insight) =>
  detailsTemplate
    .replaceAll(/{{(.*?)}}/gm, x => handleInsightData(x, data))
    .replace('alert(s)', Number(data.any_alert_count) === 1 ? 'alert' : 'alerts');

export const getInsightSeverityValues = ({ severity }: Insight) => {
  switch (severity) {
    case InsightSeverity.CRITICAL:
      return { backgroundColor: 'bg-red-500', label: 'Critical Action Required' };
    case InsightSeverity.PROBLEM:
      return { backgroundColor: 'bg-brand', label: 'Problem' };
    case InsightSeverity.WARNING:
      return { backgroundColor: 'bg-yellow-500', label: 'Warning' };
    case InsightSeverity.INTERESTING:
      return { backgroundColor: 'bg-blue-500', label: 'Interesting' };
    case InsightSeverity.NORMAL:
      return { backgroundColor: 'bg-green-500', label: 'Normal' };
    default:
      return { backgroundColor: 'bg-gray-500', label: 'Unknown Severity' };
  }
};

export const getInsightActiveText = ({ startedAt }: Insight) => {
  const daysActive = dayJS().diff(startedAt, 'day');

  if (daysActive === 0) return 'Active since today';
  return `Active for ${daysActive} day${daysActive === 1 ? '' : 's'}`;
};

export const getInsightAddress = (
  { data }: Insight,
  projectsData: GetProjectsResponse | undefined
) => {
  return (
    projectsData?.results.find(project => project.id === data.project_id)?.address?.trim() ||
    'No address'
  );
};

export const getInsightAlertFilters = ({ data }: Insight) => {
  let riskState;
  // if (data.type === '')

  if (data.type === 'high risk') riskState = RiskState.HIGH_RISK;
  if (data.type === 'extreme risk') riskState = RiskState.EXTREME_RISK;

  return {
    organizationIds: data.organization_id,
    projectIds: data.project_id,
    deviceIds: data.device_id,
    startAt: dayJS().subtract(14, 'days').toISOString(),
    reportingPeriodSecs: DAY_IN_S * 14,
    reviewStatuses: ReviewStatus.NOT_REVIEWED,
    alertTypes: AlertType.CRITICAL,
    riskState: riskState,
    limit: 50
  };
};
