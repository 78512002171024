export const DetectionCardSkeleton: React.FC = () => {
  return (
    <div className='flex flex-col min-w-[12rem] cursor-pointer rounded-lg bg-gray-100'>
      <div className='rounded-t-lg w-full aspect-[4/3] bg-gray-300 border' />
      <div className='space-y-2 p-3 flex flex-grow justify-between flex-col bg-gray-100 border rounded-b-lg'>
        <div className='text-base font-medium block animate-pulse bg-gray-200 h-5 w-full rounded-md' />
        <div className='text-base font-medium block animate-pulse bg-gray-200 h-5 w-full rounded-md' />
        <div className='text-base font-medium block animate-pulse bg-gray-200 h-5 w-full rounded-md' />
        <div className='text-base font-medium block animate-pulse bg-gray-200 h-3 w-full rounded-md' />
      </div>
    </div>
  );
};
