import { useMemo } from 'react';
import type { ClassNames, DateRange } from 'react-day-picker';
import { DayPicker } from 'react-day-picker';

import { Button } from '@fleet/components';

import { Modal } from '../../atoms';
import { dayJS } from '../../../const';

import 'react-day-picker/dist/style.css';

export interface DatePickerProps {
  open: boolean;
  onClose: () => void;
  selected: DateRange | undefined;
  onSelect: (date: DateRange | undefined) => void;
  onConfirm: () => void;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  open,
  onClose,
  selected,
  onSelect,
  onConfirm
}) => {
  const rangeSelected =
    Boolean(selected?.from) &&
    Boolean(selected?.to) &&
    JSON.stringify(selected?.from) !== JSON.stringify(selected?.to);

  const classNames: ClassNames = useMemo(
    () => ({
      months: 'flex flex-col md:flex-row justify-around md:space-x-4 space-y-8 md:space-y-0 m-4',
      month: 'bg-primary-element-light rounded-2xl p-4',
      caption_label: 'text-lg font-medium text-gray-700 mx-4',
      caption: 'flex justify-between items-center mb-2',
      caption_start: 'text-right [&>:first-child]:flex-row-reverse',
      caption_end: 'text-left',
      head_cell: 'w-10 h-10 lg:w-12 lg:h-12 text-center font-medium',
      day: 'w-10 h-10 lg:w-12 lg:h-12 text-lg rounded-full hover:!bg-brand/25',
      day_selected: '!bg-brand bg-opacity-40',
      day_range_start: rangeSelected ? 'rounded-tr-none rounded-br-none' : '',
      day_range_middle: rangeSelected ? 'rounded-none' : '',
      day_range_end: rangeSelected ? 'rounded-tl-none rounded-bl-none' : '',
      day_disabled: 'hover:bg-inherit'
    }),
    [rangeSelected]
  );

  const modalHeader = useMemo(
    () => <h3 className='mb-6 text-center text-3xl font-medium text-gray-700'>Select day range</h3>,
    []
  );

  const dayPickerDisabled = useMemo(
    () => ({ after: dayJS().toDate(), before: new Date('2023-01-01') }),
    []
  );

  const defaultMonth = useMemo(() => {
    if (selected?.from) return selected.from;
    return dayJS().subtract(1, 'month').toDate();
  }, [selected?.from]);

  return (
    <Modal
      onClose={onClose}
      isOpen={open}
      title='Date'
      header={modalHeader}
      className='max-w-3xl lg:max-w-5xl'
    >
      <DayPicker
        mode='range'
        disabled={dayPickerDisabled}
        defaultMonth={defaultMonth}
        toMonth={dayJS().toDate()}
        numberOfMonths={2}
        max={365}
        selected={selected}
        onSelect={onSelect}
        classNames={classNames}
      />
      <div className='mt-8 flex justify-between space-x-6'>
        <Button
          variant='outline'
          onClick={onClose}
          className='w-1/2 place-content-center py-3 text-xl'
        >
          Cancel
        </Button>
        <Button
          variant='solid'
          onClick={onConfirm}
          disabled={selected === undefined}
          className='w-1/2 place-content-center py-3 text-xl'
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};
