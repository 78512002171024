import type { BarDatum, BarTooltipProps } from '@nivo/bar';

import { dayJS, SHORT_DATE_FORMAT } from '../../../const';
import { getFormattedTZDate } from '../../../utils';

export const getLabelValueColorClass = (index: number) => {
  if (index < 15) return 'text-success';
  if (index < 30) return 'text-warning';
  return 'text-error';
};
export const getCustomTooltip = ({ index, value }: BarTooltipProps<BarDatum>, label: string) => (
  <div className='invisible animate-appear rounded-xl border border-primary-element bg-white p-2 text-sm font-medium shadow-xl'>
    <p className='mb-2 w-max'>
      {getFormattedTZDate({
        date: dayJS().subtract(7 - index, 'days'),
        dateFormat: SHORT_DATE_FORMAT,
        includeTimezone: false
      })}
    </p>
    <p>{value}</p>
  </div>
);
