import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import type { SelectOption } from '@fleet/components';
import { Button, SearchableSelect, Select } from '@fleet/components';
import { sortByKey } from '@fleet/utils';

import { Modal } from '../../atoms';
import { OrganizationUserRole, ProjectUserRole, UserTypes } from '../../../const';
import { Routes } from '../../../const';
import { SUPPORT_EMAIL_DOMAINS } from '../../../const/Users';
import type { Project } from '../../../hooks';
import { useAuthContext } from '../../../hooks';
import { useAddProjectUser } from '../../../hooks/api/useAddProjectUser';
import type { AxiosErrorResponseBody } from '../../../utils';
import { getUserFullName, projectUserRoleOptions } from '../../../utils/User';

interface InviteProjectUserModalProps {
  project: Project;
  isOpen: boolean;
  onClose: () => void;
  setInviteUserModalOpen: (value: boolean) => void;
}

export const InviteProjectUserModal: React.FC<InviteProjectUserModalProps> = ({
  project,
  isOpen,
  onClose,
  setInviteUserModalOpen
}) => {
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<ProjectUserRole>(ProjectUserRole.REVIEWER);
  const { trigger: addProjectUser } = useAddProjectUser({
    projectId: project.id,
    userId: selectedUser
  });
  const { currentOrganization, refetchAllQueries, user, currentOrganizationRole, roles } =
    useAuthContext();
  const router = useRouter();

  const userOptions: SelectOption[] = useMemo(() => {
    let userLinks = currentOrganization?.userLinks || [];
    if (user?.type === UserTypes.CUSTOMER)
      userLinks = userLinks.filter(userLink => userLink.user.type === UserTypes.CUSTOMER);
    if (!SUPPORT_EMAIL_DOMAINS.some(domain => user?.email.endsWith(domain))) {
      userLinks = userLinks.filter(
        ({ user }) => !SUPPORT_EMAIL_DOMAINS.some(domain => user.email.endsWith(domain))
      );
    }
    return sortByKey(
      userLinks.map(userLink => ({
        label: `${getUserFullName(userLink.user) || 'No name'} (${userLink.user.email})`,
        value: userLink.user.userId
      })) || [],
      'label'
    );
  }, [currentOrganization?.userLinks, user?.email, user?.type]);

  const onSubmit = useCallback(async () => {
    if (!selectedUser) {
      toast.error('Please select a user');
      return;
    }
    if (!selectedRole) {
      toast.error('Please select a role');
      return;
    }

    try {
      await addProjectUser({ role: selectedRole });
      refetchAllQueries();
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error((error.response?.data as AxiosErrorResponseBody).message);
      }
      return;
    }
    toast.success('User invited successfully');
    setSelectedUser('');
    setSelectedRole(ProjectUserRole.REVIEWER);
    onClose();
  }, [addProjectUser, onClose, refetchAllQueries, selectedRole, selectedUser]);

  const handleInviteOrgUserButton = useCallback(() => {
    onClose();
    setInviteUserModalOpen(true);
  }, [onClose, setInviteUserModalOpen]);

  const handleOrganizationAdminLink = useCallback(() => {
    onClose();
    router.push(Routes.ORGANIZATION);
  }, [onClose, router]);

  const NoResultsComponent = useMemo(() => {
    if (currentOrganizationRole === OrganizationUserRole.ADMIN) {
      return (
        <div>
          <div className='flex bg-gray-50 px-4 pb-1 pt-3 font-medium'>
            <ExclamationCircleIcon className='mr-2 h-6 w-6 text-yellow-400' />{' '}
            <span>User not found in your organization</span>
          </div>
          <div className='flex items-center bg-gray-50 px-4 pb-3 pl-12'>
            To invite a user to the organisation
            <button
              className='ml-1 flex h-fit items-center text-brand'
              onClick={handleInviteOrgUserButton}
            >
              click here
            </button>
          </div>
        </div>
      );
    }

    if (roles?.projects?.[project.id] === ProjectUserRole.ADMIN) {
      return (
        <div>
          <div className='flex bg-gray-50 px-4 pb-1 pt-3 font-medium'>
            <ExclamationCircleIcon className='mr-2 h-6 w-6 text-yellow-400' />{' '}
            <span>User not found in your organization</span>
          </div>
          <div className='flex bg-gray-50 px-4 pb-3 pl-12'>
            <span>Please ask your</span>
            <button
              className='ml-1 flex h-fit items-center text-brand'
              onClick={handleOrganizationAdminLink}
            >
              organization admin
            </button>
            <span className='ml-1'>to invite the user to the organization</span>
          </div>
        </div>
      );
    }
  }, [
    currentOrganizationRole,
    handleInviteOrgUserButton,
    handleOrganizationAdminLink,
    project.id,
    roles?.projects
  ]);

  return (
    <Modal title='Invite Project User' isOpen={isOpen} onClose={onClose}>
      <div className='space-y-4 [&_input]:w-full'>
        <SearchableSelect
          label='Name'
          options={userOptions}
          value={selectedUser || ''}
          onChange={setSelectedUser}
          noResultMessage={NoResultsComponent}
        />

        <Select
          label='Project Role'
          options={projectUserRoleOptions}
          value={selectedRole || ''}
          onChange={setSelectedRole}
        />
        <div className='flex justify-between gap-4 pt-3'>
          <Button variant='mono' className='h-14 w-1/2 justify-center text-lg' onClick={onClose}>
            Cancel
          </Button>
          <Button
            type='submit'
            variant='solid'
            className='h-14 w-1/2 justify-center text-lg'
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
