import { useContext, useMemo } from 'react';

import {
  generateAggregatedBlindsightIndex,
  generateCSVData,
  generateGraphData,
  getCustomTooltip
} from './AnalyticsBlindsightIndex.utils';
import { AnalyticsCard, AnalyticsGraph } from '../../molecules';
import { dayJS } from '../../../const';
import { useAuthContext, useGetBlindsightIndex, useGetOrganizations } from '../../../hooks';
import { AnalyticsContext } from '../../../providers';
import { getStoredTimezone } from '../../../utils';

type AnalyticsBlindsightIndexProps = {
  version?: number;
};

export const AnalyticsBlindsightIndex: React.FC<AnalyticsBlindsightIndexProps> = ({
  version = 1
}) => {
  const { currentOrganizationId } = useAuthContext();
  const { analyticsFilters, dateAggregation, count } = useContext(AnalyticsContext);

  const {
    data: indexData,
    isLoading,
    error
  } = useGetBlindsightIndex({
    queryParams: {
      timezone: getStoredTimezone(),
      organizationIds: currentOrganizationId,
      ...analyticsFilters
    },
    variant: dateAggregation,
    version
  });

  const periodEndAt = dayJS({ date: analyticsFilters.startAt })
    .add(analyticsFilters.reportingPeriodSecs, 's')
    .toISOString();

  const { data: orgs } = useGetOrganizations({});

  const aggregatedIndex = useMemo(
    () =>
      generateAggregatedBlindsightIndex({
        indexData: indexData?.results,
        count,
        periodEndAt,
        dateAggregation
      }),
    [indexData?.results, count, periodEndAt, dateAggregation]
  );

  const graphData = useMemo(
    () => generateGraphData({ aggregatedIndex, periodEndAt, dateAggregation, count }),
    [aggregatedIndex, count, dateAggregation, periodEndAt]
  );

  const csvData = useMemo(
    () =>
      generateCSVData({
        aggregatedIndex,
        periodEndAt,
        count,
        dateAggregation
      }),
    [aggregatedIndex, periodEndAt, count, dateAggregation]
  );

  const graphProps = useMemo(
    () => ({
      data: graphData
    }),
    [graphData]
  );

  return (
    <AnalyticsCard
      header={`Blindsight Index${version === 1 ? '' : ` ${version}.0`}`}
      loading={isLoading}
      error={error?.response?.data.message}
      data-intercom-target='analytics-bs-index-card'
      className={version === 1 ? '' : 'border-2 border-emerald-300'}
    >
      {(() => {
        if (!indexData?.results?.length)
          return <p className='text-center'>No data available for this time period</p>;

        return (
          <AnalyticsGraph
            csvData={csvData}
            csvLabel='blindsight_detections'
            count={count}
            yLabel='Blindsight Index'
            graphProps={graphProps}
            customTooltip={getCustomTooltip}
            periodEndAt={periodEndAt}
            dateAggregation={dateAggregation}
          />
        );
      })()}
    </AnalyticsCard>
  );
};
