import Image from 'next/image';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import type { OnProgressProps } from 'react-player/base';
import { twMerge } from 'tailwind-merge';

import { Button } from '@fleet/components';

import { BoundingBoxes, RoiOverlay } from '../../atoms';
import { LocalStorageKeys } from '../../../const';
import type { Alert } from '../../../hooks';
import { getFromLocalStorage, setToLocalStorage } from '../../../utils';

const PREVIEW_VID_TOTAL_FRAMES = 80;

interface PreviewPlayerProps {
  alert: Alert | undefined;
}

export const PreviewPlayer: React.FC<PreviewPlayerProps> = ({ alert }) => {
  const [thumbnailUnavailable, setThumbnailUnavailable] = useState(false);
  const [previewUnavailable, setPreviewUnavailable] = useState(false);
  const [showROI, setShowROI] = useState(true);
  const [showBoundingBoxes, setShowBoundingBoxes] = useState(true);
  const [showToggles, setShowToggles] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [previewFrame, setPreviewFrame] = useState(0);

  useEffect(() => {
    setShowBoundingBoxes(getFromLocalStorage<boolean>(LocalStorageKeys.BBOXES_ENABLED) ?? true);
    setShowROI(getFromLocalStorage<boolean>(LocalStorageKeys.ROI_ENABLED) ?? true);
  }, []);

  const reloopRef = useRef(false);

  useEffect(() => {
    if (reloopRef.current && !playing) {
      setTimeout(() => {
        setPlaying(true);
        reloopRef.current = false;
      }, 100);
    }
  }, [playing]);

  const {
    previewVideoUrl,
    videoThumbnailUrl,
    generalZones,
    criticalZones,
    exclusionZones,
    detectionDetails,
    alertType
  } = alert ?? {};

  const onPlay = useCallback(() => setPlaying(true), []);
  const onPause = useCallback(() => setPlaying(false), []);
  const onEnded = useCallback(() => (reloopRef.current = true), []);
  const onError = useCallback(() => setPreviewUnavailable(true), []);
  const onSeek = useCallback(() => setPlaying(false), []);

  const onProgress = useCallback(({ played }: OnProgressProps) => {
    setPreviewFrame(Math.ceil(played * PREVIEW_VID_TOTAL_FRAMES) + 1);
    if (played >= 0.98) reloopRef.current = true;
  }, []);

  const toggleBoundingBoxes = useCallback(() => {
    setShowBoundingBoxes(prev => {
      setToLocalStorage(LocalStorageKeys.BBOXES_ENABLED, !prev);
      return !prev;
    });
  }, []);

  const toggleROIs = useCallback(() => {
    setShowROI(prev => {
      setToLocalStorage(LocalStorageKeys.ROI_ENABLED, !prev);
      return !prev;
    });
  }, []);

  const onThumnailUnavailable = useCallback(() => setThumbnailUnavailable(true), []);

  const onMouseEnter = useCallback(() => setShowToggles(true), []);
  const onMouseLeave = useCallback(() => setShowToggles(false), []);

  const reactPlayerStyle: React.CSSProperties = useMemo(
    () => ({
      position: 'absolute',
      top: 0,
      left: 0
    }),
    []
  );

  if (!alert) return null;

  if (previewUnavailable || !previewVideoUrl)
    return (
      <div className='relative aspect-[4/3] w-full overflow-hidden rounded-2xl lg:w-3/5'>
        <Image
          className={twMerge(
            'rounded-t-lg object-fill brightness-50',
            thumbnailUnavailable && 'opacity-0'
          )}
          src={videoThumbnailUrl ?? ''}
          onError={onThumnailUnavailable}
          fill
          alt='detection preview image'
          loading='lazy'
        />
        <div
          className={twMerge(
            'absolute left-1/2 top-1/3 min-w-[75%] -translate-x-1/2',
            thumbnailUnavailable ? 'text-black' : 'text-white'
          )}
        >
          <p className='text-xl font-bold'>Preview video unavailable</p>
          <p className='text-lg'>
            This may be due to a hard shutdown of your device.
            <br />
            Please contact Customer Success for assistance.
          </p>
        </div>
      </div>
    );

  return (
    <div
      className='relative aspect-[4/3] w-full overflow-hidden rounded-2xl lg:w-3/5'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ReactPlayer
        url={previewVideoUrl}
        controls
        loop
        playing={playing}
        playsinline
        muted
        style={reactPlayerStyle}
        width='100%'
        height='100%'
        progressInterval={50}
        onProgress={onProgress}
        onSeek={onSeek}
        onEnded={onEnded}
        onPlay={onPlay}
        onPause={onPause}
        onError={onError}
      />
      {showROI ? (
        <>
          <RoiOverlay zones={generalZones} variant='alert' />
          <RoiOverlay zones={criticalZones} variant='critical' />
          <RoiOverlay zones={exclusionZones} variant='exclusion' />
        </>
      ) : null}
      {showBoundingBoxes ? (
        <BoundingBoxes boxes={detectionDetails?.[`frame-${previewFrame}`]} alertType={alertType} />
      ) : null}
      <div
        className={twMerge(
          'absolute right-2 top-2 flex space-x-1 transition-opacity duration-200',
          !showToggles && 'pointer-events-none opacity-0'
        )}
      >
        <Button
          className={twMerge(
            'w-fit border-0 bg-primary-element/60 px-2 py-1 text-xs text-primary-text transition-opacity hover:opacity-80 active:opacity-60',
            showBoundingBoxes && 'bg-brand/60'
          )}
          onClick={toggleBoundingBoxes}
        >
          Detections
        </Button>
        <Button
          className={twMerge(
            'w-fit border-0 bg-primary-element/60 px-2 py-1 text-xs text-primary-text transition-opacity hover:opacity-80 active:opacity-60',
            showROI && 'bg-brand/60'
          )}
          onClick={toggleROIs}
        >
          Zones
        </Button>
      </div>
    </div>
  );
};
