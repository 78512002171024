import { useAuthContext } from './useAuthContext';
const ALLOWED_USERS = new Set([
  'alex.virgona@presien.com',
  'danricko@gmail.com',
  'jamen.wills@presien.com',
  'joshua.truscott@presien.com',
  'nathan.gilles@presien.com',
  'mark@presien.com',
  'juan@presien.com',
  'alexander.wendel@presien.com',
  'nicholas.leong@presien.com',
  'reynaldo@presien.com',
  'support@presien.com'
]);

export const useUserHasFullAccess = () => {
  const { user } = useAuthContext();
  return ALLOWED_USERS.has(user?.email || '');
};
