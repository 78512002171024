import { useCallback, useContext, useMemo } from 'react';

import { MultiSelect } from '@fleet/components';

import { ENABLED_FILTERS_PER_ROUTE, FilterLabels, ReviewStatus, ReviewStatusLabel } from '../../../../const';
import { useGetCurrentRoute } from '../../../../hooks';
import { SelectedFiltersContext } from '../../../../providers';
import { trackEvent } from '../../../../utils';
import type { SelectOption } from '../../../atoms';

export const ReviewStatusFilter: React.FC = () => {
  const reviewStatusOptions: SelectOption<ReviewStatus>[] = useMemo(() =>
      Object.keys(ReviewStatus).map(reviewStatus => ({
        label: ReviewStatusLabel[reviewStatus as keyof typeof ReviewStatus],
        value: ReviewStatus[reviewStatus as keyof typeof ReviewStatus]
      })),
    []
  );

  const { selectedFilters, setSelectedFilters } = useContext(SelectedFiltersContext);
  const currentRoute = useGetCurrentRoute();

  const handleReviewStatusChange = useCallback(
    (value: string) => {
      const newValues = value.startsWith(ReviewStatus.ALL) ? value.slice(4) : (value.includes(ReviewStatus.ALL) ? undefined : value);
      trackEvent('review-status-filter-changed', { reviewStatus: newValues });
      setSelectedFilters({ ...selectedFilters, [FilterLabels.REVIEW_STATUS]: newValues });
    },
    [selectedFilters, setSelectedFilters]
  );

  const selectedReviewStatus = selectedFilters.reviewStatus || '';

  const filterDisabled = !ENABLED_FILTERS_PER_ROUTE[currentRoute].includes(FilterLabels.REVIEW_STATUS);

  return (
    <MultiSelect<string>
      label='Review Status'
      options={reviewStatusOptions}
      onChange={handleReviewStatusChange}
      value={selectedReviewStatus}
      disabled={filterDisabled}
    />
  );
};