import type { SWRMutationConfig } from './useApiHooks';
import { useMutationApi } from './useApiHooks';
import type { sendTo } from './useGetDetectionsReport';

export interface CreateDetectionsReport {
  organizationXid: number;
  projectXid: number;
  deviceXid?: number | null;
  commentary: string;
  sendTo: sendTo[];
  bcc?: sendTo[] | null;
  timezone: string;
  scheduledTime: string;
}

export const useCreateDetectionsReport = (swrConfig?: SWRMutationConfig<never>) =>
  useMutationApi<CreateDetectionsReport>(
    'post',
    `/internal-services/detections-report/create`,
    undefined,
    swrConfig
  );
