import { ChevronDownIcon, PaperClipIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';

import { Dropdown, Error, Loading } from '@fleet/components';
import { copyToClipboard, snakeToSentenceCase } from '@fleet/utils';

import { DetectionInfo } from './DetectionInfo';
import { PreviewPlayer } from './PreviewPlayer';
import { RejectDetectionModal } from './RejectDetectionModal';
import { ReviewButtons } from './ReviewButtons';
import { Modal } from '../../atoms';
import { AlertType, QueryParamKeys, ReviewStatus } from '../../../const';
import type { Alert } from '../../../hooks';
import {
  useAuthContext,
  useGetAlert,
  useGetDevices,
  useGetOrganizations,
  useGetProjects,
  useUpdateAlertReview
} from '../../../hooks';
import type { QueryParams } from '../../../types';

const handleOnReviewError = () => {
  toast.error('Review status update failed', { duration: 1000 });
};

const copyLinkOnClick = () => {
  copyToClipboard(window.location.href);
  toast.success('Link copied to clipboard', { duration: 1000 });
};

interface DetectionModalProps {
  alerts?: Alert[];
  isOpen: boolean;
  onClose: () => void;
  resultsContinue?: boolean;
  onFinalResult?: () => void;
  loading?: boolean;
}

export const DetectionModal: React.FC<DetectionModalProps> = ({
  alerts,
  isOpen,
  onClose,
  resultsContinue,
  onFinalResult,
  loading
}) => {
  const { currentOrganizationProjectIds, user } = useAuthContext();
  const [mutatingStatus, setMutatingStatus] = useState<ReviewStatus>();
  const [prevAlertId, setPrevAlertId] = useState('');
  const [selectedAlertId, setSelectedAlertId] = useState('');
  const [nextAlertId, setNextAlertId] = useState('');
  const [displayCompletedModal, setDisplayCompletedModal] = useState(false);

  const router = useRouter();
  const query = router.query as QueryParams;

  const alertIndex = alerts?.findIndex(alert => alert.alertId === selectedAlertId);

  const handleOnReviewSuccess = () => {
    toast.success('Review status updated successfully', { duration: 1000 });

    if (nextAlertId) return onNext();
    if (alerts && alertIndex === alerts.length - 1) setDisplayCompletedModal(true);
    handleOnClose();
  };

  const onPrev = () => {
    if (prevAlertId === '') return;

    setSelectedAlertId(prevAlertId);
    router.push({ query: { ...query, alertId: prevAlertId } satisfies QueryParams });
  };

  const onNext = () => {
    if (nextAlertId === '') return handleOnClose();

    setSelectedAlertId(nextAlertId);
    router.push({ query: { ...query, alertId: nextAlertId } satisfies QueryParams });
  };

  const { trigger: updateAlertReview, isMutating } = useUpdateAlertReview({
    onSuccess: handleOnReviewSuccess,
    onError: handleOnReviewError
  });

  useEffect(() => {
    if (alerts && alertIndex === alerts.length - 1) onFinalResult?.();
  }, [selectedAlertId, alerts, onFinalResult, alertIndex]);

  useEffect(() => {
    setSelectedAlertId(query?.alertId ?? '');

    if (alerts === undefined || query?.alertId === undefined || alerts.length === 0) return;

    const index = alerts.findIndex(alert => alert.alertId === query.alertId);

    if (index === -1) return;

    setPrevAlertId(alerts[index - 1]?.alertId ?? '');
    setNextAlertId(alerts[index + 1]?.alertId ?? '');
  }, [alerts, query]);

  const { data: orgs, error: orgsError } = useGetOrganizations({});

  const {
    data: alert,
    isLoading: alertsLoading,
    error
  } = useGetAlert({ alertId: selectedAlertId });
  const { alertId, projectId, detectionType, alertType, deviceId, reviewStatus, previewVideoUrl } =
    alert?.results ?? {};

  useGetAlert(prevAlertId ? { alertId: prevAlertId } : undefined);
  useGetAlert(nextAlertId ? { alertId: nextAlertId } : undefined);

  const { data: projects, isLoading: projectsLoading } = useGetProjects({
    projectIds: currentOrganizationProjectIds
  });
  const { data: devices, isLoading: devicesLoading } = useGetDevices(
    deviceId ? { deviceIds: deviceId } : undefined
  );

  const apiError = error?.response?.data.message ?? 'Unexpected Error';
  const baseClasses = 'w-3/5 aspect-[4/3] self-center my-4';

  const modalLoading = alertsLoading || projectsLoading || devicesLoading || loading;

  const projectName = projects?.results.find(project => project.id === projectId)?.name;

  const title = `${snakeToSentenceCase(detectionType)} detected${
    devices?.results?.[0]?.name ? ' by ' + devices?.results?.[0]?.name : ''
  } at ${projectName}`;

  const showChatButton = useMemo(() => {
    const validOrgs = !orgsError && orgs && orgs.results.some(org => org.name === 'Presien');

    const validUser =
      user &&
      [
        'paul.brenner@jcb.com',
        'mark@presien.com',
        'presienassembly@gmail.com',
        'alex.virgona@presien.com',
        'brad.mullis@leica-geosystems.com'
      ].includes(user.email);

    return validOrgs || validUser;
  }, [orgs, orgsError, user]);

  const shareDropdownOptions = [
    {
      label: 'Copy link',
      onClick: copyLinkOnClick,
      icon: <PaperClipIcon className='h-4 w-4' />
    }
  ];

  const Title = () => (
    <div className='flex w-full justify-between'>
      <p className={twMerge('flex items-center text-xl', !alert && 'opacity-0')}>
        {alertType === AlertType.CRITICAL && (
          <span className='mr-2 h-fit whitespace-nowrap rounded-2xl bg-brand px-2 py-1 text-sm text-white'>
            Critical
          </span>
        )}
        {title}
      </p>

      <div className='ml-2 flex items-center space-x-2'>
        {showChatButton ? (
          <Link
            href={`/sage?${QueryParamKeys.VIDEO_LINK}=${encodeURIComponent(previewVideoUrl ?? '')}`}
            className='rounded-xl bg-brand px-4 py-1 text-sm text-white shadow-md'
          >
            AI Copilot
          </Link>
        ) : null}
        <Dropdown
          className='rounded-2xl rounded-tl-none border text-sm'
          options={shareDropdownOptions}
        >
          {({ open }) => (
            <p
              className={twMerge(
                'transition-[border-radius, border] flex items-center gap-x-2 rounded-xl border px-3 py-1 text-sm shadow-filter-button duration-150 ease-out',
                open && 'rounded-b-none border-b-transparent'
              )}
            >
              Share <ChevronDownIcon className={twMerge('h-4 w-4', open && 'stroke-brand')} />
            </p>
          )}
        </Dropdown>
      </div>
    </div>
  );

  const onReview = (newReviewStatus: ReviewStatus) => {
    if (!alertId) return;
    if (reviewStatus === newReviewStatus) return onNext();

    setMutatingStatus(newReviewStatus);

    if (newReviewStatus === ReviewStatus.REJECTED) return;

    updateAlertReview({ alertId: alertId, newReviewStatus })
      .then(() => setMutatingStatus(undefined))
      .catch(() => setMutatingStatus(undefined));
  };

  const handleOnClose = useCallback(() => {
    if (isMutating || mutatingStatus) return;

    onClose();
    setTimeout(() => {
      setSelectedAlertId('');
      setPrevAlertId('');
      setNextAlertId('');
    }, 300);
  }, [isMutating, mutatingStatus, onClose]);

  const rejectOnClose = useCallback(() => {
    setMutatingStatus(undefined);
  }, []);

  const rejectOnSubmit = useCallback(
    (rejectReason: string) => {
      if (!alertId) return;

      return updateAlertReview({
        alertId: alertId,
        newReviewStatus: ReviewStatus.REJECTED,
        message: rejectReason
      })
        .then(() => setMutatingStatus(undefined))
        .catch(() => setMutatingStatus(undefined));
    },
    [alertId, updateAlertReview]
  );

  const completedModalOnClose = useCallback(() => {
    setDisplayCompletedModal(false);
  }, []);

  const modalHeader = modalLoading ? null : <Title />;

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleOnClose}
        header={modalHeader}
        className='flex flex-col lg:max-w-6xl'
      >
        <RejectDetectionModal
          isMutating={isMutating}
          isOpen={mutatingStatus === ReviewStatus.REJECTED && isOpen}
          onClose={rejectOnClose}
          onSubmit={rejectOnSubmit}
        />
        {(() => {
          if (modalLoading) return <Loading className={baseClasses} />;
          if (error) return <Error className={baseClasses} error={apiError} />;

          const alertIndex = alerts?.findIndex(alert => alert.alertId === selectedAlertId) ?? -1;

          return (
            <div className='flex flex-col space-y-6 lg:flex-row lg:space-y-0' key={selectedAlertId}>
              <PreviewPlayer alert={alert?.results} />
              <div className='flex w-full flex-col justify-between lg:w-2/5 lg:pl-6'>
                <DetectionInfo alert={alert?.results} />
                <div>
                  {alertIndex === -1 ? null : (
                    <div className='flex justify-center'>
                      {alertIndex + 1} of {alerts?.length ?? 'N/A'}
                      {resultsContinue ? '+' : ''}
                    </div>
                  )}
                  <ReviewButtons
                    onReview={onReview}
                    mutatingStatus={mutatingStatus}
                    isMutating={isMutating}
                    reviewStatus={reviewStatus}
                    onPrev={onPrev}
                    onNext={onNext}
                    prevAlertId={prevAlertId}
                    nextAlertId={nextAlertId}
                  />
                </div>
              </div>
            </div>
          );
        })()}
      </Modal>

      <Modal
        isOpen={displayCompletedModal}
        onClose={completedModalOnClose}
        title='Detections Reviewed'
        className='self-center'
      >
        All detections have been reviewed
      </Modal>

      <Modal
        isOpen={alerts?.length === 0 && isOpen && !selectedAlertId}
        onClose={onClose}
        title='No Detections'
        className='self-center'
      >
        No detections found to review
      </Modal>
    </>
  );
};
