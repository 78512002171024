import { useCallback, useContext } from 'react';

import { snakeToSentenceCase } from '@fleet/utils';

import { ENABLED_FILTERS_PER_ROUTE, FeaturedStatus, FilterLabels } from '../../../../const';
import { useGetCurrentRoute } from '../../../../hooks';
import { SelectedFiltersContext } from '../../../../providers';
import { trackEvent } from '../../../../utils';
import type { SelectOption } from '../../../atoms';
import { Select } from '../../../atoms';

export const FeaturedFilter: React.FC = () => {
  const featuredOptions: SelectOption<FeaturedStatus>[] = Object.values(FeaturedStatus).map(
    featured => ({
      label: snakeToSentenceCase(featured) ?? featured,
      value: featured
    })
  );

  const { selectedFilters, setSelectedFilters } = useContext(SelectedFiltersContext);

  const currentRoute = useGetCurrentRoute();

  const handleFeaturedChange = useCallback(
    (featured: FeaturedStatus) => {
      const selectedFeatured = featured === FeaturedStatus.ALL ? undefined : featured;

      trackEvent('featured-filter-changed', { featured: selectedFeatured });
      setSelectedFilters({ ...selectedFilters, featured: selectedFeatured });
    },
    [selectedFilters, setSelectedFilters]
  );

  return (
    <Select<FeaturedStatus>
      label='Featured'
      options={featuredOptions}
      onChange={handleFeaturedChange}
      value={snakeToSentenceCase(selectedFilters.featured) || 'All'}
      disabled={!ENABLED_FILTERS_PER_ROUTE[currentRoute].includes(FilterLabels.FEATURED)}
    />
  );
};
