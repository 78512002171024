import { ArrowUpIcon, DocumentPlusIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { twMerge } from 'tailwind-merge';
import { v4 } from 'uuid';

import {
  GroundingIcon,
  PresienAvatar,
  UserAvatar,
  VideoReviewIcon
} from '@fleet/components/src/icons';

import { SageGroundingSidebar } from './SageGroundingSidebar';
import type { ChatItem, InitialActionMode, SuggestedQuestion } from './utils';
import {
  JCB_ORG_FILES,
  ORG_FILES,
  REGULATIONS,
  suggestedQuestions,
  TARONGA_GROUP_ORG_FILES,
  VideoMessage
} from './utils';
import type { SageChatResponse } from '../../hooks';
import { useSageBrandingTheme, useSageChat, useSageDescription } from '../../hooks';
import type { SageSafetyReportResponse } from '../../hooks/useSageSafetyReport';
import { useSageSafetyReport } from '../../hooks/useSageSafetyReport';
import type { QueryParams } from '../../types';

export const SageChat: React.FC = () => {
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState<ChatItem[]>([]);
  const [initialActionMode, setInitialActionMode] = useState<InitialActionMode>('question');
  const [userFileDescription, setUserFileDescription] = useState<string>('');
  const [hiddenFileDescription, setHiddenFileDescription] = useState<string>('');
  const [groundingSidebarOpen, setGroundingSidebarOpen] = useState(false);

  const [orgFilesEnabled, setOrgFilesEnabled] = useState(true);
  const [enabledOrgFiles, setEnabledOrgFiles] = useState(ORG_FILES);

  const [enabledPresienFile, setEnabledPresienFile] = useState(
    REGULATIONS[REGULATIONS.findIndex(regulation => regulation.includes('US'))]
  );

  useEffect(() => {
    if (
      [
        'https://jcb.blindsight.ai',
        'https://enterprise-nightly.blindsight.ai',
        'https://enterprise-uat.blindsight.ai'
      ].includes(window.location.origin)
    )
      setEnabledOrgFiles(JCB_ORG_FILES);
  }, []);

  useEffect(() => {
    if (['https://tarongagroup.blindsight.ai'].includes(window.location.origin)) {
      setEnabledOrgFiles(TARONGA_GROUP_ORG_FILES);
    }
  }, []);

  const lastMessageRef = useRef<HTMLDivElement>(null);

  const sageTheme = useSageBrandingTheme();

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  const onSuccessChatResponse = useCallback(
    (response: SageSafetyReportResponse | SageChatResponse) => {
      setChatHistory(prev => [
        ...prev,
        {
          id: v4(),
          user: 'Presien',
          message:
            response.status === 200
              ? response.data.response
              : `An error occurred while processing the request: ${response.data as unknown as string}`
        }
      ]);
    },
    [setChatHistory]
  );

  const { trigger: uploadVideo } = useSageDescription({
    onSuccess: data => {
      setUserFileDescription(data.data.user_description);
      setHiddenFileDescription(data.data.hidden_description);
    }
  });

  const { trigger: requestSafetyReport, isMutating: isMutatingSafetyReport } = useSageSafetyReport({
    onSuccess: onSuccessChatResponse
  });

  const { trigger: sendMessage, isMutating: isMutatingMessage } = useSageChat({
    onSuccess: onSuccessChatResponse
  });

  const orgGrounding = useMemo(
    () => (orgFilesEnabled ? enabledOrgFiles : []),
    [orgFilesEnabled, enabledOrgFiles]
  );

  const router = useRouter();
  const { query } = router as { query: QueryParams };

  const handleOnQuickAction = useCallback(
    (promptText: string) => {
      setChatHistory(prev => [...prev, { id: v4(), user: 'You', message: promptText }]);

      if (promptText === 'Describe all the events in the video') {
        return setTimeout(() => {
          setChatHistory(prev => [
            ...prev,
            {
              id: v4(),
              user: 'Presien',
              message: userFileDescription
            }
          ]);
        }, 1000);
      }

      if (promptText === 'Run a compliance review') {
        return setTimeout(() => {
          requestSafetyReport({
            videoDescription: hiddenFileDescription,
            orgGrounding
          });
        }, 1000);
      }

      if (promptText === 'Generate a heads up video for this event') {
        return setTimeout(() => {
          if (typeof window === 'undefined') return;
          let videoName;
          switch (window.location.origin) {
            case 'https://jcb.blindsight.ai':
              videoName = 'test_jcb_heads_up.mp4';
              break;
            case 'https://balfourbeatty.blindsight.ai':
              videoName = 'test_bb_heads_up.mp4';
              break;
            default:
              videoName = 'test_jcb_heads_up.mp4';
          }

          setChatHistory(prev => [
            ...prev,
            {
              id: v4(),
              user: 'Presien',
              message: (
                <VideoMessage
                  videoURL={
                    'https://presien-sage-demo-public.s3.ap-southeast-2.amazonaws.com/' + videoName
                  }
                />
              )
            }
          ]);
        }, 1000);
      }

      setTimeout(() => {
        sendMessage({
          promptText,
          videoDescription: hiddenFileDescription,
          orgGrounding
        });
      }, 1000);
    },
    [userFileDescription, requestSafetyReport, orgGrounding, sendMessage, hiddenFileDescription]
  );

  const handleOnQuickActionRef = useRef(handleOnQuickAction);

  useEffect(() => {
    handleOnQuickActionRef.current = handleOnQuickAction;
  }, [handleOnQuickAction]);

  const videoUploadSuggestions: SuggestedQuestion[] = useMemo(
    () => [
      {
        icon: <VideoReviewIcon />,
        title: 'Describe',
        description: 'Get a detailed summary of all events',
        onClick: () => handleOnQuickActionRef.current('Describe all the events in the video')
      },
      {
        icon: <VideoReviewIcon />,
        title: 'Compliance',
        description: 'Run a compliance review to evaluate safety',
        onClick: () => handleOnQuickActionRef.current('Run a compliance review')
      },
      {
        icon: <VideoReviewIcon />,
        title: 'Heads Up',
        description: 'Generate a heads up video',
        onClick: () => handleOnQuickActionRef.current('Generate a heads up video for this event')
      }
    ],
    []
  );

  useEffect(() => {
    if (!router.isReady || !query?.videoLink) return;

    const videoUrl = query.videoLink;

    setChatHistory([{ id: v4(), user: 'You', message: 'Uploading video...' }]);
    uploadVideo({ videoUrl })
      .then(() => {
        setChatHistory([
          { id: v4(), user: 'You', message: <VideoMessage videoURL={videoUrl} /> },
          {
            id: v4(),
            user: 'Presien',
            message: (
              <div>
                <p className='mb-2 text-sm text-system-200'>
                  Your file has been uploaded. How would you like to continue?
                </p>
                <div className='mt-2 flex w-full space-x-2'>
                  {videoUploadSuggestions.map(({ icon, title, description, onClick }) => (
                    <button
                      key={title}
                      onClick={onClick}
                      className='flex-1 space-y-2 rounded-xl bg-system-850 p-4 text-start text-xs text-system-200 shadow-card'
                    >
                      {icon}
                      <h4>{title}</h4>
                      <p className='font-light'>{description}</p>
                    </button>
                  ))}
                </div>
              </div>
            )
          }
        ]);
      })
      .catch(() =>
        setChatHistory([
          { id: v4(), user: 'You', message: <VideoMessage videoURL={videoUrl} /> },
          {
            id: v4(),
            user: 'Presien',
            message: 'An error occurred while uploading the file. Please try again.'
          }
        ])
      );

    const newQuery = { ...query };
    delete newQuery.videoLink;
    router.replace({ query: newQuery }, undefined, { shallow: true });
  }, [
    enabledOrgFiles,
    userFileDescription,
    query,
    router,
    sendMessage,
    uploadVideo,
    videoUploadSuggestions
  ]);

  const handleOnSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setChatHistory(prev => [...prev, { id: v4(), user: 'You', message: message }]);
      setMessage('');
      setTimeout(
        () =>
          sendMessage({
            promptText: message,
            videoDescription: hiddenFileDescription,
            orgGrounding
          }),
        1000
      );
    },
    [message, sendMessage, hiddenFileDescription, orgGrounding]
  );

  const handleOnInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setMessage(e.target.value),
    []
  );
  const handleOnInputFocus = useCallback(() => setGroundingSidebarOpen(false), []);

  const handleOnQuestionTabClick = useCallback(() => setInitialActionMode('question'), []);
  const handleOnUploadTabClick = useCallback(() => setInitialActionMode('upload'), []);
  const handleOnUploadButtonClick = useCallback(() => void 0, []);

  const handleOnGroundingClick = useCallback(() => setGroundingSidebarOpen(true), []);
  const handleOnGroundingSidebarClose = useCallback(() => setGroundingSidebarOpen(false), []);

  const handleOnOrgFileChange = useCallback(setEnabledOrgFiles, [setEnabledOrgFiles]);
  const handleOnPresienFileChange = useCallback((file: string) => setEnabledPresienFile(file), []);

  return (
    <div className='relative flex grow flex-col overflow-hidden rounded-xl bg-system-900 shadow-card-lg'>
      {/* Chat Header */}
      <div className='flex items-center justify-between px-4 py-3 text-system-100 shadow-border-bottom'>
        <p>New chat</p>
        <button
          className='flex items-center gap-x-1 rounded-lg px-2 py-1 text-sm shadow-button-border'
          onClick={handleOnGroundingClick}
        >
          <GroundingIcon theme={sageTheme?.theme} /> Grounding
        </button>
      </div>

      {/* Messages */}
      <div
        className={twMerge(
          'flex h-[calc(100vh-14.5rem)] min-h-80 grow items-center space-y-4 overflow-y-auto py-4 text-sm text-system-200 shadow-border-bottom',
          chatHistory.length === 0 && 'justify-center',
          chatHistory.length > 0 && 'flex-col-reverse'
        )}
      >
        {chatHistory.length > 0 ? (
          <>
            {isMutatingMessage || isMutatingSafetyReport ? (
              <div className='w-[32rem] pb-11 pt-1'>
                <PresienAvatar className='mb-2' />
                <p className='text-sm text-system-200'>Processing ...</p>
              </div>
            ) : null}
            {chatHistory.toReversed().map(({ id, user, message }, index) => (
              <div
                key={id}
                className='w-[32rem] pb-11 pt-1'
                ref={index === 0 ? lastMessageRef : null}
              >
                {user === 'Presien' ? (
                  <PresienAvatar className='mb-2' />
                ) : (
                  <UserAvatar className='mb-2' />
                )}
                {typeof message === 'string' ? (
                  <ReactMarkdown>{message.replaceAll('\n', '&nbsp;  \n')}</ReactMarkdown>
                ) : (
                  message
                )}
              </div>
            ))}
          </>
        ) : (
          <div className='flex w-[32rem] flex-col items-center space-y-4'>
            <PresienAvatar />
            <h3 className='text-xl text-system-200'>What would you like to do today?</h3>
            <div
              className={twMerge(
                'w-full',
                initialActionMode === 'upload' && 'rounded-xl shadow-card-sm'
              )}
            >
              {/* Tabs */}
              <div
                className={twMerge(
                  'w-full items-center rounded-xl text-sm',
                  initialActionMode === 'question' && 'shadow-card-sm'
                )}
              >
                <div
                  className={twMerge(
                    'flex w-full',
                    initialActionMode === 'upload' && 'shadow-border-bottom'
                  )}
                >
                  <button
                    onClick={handleOnQuestionTabClick}
                    className={twMerge(
                      'm-2 grow rounded-lg px-2 py-1.5',
                      initialActionMode !== 'question' && 'text-system-400',
                      initialActionMode === 'question' && 'bg-system-800 text-system-200'
                    )}
                  >
                    Ask a question
                  </button>
                  <button
                    onClick={handleOnUploadTabClick}
                    className={twMerge(
                      'm-2 grow rounded-lg px-2 py-1.5',
                      initialActionMode !== 'upload' && 'text-system-400',
                      initialActionMode === 'upload' && 'bg-system-800 text-system-200'
                    )}
                  >
                    Upload a file
                  </button>
                </div>
                {initialActionMode === 'upload' ? (
                  <div className='w-full rounded-b-xl px-4 py-3.5'>
                    <button
                      className='flex w-full flex-col items-center justify-center space-y-2 rounded-xl border border-dashed border-brand-200 bg-brand-400 px-6 py-3.5 text-center text-xs text-brand-100'
                      onClick={handleOnUploadButtonClick}
                    >
                      <DocumentPlusIcon className='size-3.5' strokeWidth={2} />
                      <p>Drop or select a file to upload</p>
                      <p>.MOV or .PNG · Max 20 MB</p>
                    </button>
                  </div>
                ) : null}
              </div>

              {/* Content */}
              {initialActionMode === 'question' ? (
                <div className='mt-2 flex w-full space-x-2'>
                  {suggestedQuestions.map(({ icon, title, description, onClick }) => (
                    <button
                      key={title}
                      onClick={onClick}
                      className='grow space-y-2 rounded-xl bg-system-850 p-4 text-start text-xs text-system-200 shadow-card'
                    >
                      {icon}
                      <h4>{title}</h4>
                      <p>{description}</p>
                    </button>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>

      {/* Input */}
      <form
        onSubmit={handleOnSubmit}
        className='mx-auto my-4 flex w-[32rem] items-center gap-x-2 rounded-xl px-4 py-2 shadow-input-border'
      >
        <input
          type='text'
          placeholder='Message'
          className='grow text-sm text-system-200 focus:outline-none'
          value={message}
          onChange={handleOnInput}
          onFocus={handleOnInputFocus}
        />
        <button className='rounded-[0.4375rem] p-1.5 shadow-button-border' type='submit'>
          <ArrowUpIcon className='size-4 text-system-200' strokeWidth={2} />
        </button>
      </form>

      {/* Sage Grounding Sidebar */}
      <SageGroundingSidebar
        isOpen={groundingSidebarOpen}
        onClose={handleOnGroundingSidebarClose}
        orgFilesEnabled={orgFilesEnabled}
        setOrgFilesEnabled={setOrgFilesEnabled}
        enabledOrgFiles={enabledOrgFiles}
        onOrgGroundingChange={handleOnOrgFileChange}
        enabledPresienFile={enabledPresienFile}
        onPresienGroundingChange={handleOnPresienFileChange}
      />
    </div>
  );
};
