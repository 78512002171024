import { useApi } from './useApiHooks';
import type { Device } from './useGetDevices';
import type { Project, ProjectUser } from './useGetProjects';
import type { OrganizationUserRole, UserTypes } from '../../const';
import { generateURLSearchParams } from '../../utils';

export interface User {
  userId: string;
  email: string;
  type: UserTypes;
  givenName?: string;
  familyName?: string;
  jobTitle?: string;
  phoneNumber?: string;
  timezone?: string;
  lastActive?: string;
  projectUsers?: ProjectUser[];
}

export interface OrganizationUser {
  userId: string;
  user: User;
  organizationId: string;
  role: OrganizationUserRole;
  createdAt: string;
  className?: string;
}

export interface Organization {
  id: string;
  name: string;
  organizationUnique: string;
  createdAt: string;
  address: string;
  userLinks: OrganizationUser[];
  projects: Project[];
  devices: Device[];
  // Address fields to be added
  street?: string;
  city?: string;
  state?: string;
  postcode?: string;
  country?: string;
  xid?: number;
}

export interface GetOrganizationsQuery {
  organizationIds?: string;
  select?: (keyof Organization)[];
}

export interface GetOrganizationsResponse {
  results: Organization[];
}

export const useGetOrganizations = (queryParams?: GetOrganizationsQuery, disableQuery = false) =>
  useApi<GetOrganizationsResponse>(
    'get',
    disableQuery ? null : `/organizations${generateURLSearchParams(queryParams)}`
  );
