import { useCallback, useContext,useMemo } from 'react';

import { MultiSelect } from '@fleet/components';
import { snakeToSentenceCase } from '@fleet/utils';

import { ENABLED_FILTERS_PER_ROUTE, FilterLabels, RiskState } from '../../../../const';
import { useGetCurrentRoute } from '../../../../hooks';
import { SelectedFiltersContext } from '../../../../providers';
import { trackEvent } from '../../../../utils';
import type { SelectOption } from '../../../atoms';

export const RiskFilter: React.FC = () => {
  const riskStateOptions: SelectOption<RiskState>[] = useMemo(() =>
      Object.keys(RiskState)
        .filter(riskState => riskState !== 'RISK_NOT_ASSESSED' && riskState !== 'UNKNOWN_RISK')
        .map(riskState => ({
          label: snakeToSentenceCase(riskState) ?? riskState,
          value: RiskState[riskState as keyof typeof RiskState]
        })),
    []
  );

  const { selectedFilters, setSelectedFilters } = useContext(SelectedFiltersContext);
  const currentRoute = useGetCurrentRoute();

  const handleRiskStateChange = useCallback(
    (value: string) => {
      trackEvent('risk-state-filter-changed', { riskState: value });
      const newValues = value.startsWith(RiskState.ANY_RISK) ? value.slice(4) : (value.includes(RiskState.ANY_RISK) ? undefined : value);
      setSelectedFilters({
        ...selectedFilters,
        [FilterLabels.RISK_STATE]: newValues
      });
    },
    [selectedFilters, setSelectedFilters]
  );

  const selectedRiskState = selectedFilters.riskState || '';

  const filterDisabled = !ENABLED_FILTERS_PER_ROUTE[currentRoute].includes(FilterLabels.RISK_STATE);

  return (
    <MultiSelect<string>
      label='Risk Level'
      options={riskStateOptions}
      onChange={handleRiskStateChange}
      value={selectedRiskState}
      disabled={filterDisabled}
    />
  );
};