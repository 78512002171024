import { PlusIcon } from '@heroicons/react/24/outline';

export const SageSidebar: React.FC = () => (
  <div className='w-80 rounded-xl bg-system-900 shadow-card-lg'>
    {/* Chats Header */}
    <div className='flex items-center justify-between px-4 py-3 text-system-100 shadow-border-bottom'>
      <p>Chats</p>
      <button className='flex items-center gap-x-1 rounded-lg px-2 py-1 text-sm text-system-200 shadow-button-border'>
        <PlusIcon className='size-3' strokeWidth={2} /> New
      </button>
    </div>

    {/* Chats: TODO */}
  </div>
);
