import { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

export interface ToggleGroupOption<ValueType> {
  label: string;
  value: ValueType;
  icon: React.ReactNode;
}

interface ToggleGroupProps<ValueType> {
  label?: string;
  options: ToggleGroupOption<ValueType>[];
  onChange: (values: ValueType[]) => void;
  value: ValueType | undefined;
  variant?: 'single' | 'multi';
  disabled?: boolean;
  defaultValue?: ValueType;
}

export const ToggleGroup = <ValueType extends string>({
  options,
  label,
  onChange,
  value,
  variant = 'multi',
  disabled,
  defaultValue
}: ToggleGroupProps<ValueType>) => {
  const valuesArray = (value?.split(',') ?? []).filter(item => item !== '') as ValueType[];

  const handleOptionClick = (optionValue: ValueType) => {
    if (variant === 'single') return onChange([optionValue]);

    if (valuesArray.length === 1 && valuesArray.includes(optionValue)) return;

    if (valuesArray.includes(optionValue)) {
      onChange(valuesArray.filter(value => value !== optionValue));
    } else {
      onChange([...valuesArray, optionValue]);
    }
  };

  const handleReset = useCallback(() => {
    if (defaultValue) return onChange([defaultValue]);
    if (valuesArray.length > 0) return onChange([]);
    return onChange(options.map(option => option.value));
  }, [defaultValue, onChange, options, valuesArray.length]);

  const getResetText = () => {
    if (defaultValue) return 'Reset';
    if (valuesArray.length > 0) return 'Clear All';
    return 'Select All';
  };

  return (
    <div className={twMerge('space-y-2', disabled ? 'pointer-events-none opacity-40' : '')}>
      <div className='text-brand flex items-center justify-between text-sm'>
        <p className='font-bold text-gray-700'>{label}</p>
        {variant === 'multi' ? (
          <button className='text-brand disabled:opacity-50' onClick={handleReset}>
            {getResetText()}
          </button>
        ) : null}
      </div>
      <div className='flex w-full space-x-2'>
        {options.map(option => (
          <button
            key={option.label}
            className={twMerge(
              'hover:bg-brand/10 text-secondary-text flex w-full flex-col items-center justify-center space-y-1 rounded-2xl border border-gray-300 py-3 transition',
              valuesArray.includes(option.value)
                ? 'bg-brand hover:bg-brand/60 stroke-white text-white'
                : 'fill-secondary-text stroke-secondary-text'
            )}
            // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
            onClick={() => handleOptionClick(option.value)}
          >
            {option.icon}
            <p>{option.label}</p>
          </button>
        ))}
      </div>
    </div>
  );
};
