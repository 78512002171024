import type { AxiosError } from 'axios';
import { twMerge } from 'tailwind-merge';

import { Error, Loading } from '@fleet/components';

import { NoProjectsAssignedMessage, NoResults } from '../../atoms';
import { DetectionCard, DetectionCardSkeleton } from '../../molecules';
import { type Alert, type ApiError, useIntersectionObserver, useAuthContext } from '../../../hooks';
import type { MapState } from '../../../types';

const getStylesFromState = (state: MapState) => {
  switch (state) {
    case 'minimized':
      return 'w-full p-6';
    case 'maximized':
      return 'w-0 p-0';
    default:
      return 'w-1/2 p-6';
  }
};

interface DetectionsGridProps {
  alerts?: Alert[];
  loadingNewPage?: boolean;
  handleScrollToBottom?: () => void;
  loading?: boolean;
  mapState: MapState;
  error?: AxiosError<ApiError, unknown>;
}

export const DetectionsGrid: React.FC<DetectionsGridProps> = ({
  alerts,
  loadingNewPage,
  handleScrollToBottom,
  loading,
  mapState,
  error
}) => {
  const { currentOrganizationProjectIds } = useAuthContext();
  const containerRef = useIntersectionObserver(handleScrollToBottom);

  const styles = getStylesFromState(mapState);

  if (loading && !alerts) {
    return (
      <div className={twMerge('overflow-hidden', styles)}>
        <div className='grid animate-pulse grid-cols-detections content-start justify-around gap-6'>
          {new Array(10).fill(0).map((_, i) => (
            <DetectionCardSkeleton key={i} />
          ))}
        </div>
      </div>
    );
  }

  if (error) return <Error error={error?.response?.data.message} className={styles} />;
  if (!currentOrganizationProjectIds) return <NoProjectsAssignedMessage className={styles} />;
  if (alerts?.length === 0) return <NoResults className={styles} />;

  return (
    <div className={twMerge('relative overflow-y-auto', styles)}>
      {loading ? (
        <Loading className='pointer-events-none absolute left-0 top-0 z-50 w-full bg-black/25' />
      ) : null}
      <div className='grid grid-cols-detections content-start justify-around gap-6'>
        {alerts?.map((alert, index) => (
          <DetectionCard
            key={alert.alertId}
            alert={alert}
            dataPw={`detections-grid-card-${index}`}
          />
        ))}

        <div ref={containerRef} />
      </div>
      {loadingNewPage ? <Loading className='mt-8 flex h-fit justify-center' /> : null}
    </div>
  );
};
