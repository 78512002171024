import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
  Transition
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export interface SearchableSelectOption<ValueType = string> {
  label: string;
  value?: ValueType | undefined;
}

const getLabelFromValue = (options: SearchableSelectOption[], value: string) =>
  options.find(option => option.value === value)?.label ?? '';

interface SearchableSelectProps<ValueType> {
  label?: string;
  options: SearchableSelectOption<ValueType>[];
  value: ValueType | string;
  onChange: (value: ValueType) => void;
  disabled?: boolean;
  displayType?: DisplayType;
  placeholder?: string;
  displayOptDir?: DisplayOptDir;
  noOptionsMessage?: string;
  noResultMessage?: React.ReactNode | string;
  className?: string;
}

export enum DisplayType {
  block = 'block',
  inline = 'inline'
}

export enum DisplayOptDir {
  top = 'top',
  bottom = 'bottom'
}

export const SearchableSelect = <ValueType extends string>({
  label,
  options,
  value,
  onChange,
  disabled,
  displayType = DisplayType.block,
  placeholder = 'Type to search or select an option',
  displayOptDir = DisplayOptDir.bottom,
  noOptionsMessage = 'No options available in the list',
  noResultMessage = 'No Results',
  className = ''
}: SearchableSelectProps<ValueType>) => {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? options
      : options.filter(option => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });

  const NoResultComponent = useMemo(() => {
    if (typeof noResultMessage !== 'string') return noResultMessage;
    return <div className='block truncate bg-gray-50 px-4 py-3 font-medium'>No Results</div>;
  }, [noResultMessage]);

  const SelectOptions = () =>
    useMemo(() => {
      if (options.length === 0)
        return (
          <div className='block truncate bg-gray-50 px-4 py-3 font-medium'>{noOptionsMessage}</div>
        );

      return filteredOptions.length === 0
        ? NoResultComponent
        : filteredOptions.map(({ label, value }, idx) => (
            <ComboboxOption key={idx} value={value} className='select-none'>
              <p className='block truncate px-4 py-3 ui-active:bg-brand/10'>{label}</p>
              {idx !== options.length - 1 && <hr className='mx-4 my-1' />}
            </ComboboxOption>
          ));
    }, []);

  const isInline = useMemo(() => displayType === DisplayType.inline, [displayType]);
  const isDisplayOptDirTop = useMemo(() => displayOptDir === DisplayOptDir.top, [displayOptDir]);

  return (
    <div
      className={twMerge(
        'w-full text-gray-700',
        disabled ? 'pointer-events-none opacity-40' : '',
        className
      )}
    >
      <Combobox value={value} onChange={onChange} onClose={() => setQuery('')}>
        <div className={twMerge('w-full', isInline ? 'flex items-center' : '')}>
          {label && (
            <Label
              className={twMerge(
                'block font-medium leading-4 text-gray-500',
                isInline ? 'whitespace-nowrap pr-2' : ''
              )}
            >
              {label}
            </Label>
          )}
          <div className='relative w-full'>
            <ComboboxInput
              className={twMerge(
                'flex w-full items-center justify-between border border-neutral-300 px-4 py-3 transition-[border-radius] duration-300 focus:outline-none ui-not-open:rounded-xl',
                isInline ? '' : 'mt-3',
                isDisplayOptDirTop ? 'ui-open:rounded-b-xl' : 'ui-open:rounded-t-xl'
              )}
              aria-label={label}
              displayValue={(optionValue: ValueType) => getLabelFromValue(options, optionValue)}
              onChange={event => setQuery(event.target.value)}
              placeholder={placeholder}
            />
            <ComboboxButton className='group absolute inset-y-0 right-0 px-4'>
              <ChevronDownIcon className='h-5 w-5 transition-transform duration-300 ui-open:rotate-180 ui-not-open:rotate-0' />
            </ComboboxButton>
          </div>
        </div>

        <Transition
          enter='transition-[max-height,opacity] ease-in duration-300'
          enterFrom='opacity-0 max-h-0 border-0'
          enterTo={twMerge(
            'opacity-100 max-h-60 border',
            isDisplayOptDirTop ? 'border-b-0' : 'border-t-0'
          )}
          leave='transition-[max-height,opacity] ease-out duration-300'
          leaveFrom={twMerge(
            'opacity-100 max-h-60 border',
            isDisplayOptDirTop ? 'border-b-0' : 'border-t-0'
          )}
          leaveTo='opacity-0 max-h-0 border-0'
        >
          <ComboboxOptions
            anchor={displayOptDir}
            className={twMerge(
              'w-[var(--button-width)] w-[var(--input-width)] overflow-auto border bg-white [--anchor-max-height:18rem] focus:outline-none',
              isDisplayOptDirTop ? 'rounded-t-xl border-b-0' : 'rounded-b-xl border-t-0'
            )}
          >
            <SelectOptions />
          </ComboboxOptions>
        </Transition>
      </Combobox>
    </div>
  );
};
