type ValueOf<T> = T[keyof T];

export const getEnumKeyFromValue = <R extends string | number, T extends { [key: string]: R }>(
  myEnum: T,
  enumValue: ValueOf<T> | undefined
) => {
  if (enumValue === undefined) return;

  const keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : '';
};
