import type { IconOptions } from 'leaflet';
import { useEffect, useState } from 'react';

import { RiskState } from '../../const';
import type { MarkerColors } from '../../types';

type IconRiskState = {
  riskState: RiskState | null;
};

export type Icons = {
  [K in MarkerColors]: L.Icon<IconRiskState & IconOptions>;
};

export const useGetAlertMapMarkers = () => {
  const [icons, setIcons] = useState<Icons>();

  useEffect(() => {
    // loading 'leaflet' dynamically when the component mounts due to unsupported ssr handling
    const loadIcon = async () => {
      const L = await import('leaflet').then(({ default: L }) => L);
      const redIcon = new L.Icon<IconRiskState & IconOptions>({
        iconUrl: '/marker-icon-red.png',
        //shadowUrl: '/marker-shadow.png',
        iconSize: [25, 41],
        riskState: RiskState.EXTREME_RISK
      });
      const orangeIcon = new L.Icon<IconRiskState & IconOptions>({
        iconUrl: '/marker-icon-orange.png',
        //shadowUrl: '/marker-shadow.png',
        iconSize: [25, 41],
        riskState: RiskState.HIGH_RISK
      });
      const yellowIcon = new L.Icon<IconRiskState & IconOptions>({
        iconUrl: '/marker-icon-yellow.png',
        //shadowUrl: '/marker-shadow.png',
        iconSize: [25, 41],
        riskState: RiskState.MODERATE_RISK
      });
      const blueIcon = new L.Icon<IconRiskState & IconOptions>({
        iconUrl: '/marker-icon-blue.png',
        //shadowUrl: '/marker-shadow.png',
        iconSize: [25, 41],
        riskState: RiskState.UNKNOWN_RISK
      });
      const greenIcon = new L.Icon<IconRiskState & IconOptions>({
        iconUrl: '/marker-icon-green.png',
        //shadowUrl: '/marker-shadow.png',
        iconSize: [25, 41],
        riskState: null
      });
      setIcons({
        red: redIcon,
        orange: orangeIcon,
        yellow: yellowIcon,
        green: greenIcon,
        blue: blueIcon
      });
    };

    loadIcon();
  }, []);

  return icons;
};
