export enum DeviceStatus {
  Offline = 'Offline',
  Online = 'Online'
}

type DeviceStatusIndicator = {
  status?: DeviceStatus;
  displayDot?: boolean;
};

const DeviceStatusColour: Record<DeviceStatus, string> = {
  [DeviceStatus.Offline]: 'text-gray-400',
  [DeviceStatus.Online]: 'text-green-450'
};

export const DeviceStatusIndicator: React.FC<DeviceStatusIndicator> = ({
  status = DeviceStatus.Offline,
  displayDot = true
}) => (
  <div className={`flex items-center font-normal ${DeviceStatusColour[status]}`}>
    {displayDot && <span className='-translate-y-1 text-4xl leading-[0]'>•</span>}
    {status}
  </div>
);
