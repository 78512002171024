import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import type { SelectOption } from '@fleet/components';
import { Button, Select } from '@fleet/components';
import { SearchableSelect } from '@fleet/components';
import { Loading } from '@fleet/components';

import type { Recipients } from '../RecipientsFields/RecipientsFields';
import { RecipientsFields } from '../RecipientsFields/RecipientsFields';
import { Modal } from '../../atoms';
import { SUPPORTED_TIMEZONES } from '../../../const';
import { useGetAdminOrganizations } from '../../../hooks';
import { useGetProjects } from '../../../hooks';
import { useGetDevices } from '../../../hooks';
import type { DetectionsReport } from '../../../hooks/api/useGetDetectionsReport';
import { useUpdateDetectionsReport } from '../../../hooks/api/useUpdateDetectionsReport';
import type { AxiosErrorResponseBody } from '../../../utils';
import { generateTimeSlots } from '../../../utils';

interface AdminEditDetectionsReportModalProps {
  detectionsReport: DetectionsReport;
  isOpen: boolean;
  onClose: () => void;
}

export const AdminEditDetectionsReportModal: React.FC<AdminEditDetectionsReportModalProps> = ({
  detectionsReport,
  isOpen,
  onClose
}) => {
  const { trigger: updateDetectionsReport, isMutating } = useUpdateDetectionsReport({
    id: detectionsReport.id
  });
  const { data: orgData, isLoading: isLoadingOrgData } = useGetAdminOrganizations();
  const [selectedOrganization, setSelectedOrganization] = useState<string>('');
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [selectedDevice, setSelectedDevice] = useState<string>('');
  const [timezone, setTimezone] = useState<string>(detectionsReport.timezone || '');
  const [scheduledTime, setScheduledTime] = useState<string>(detectionsReport.scheduledTime || '');
  const [commentary, _setCommentary] = useState<string>(detectionsReport.commentary || '');
  const [recipients, setRecipients] = useState<Recipients[]>(detectionsReport.sendTo || []);
  const [bcc, setBcc] = useState<Recipients[]>(detectionsReport.bcc || []);
  const [isFirstLoadOrg, setIsFirstLoadOrg] = useState(true);
  const [isFirstLoadProject, setIsFirstLoadProject] = useState(true);
  const [isFirstLoadDevice, setIsFirstLoadDevice] = useState(true);

  const { data: projectsData, isLoading: isLoadingProjectsData } = useGetProjects({
    organizationIds: selectedOrganization?.split('|')[0] || undefined
  });

  const { data: devicesData, isLoading: isLoadingDevicesData } = useGetDevices({
    projectIds: selectedProject?.split('|')[0] || undefined
  });

  useEffect(() => {
    if (!isLoadingOrgData && orgData && isFirstLoadOrg) {
      setSelectedOrganization(
        `${detectionsReport.organization.id}|${detectionsReport.organizationXid}`
      );
      setIsFirstLoadOrg(false);
    }
  }, [isLoadingOrgData, orgData, detectionsReport, isFirstLoadOrg]);

  useEffect(() => {
    if (!isLoadingProjectsData && projectsData && isFirstLoadProject) {
      setSelectedProject(`${detectionsReport.project.id}|${detectionsReport.projectXid}`);
    }
    setIsFirstLoadProject(false);
  }, [isLoadingProjectsData, projectsData, detectionsReport, isFirstLoadProject]);

  useEffect(() => {
    if (!isLoadingDevicesData && devicesData && detectionsReport.deviceXid && isFirstLoadDevice) {
      setSelectedDevice(`${detectionsReport.device?.id}|${detectionsReport.deviceXid}`);
      setIsFirstLoadDevice(false);
    }
  }, [isLoadingDevicesData, devicesData, detectionsReport, isFirstLoadDevice]);

  const organizationOptions = useMemo(() => {
    if (!orgData) return [];
    return orgData.map(organization => ({
      label: organization.name,
      value: `${organization.id}|${organization.xid}`
    }));
  }, [orgData]);

  const projectOptions: SelectOption[] = useMemo(() => {
    if (!selectedOrganization) return [];
    const optionData = projectsData?.results || [];
    return optionData.map(row => ({ label: row.name, value: `${row.id}|${row.xid}` }));
  }, [projectsData, selectedOrganization]);

  const devicesOptions: SelectOption[] = useMemo(() => {
    if (!selectedProject) return [];
    const optionData = devicesData?.results || [];
    return optionData.map(row => ({ label: row.name, value: `${row.id}|${row.xid}` }));
  }, [devicesData, selectedProject]);

  const handleOrganizationChange = useCallback((organizationId: string) => {
    setSelectedOrganization(organizationId);
    setSelectedProject('');
    setSelectedDevice('');
  }, []);

  // @todo: improve validation so they are inline
  const validate = useCallback(() => {
    const errors = [];
    if (!selectedOrganization) errors.push('Organization is required');
    if (!selectedProject) errors.push('Project is required');
    if (!timezone) errors.push('Timezone is required');
    if (!scheduledTime) errors.push('Scheduled Time is required');
    for (const recipient of recipients) {
      if (!recipient.name?.trim() || !recipient.email?.trim())
        errors.push('Name and Email are required in all recipients (Send to)');
    }
    if (errors.length > 0) {
      toast.error(errors[0]);
      return false;
    }
    return true;
  }, [recipients, selectedOrganization, selectedProject, timezone, scheduledTime]);

  const onSubmit = useCallback(async () => {
    if (!validate()) return;

    const data = {
      organizationXid: Number.parseInt(selectedOrganization.split('|')[1]),
      projectXid: Number.parseInt(selectedProject.split('|')[1]),
      ...(selectedDevice
        ? { deviceXid: Number.parseInt(selectedDevice.split('|')[1]) }
        : { deviceXid: null }),
      commentary,
      sendTo: recipients,
      ...(bcc && bcc.length > 0 ? { bcc } : { bcc: null }),
      timezone,
      scheduledTime
    };

    try {
      await updateDetectionsReport(data);
      toast.success('Detections Report record created successfully.');
      onClose();
    } catch (error) {
      const message =
        error instanceof AxiosError
          ? (error.response?.data as AxiosErrorResponseBody).message
          : 'Failed to create Detections Report record.';
      toast.error(message);
    }
  }, [
    commentary,
    recipients,
    scheduledTime,
    timezone,
    selectedOrganization,
    selectedProject,
    selectedDevice,
    bcc,
    updateDetectionsReport,
    onClose,
    validate
  ]);

  const timezoneOptions = useMemo(
    () =>
      SUPPORTED_TIMEZONES.filter(timezone => timezone.timezone !== 'UTC').map(timezone => ({
        label: timezone.timezone,
        value: timezone.timezone
      })),
    []
  );

  const scheduledTimeOptions = useMemo(
    () => generateTimeSlots().map(timeslot => ({ label: timeslot, value: timeslot })),
    []
  );

  return (
    <Modal title='Edit Detections Report Recipient' isOpen={isOpen} onClose={onClose}>
      {isLoadingOrgData ? (
        <Loading />
      ) : (
        <div className='space-y-4 [&_input]:w-full'>
          <SearchableSelect
            label='Organization *'
            placeholder={isLoadingOrgData ? 'Loading options...' : 'Select Organization'}
            options={organizationOptions}
            value={selectedOrganization}
            onChange={handleOrganizationChange}
            className='mr-3'
          />
          <SearchableSelect
            label='Project *'
            placeholder={isLoadingProjectsData ? 'Loading options...' : 'Select Project'}
            options={projectOptions}
            value={selectedProject}
            onChange={setSelectedProject}
            noOptionsMessage='No projects available'
          />
          <SearchableSelect
            label='Device'
            placeholder={isLoadingDevicesData ? 'Loading options...' : 'Select Device'}
            options={devicesOptions}
            value={selectedDevice}
            onChange={setSelectedDevice}
            noOptionsMessage='No devices available'
          />
          <Select
            label='Timezone *'
            options={timezoneOptions}
            value={timezone}
            onChange={setTimezone}
          />
          <Select
            label='Scheduled Time *'
            options={scheduledTimeOptions}
            value={scheduledTime}
            onChange={setScheduledTime}
          />
          <RecipientsFields
            label='Send to *'
            recipients={recipients}
            setRecipients={setRecipients}
            minimumRecipients={1}
            className='py-1'
          />
          <RecipientsFields label='Bcc *' recipients={bcc} setRecipients={setBcc} />
          {isMutating ? (
            <Loading />
          ) : (
            <div className='flex justify-between gap-4 pt-3'>
              <Button
                variant='mono'
                className='h-14 w-1/2 justify-center text-lg'
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant='solid'
                className='h-14 w-1/2 justify-center text-lg'
                onClick={onSubmit}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};
