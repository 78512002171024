import { TrashIcon } from '@heroicons/react/24/outline';
import { useCallback, useMemo } from 'react';

import { Button, SearchableSelect } from '@fleet/components';
import { Select } from '@fleet/components';
import { Loading } from '@fleet/components';

import { ProjectUserRole } from '../../../const';
import { useAuthContext } from '../../../hooks';
import { useGetProjects } from '../../../hooks';
import type { ProjectRole } from '../../../hooks/api/useAddUser';
import { projectUserRoleOptions } from '../../../utils';

type ProjectsRolesProps = {
  projectsRoles: ProjectRole[];
  setProjectsRoles: React.Dispatch<React.SetStateAction<ProjectRole[]>>;
};

export const ProjectsRoles: React.FC<ProjectsRolesProps> = ({
  projectsRoles,
  setProjectsRoles
}) => {
  const { currentOrganizationId } = useAuthContext();

  const { data, isLoading } = useGetProjects({ organizationIds: currentOrganizationId });

  const projectData = useMemo(
    () =>
      data?.results.map(project => ({
        label: project.name,
        value: project.id
      })) || [],
    [data?.results]
  );

  const projectOptions = useMemo(
    () =>
      projectData.filter(
        option => !projectsRoles.some(projectRole => projectRole.projectId === option.value)
      ) || [],
    [projectData, projectsRoles]
  );

  const handleSelectProject = useCallback(
    (projectId: string) => {
      setProjectsRoles(prevProjectsRoles => {
        if (
          projectId &&
          !prevProjectsRoles.some(projectRole => projectRole.projectId === projectId)
        ) {
          return [...prevProjectsRoles, { projectId, role: ProjectUserRole.REVIEWER }];
        }
        return prevProjectsRoles;
      });
    },
    [setProjectsRoles]
  );

  const handleRemoveProjectRole = useCallback(
    (projectId: string) => {
      setProjectsRoles((prevProjectsRoles: ProjectRole[]) =>
        prevProjectsRoles.filter(projectRole => projectRole.projectId !== projectId)
      );
    },
    [setProjectsRoles]
  );

  const handleProjectRoleChange = useCallback(
    (role: string, projectId: string) => {
      setProjectsRoles(prevProjectsRoles =>
        prevProjectsRoles.map(projectRole =>
          projectRole.projectId === projectId ? { ...projectRole, role } : projectRole
        )
      );
    },
    [setProjectsRoles]
  );

  const handleAddAllProjects = useCallback(() => {
    setProjectsRoles(prevProjectsRoles => [
      ...prevProjectsRoles,
      ...projectOptions.map(projectOption => ({
        projectId: projectOption.value,
        role: ProjectUserRole.REVIEWER
      }))
    ]);
  }, [setProjectsRoles, projectOptions]);

  const handleRemoveAllProjects = useCallback(() => {
    setProjectsRoles([]);
  }, [setProjectsRoles]);

  return isLoading ? (
    <Loading loadingMessage='Component loading, please wait' />
  ) : (
    <>
      <SearchableSelect
        label='Assign Project(s)'
        options={projectOptions}
        value=''
        onChange={handleSelectProject}
        placeholder='Type to search or click to select projects'
        noOptionsMessage='There are no more projects available'
      />

      <div className='flex'>
        <Button
          className='mr-2'
          disabled={projectOptions.length === 0}
          variant='solid'
          onClick={handleAddAllProjects}
        >
          Add all
        </Button>
        <Button
          variant='solid'
          disabled={projectsRoles.length === 0}
          onClick={handleRemoveAllProjects}
        >
          Remove all
        </Button>
      </div>
      <div className='pt-2 font-medium leading-4 text-gray-500'>
        Projects{projectsRoles.length > 0 && ` (${projectsRoles.length})`}
      </div>
      {projectsRoles.length === 0 ? (
        <div>No projects assigned</div>
      ) : (
        <div className='max-h-96 overflow-y-auto px-1'>
          {projectsRoles.map(projectRole => {
            const { projectId, role } = projectRole;
            return (
              <div key={projectId} className='mb-2 flex items-center justify-between'>
                <div className='mr-2 flex w-full items-center justify-between'>
                  <div className='w-9/12'>
                    {projectData.find(option => option.value === projectId)?.label}
                  </div>
                  <div className='w-3/12'>
                    <Select
                      options={projectUserRoleOptions}
                      value={role}
                      onChange={value => {
                        handleProjectRoleChange(value, projectId);
                      }}
                    />
                  </div>
                </div>
                <TrashIcon
                  className='size-[22px] cursor-pointer text-brand'
                  onClick={() => handleRemoveProjectRole(projectId)}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
