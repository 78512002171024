import { Label, Radio, RadioGroup } from '@headlessui/react';
import { DocumentPlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { GroundingIcon } from '@fleet/components';

import { SageSwitch } from './SageSwitch';
import { JCB_ORG_FILES, ORG_FILES, REGULATIONS, TARONGA_GROUP_ORG_FILES } from './utils';
import { useSageBrandingTheme } from '../../hooks';

interface SageGroundingSidebarProps {
  isOpen: boolean;
  onClose: () => void;
  orgFilesEnabled: boolean;
  setOrgFilesEnabled: (enabled: boolean) => void;
  enabledOrgFiles: string[];
  onOrgGroundingChange: (enabledFiles: string[]) => void;
  enabledPresienFile: string;
  onPresienGroundingChange: (enabledFile: string) => void;
}

export const SageGroundingSidebar: React.FC<SageGroundingSidebarProps> = ({
  isOpen,
  onClose,
  orgFilesEnabled,
  setOrgFilesEnabled,
  enabledOrgFiles,
  onOrgGroundingChange,
  enabledPresienFile,
  onPresienGroundingChange
}) => {
  const [currentTab, setCurrentTab] = useState<'org' | 'presien'>('org');

  const sidebarRef = useRef<HTMLDivElement>(null);

  const sageTheme = useSageBrandingTheme();

  const orgFilesList = useMemo(() => {
    if (typeof window === 'undefined') return ORG_FILES;
    if (
      [
        'https://jcb.blindsight.ai',
        'https://enterprise-nightly.blindsight.ai',
        'https://enterprise-uat.blindsight.ai'
      ].includes(window.location.origin)
    )
      return JCB_ORG_FILES;
    if (['https://tarongagroup.blindsight.ai'].includes(window.location.origin))
      return TARONGA_GROUP_ORG_FILES;
    return ORG_FILES;
  }, []);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node) && isOpen) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleOnOrgTabClick = useCallback(() => setCurrentTab('org'), []);
  const handleOnPresienTabClick = useCallback(() => setCurrentTab('presien'), []);
  const handeOnGroundingUpload = useCallback(() => void 0, []);

  const handleOnOrgFilesToggled = useCallback(
    (checked: boolean) => {
      setOrgFilesEnabled(checked);
    },
    [setOrgFilesEnabled]
  );

  const handleOrgFileToggled = useCallback(
    (checked: boolean, file: string) => {
      onOrgGroundingChange?.(
        checked ? [...enabledOrgFiles, file] : enabledOrgFiles.filter(f => f !== file)
      );
    },
    [enabledOrgFiles, onOrgGroundingChange]
  );

  return (
    <div
      className={twMerge(
        'absolute right-0 h-full w-80 transform rounded-r-xl bg-system-900 shadow-card-lg transition-[transform,opacity] duration-200 ease-in-out',
        isOpen ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-25'
      )}
      ref={sidebarRef}
    >
      {/* Grounding Header */}
      <div className='flex items-center justify-between px-4 py-3 text-system-100 shadow-border-bottom'>
        <div className='flex items-center gap-x-2'>
          <GroundingIcon theme={sageTheme?.theme} className='mt-0.5' /> Grounding
        </div>
        <button className='py-1' onClick={onClose}>
          <XMarkIcon className='size-5 text-system-200' strokeWidth={1.5} />
        </button>
      </div>

      {/* Tabs */}
      <div className='flex w-full px-4 py-3 text-sm shadow-border-bottom'>
        <button
          onClick={handleOnOrgTabClick}
          className={twMerge(
            'flex-1 rounded-lg px-2 py-1.5',
            currentTab !== 'org' && 'text-system-400',
            currentTab === 'org' && 'bg-system-800 text-system-200'
          )}
        >
          {sageTheme?.theme ?? 'Acme Corp'}
        </button>
        <button
          onClick={handleOnPresienTabClick}
          className={twMerge(
            'flex-1 rounded-lg px-2 py-1.5',
            currentTab !== 'presien' && 'text-system-400',
            currentTab === 'presien' && 'bg-system-800 text-system-200'
          )}
        >
          Regulatory
        </button>
      </div>

      {/* Organisation Content */}
      {currentTab === 'org' ? (
        <>
          {/* Upload Files */}
          <div className='p-4 shadow-border-bottom'>
            <button
              onClick={handeOnGroundingUpload}
              className='flex w-full flex-col items-center space-y-2 rounded-xl border border-dashed border-brand-200 bg-brand-400 px-6 py-4 text-xs text-brand-100'
            >
              <DocumentPlusIcon className='size-4' />
              <p>Upload file to Grounding</p>
              <p className='font-light'>.PDF or .TXT, Max 20 MB</p>
            </button>
          </div>

          {/* Files Toggles */}
          <div className='w-full'>
            <div className='flex w-full items-center justify-between px-4 py-3 shadow-border-bottom-sm'>
              <p className='text-sm font-medium text-system-300'>Files</p>
              <SageSwitch checked={orgFilesEnabled} onToggled={handleOnOrgFilesToggled} />
            </div>
            <div className='max-h-[24.5rem] overflow-auto'>
              {orgFilesList.map(name => (
                <div
                  key={name}
                  className='flex items-center justify-between px-4 py-2 shadow-border-bottom-sm'
                >
                  <div className='max-w-56'>
                    <p
                      className={twMerge(
                        'break-words text-sm text-system-300 transition-opacity duration-200 ease-in-out',
                        !orgFilesEnabled && 'opacity-50'
                      )}
                    >
                      {name}
                    </p>
                    <p className='block w-fit text-xs text-brand-100 hover:underline'>View</p>
                  </div>
                  <SageSwitch
                    checked={enabledOrgFiles.includes(name)}
                    // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
                    onToggled={checked => handleOrgFileToggled(checked, name)}
                    className={orgFilesEnabled ? '' : 'pointer-events-none opacity-50'}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null}

      {/* Presien Content */}
      {currentTab === 'presien' ? (
        <div className='w-full'>
          <p className='px-4 py-3 text-sm font-medium text-system-300 shadow-border-bottom-sm'>
            Regulations
          </p>

          {/* Regulations Radio */}
          <RadioGroup
            value={enabledPresienFile}
            onChange={onPresienGroundingChange}
            className='max-h-[32.625rem] overflow-auto'
          >
            {REGULATIONS.map(name => (
              <Radio
                key={name}
                value={name}
                as='button'
                className='flex w-full items-center justify-between px-4 py-2 text-start shadow-border-bottom-sm'
              >
                <div className='max-w-56'>
                  <Label className='block cursor-pointer text-sm text-system-300 transition-opacity duration-200 ease-in-out'>
                    {name}
                  </Label>
                  <p
                    className='block w-fit text-xs text-brand-100 hover:underline'
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    View
                  </p>
                </div>
                <div className='flex size-[1.125rem] items-center justify-center rounded-full ring-1 ring-system-600 ui-checked:ring-brand-100'>
                  <div className='hidden size-3 rounded-full bg-brand-100 ui-checked:block' />
                </div>
              </Radio>
            ))}
          </RadioGroup>
        </div>
      ) : null}
    </div>
  );
};
