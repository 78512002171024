import { ArrowDownTrayIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import type { BarDatum, BarTooltipProps, ResponsiveBarCanvasProps } from '@nivo/bar';
import { useCallback, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { Dropdown } from '@fleet/components';

import { DateAggregation } from '../../../const';
import { DATE_FORMAT, dayJS } from '../../../const';
import { useGetDevices, useGetProjects, useRetrieveFilters, useAuthContext } from '../../../hooks';
import {
  downloadCSV,
  getCSVFileName,
  getFormattedTZDate,
  ResponsiveBarCanvas
} from '../../../utils';

export interface LegendItem {
  icon: React.ReactNode;
  label: string;
}

interface AnalyticsGraphProps {
  count: number;
  yLabel?: string;
  legends?: LegendItem[];
  graphProps: ResponsiveBarCanvasProps<BarDatum>;
  customTooltip: (props: BarTooltipProps<BarDatum>) => React.ReactNode;
  csvData: Object[];
  csvLabel: string;
  periodEndAt: string;
  dateAggregation: DateAggregation;
}

export const AnalyticsGraph: React.FC<AnalyticsGraphProps> = ({
  count,
  yLabel,
  legends,
  graphProps,
  customTooltip,
  csvData,
  csvLabel,
  periodEndAt,
  dateAggregation
}) => {
  const { currentOrganizationProjectIds } = useAuthContext();
  const filters = useRetrieveFilters();

  const { currentOrganization } = useAuthContext();
  const { data: projects } = useGetProjects({
    projectIds: currentOrganizationProjectIds
  });
  const { data: devices } = useGetDevices({});

  const project = useMemo(
    () => projects?.results.find(({ id }) => id === filters?.projectIds)?.name,
    [projects?.results, filters?.projectIds]
  );

  const device = useMemo(
    () => devices?.results?.find(({ id }) => id === filters?.deviceIds)?.name,
    [devices?.results, filters?.deviceIds]
  );
  const { axisLeft, ...rest } = graphProps;

  const tooltipFunction = useCallback(
    (props: BarTooltipProps<BarDatum>) => {
      const diffUnit = dateAggregation === DateAggregation.WEEKLY ? 'week' : 'day';

      return (
        <div className='invisible animate-appear rounded-xl border border-primary-element bg-white p-2 text-sm font-medium shadow-xl'>
          <p className='mb-2'>
            {getFormattedTZDate({
              date: dayJS({ date: periodEndAt }).subtract(count - props.index, diffUnit),
              dateFormat: DATE_FORMAT,
              dateAggregation
            })}
          </p>
          {customTooltip(props)}
        </div>
      );
    },
    [dateAggregation, periodEndAt, count, customTooltip]
  );

  const handleDisplayLabel = useCallback(
    (value: string) => {
      const count = rest.data.length;
      const limit = 10;
      const aggregation = dateAggregation === 'daily' ? 'day' : 'week';

      const index = rest.data.findIndex(v => v[aggregation] === value);
      const step = Math.ceil(count / limit);

      return index % step === 0 ? value : '';
    },
    [dateAggregation, rest.data]
  );

  const defaultGraphProps: ResponsiveBarCanvasProps<BarDatum> = useMemo(
    () => ({
      indexBy: dateAggregation === 'daily' ? 'day' : 'week',
      enableGridY: false,
      padding: 0.1,
      axisBottom: {
        tickSize: 4,
        tickPadding: 4,
        format: handleDisplayLabel
      },
      axisLeft: {
        tickSize: 4,
        tickPadding: 4,
        tickValues: 4,
        legend: yLabel,
        legendPosition: 'middle',
        legendOffset: -60,
        format: (value: number) => (Math.floor(value) === value && Boolean(value) ? value : ''),
        ...axisLeft
      },
      margin: { bottom: 20, top: 20, left: 70, right: 10 },
      enableLabel: false,
      theme: {
        axis: {
          domain: { line: { strokeWidth: 1, stroke: '#333333' } },
          ticks: { text: { fontSize: 12 } },
          legend: { text: { fontSize: 16 } }
        }
      },
      colors: [`rgb(255, 125, 51)`],
      tooltip: tooltipFunction,
      ...rest
    }),
    [axisLeft, dateAggregation, handleDisplayLabel, rest, tooltipFunction, yLabel]
  );

  const downloadCSVData = useCallback(() => {
    const csvFileName = getCSVFileName({
      org: currentOrganization?.name,
      project,
      device,
      label: csvLabel
    });

    const processedCSVData: Object[] = csvData.map(data => {
      return {
        ...(currentOrganization ? { 'Organization name': currentOrganization?.name } : {}),
        ...(project ? { 'Project name': project } : {}),
        ...(device ? { 'Device name': device } : {}),
        ...data
      };
    });

    downloadCSV(processedCSVData, csvFileName);
  }, [csvData, csvLabel, device, currentOrganization, project]);

  const dropdownOptions = useMemo(
    () => [
      {
        label: '.csv',
        onClick: downloadCSVData,
        icon: <ArrowDownTrayIcon className='h-4 w-4' />
      }
    ],
    [downloadCSVData]
  );

  return (
    <>
      <div className='flex h-60'>
        <div className='h-full min-w-0 flex-grow'>
          <ResponsiveBarCanvas {...defaultGraphProps} />
        </div>
        {csvData ? (
          <Dropdown
            className='w-full rounded-b-2xl rounded-t-none border border-t-0 text-sm'
            options={dropdownOptions}
          >
            {({ open }) => (
              <p
                className={twMerge(
                  'flex items-center gap-x-2 rounded-xl border px-3 py-1 text-sm shadow-filter-button transition-[border-radius] duration-150 ease-out',
                  open && 'rounded-b-none'
                )}
              >
                Export <ChevronDownIcon className={twMerge('h-4 w-4', open && 'stroke-brand')} />
              </p>
            )}
          </Dropdown>
        ) : null}
      </div>
      {legends && legends.length > 0 && (
        <div className='mt-2 flex justify-end space-x-2'>
          {legends.map((legend, index) => (
            <div key={index} className='flex items-center space-x-1'>
              {legend.icon}
              <p>{legend.label}</p>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
