import { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';

import {
  getAggregatedIndex,
  getIndexChangeTooltipText,
  ProcessedChangeLine
} from './AnalyticsSummary.utils';
import { Tooltip } from '../../atoms';
import { AnalyticsCard } from '../../molecules';
import { AlertType, DAY_IN_S, dayJS, DetectionType, FilterLabels, Routes } from '../../../const';
import {
  useAuthContext,
  useGetBlindsightIndex,
  useGetDevices,
  useGetOrganizations,
  useRetrieveFilters
} from '../../../hooks';
import { AnalyticsContext } from '../../../providers';
import type { AggregatedAnalyticsDetections } from '../../../types';
import type { QueryParams } from '../../../types';
import { getAggregatedIndexChange, getStoredTimezone } from '../../../utils';

export const AnalyticsSummary: React.FC<AggregatedAnalyticsDetections> = ({
  peopleDetections,
  critPeopleDetections,
  vehicleDetections,
  critVehicleDetections,
  coneDetections,
  critConeDetections
}) => {
  const { currentOrganizationId } = useAuthContext();
  const router = useRouter();
  const {
    analyticsFilters,
    dateAggregation,
    comparison,
    comparisonCurrText,
    comparisonPrevText,
    count
  } = useContext(AnalyticsContext);

  const { projectIds, deviceIds, reportingPeriodSecs, startAt } = analyticsFilters;

  const filters = useRetrieveFilters();
  const { data: orgs } = useGetOrganizations({});

  const {
    data: devicesData,
    isLoading: devicesLoading,
    error: devicesError
  } = useGetDevices({ organizationIds: currentOrganizationId, projectIds, deviceIds });

  const {
    data: indexData,
    isLoading: indexLoading,
    error: indexError
  } = useGetBlindsightIndex({
    queryParams: {
      ...(comparison
        ? {
            startAt: dayJS({ date: startAt })
              .subtract(reportingPeriodSecs, 'seconds')
              .toISOString(),
            reportingPeriodSecs: reportingPeriodSecs * 2
          }
        : { startAt: dayJS({ date: startAt }).toISOString(), reportingPeriodSecs }),
      timezone: getStoredTimezone(),
      organizationIds: currentOrganizationId,
      projectIds,
      deviceIds
    },
    variant: dateAggregation
  });

  const periodEndAt = dayJS({ date: analyticsFilters.startAt })
    .add(analyticsFilters.reportingPeriodSecs, 's')
    .toISOString();

  const aggregatedIndex = useMemo(
    () => getAggregatedIndex({ indexData, count, periodEndAt, dateAggregation }),
    [count, dateAggregation, indexData, periodEndAt]
  );

  const comparisonRange = analyticsFilters.reportingPeriodSecs > DAY_IN_S * 8 ? 'month' : 'day';

  const daysInCurrRange =
    comparisonRange === 'month'
      ? dayJS({ date: periodEndAt }).subtract(1, 'millisecond').daysInMonth()
      : 7;

  const daysInPrevRange =
    comparisonRange === 'month'
      ? dayJS({ date: periodEndAt }).subtract(1, 'millisecond').subtract(1, 'month').daysInMonth()
      : 7;

  const currIndexTimes = useMemo(
    () =>
      comparison
        ? aggregatedIndex.slice(-daysInCurrRange).flatMap(index => (index === null ? [] : [index]))
        : aggregatedIndex.flatMap(index => (index === null ? [] : [index])),
    [aggregatedIndex, comparison, daysInCurrRange]
  );

  const prevIndexTimes = useMemo(
    () =>
      comparison
        ? aggregatedIndex
            .slice(0, daysInPrevRange)
            .flatMap(index => (index === null ? [] : [index]))
        : [],
    [aggregatedIndex, comparison, daysInPrevRange]
  );

  const prevIndexAverage = useMemo(() => {
    const indexAvg = Math.round(prevIndexTimes.reduce((a, c) => a + c, 0) / prevIndexTimes.length);
    return Number.isNaN(indexAvg) ? 0 : indexAvg;
  }, [prevIndexTimes]);

  const currIndexAverage = useMemo(() => {
    const indexAvg = Math.round(currIndexTimes.reduce((a, c) => a + c, 0) / currIndexTimes.length);
    return Number.isNaN(indexAvg) ? 0 : indexAvg;
  }, [currIndexTimes]);

  const aggregatedIndexChange = getAggregatedIndexChange(prevIndexAverage, currIndexAverage);

  const totalPeopleDetections = useMemo(
    () => peopleDetections.reduce((a, b) => a + b, 0),
    [peopleDetections]
  );

  const totalCritPeopleDetections = useMemo(
    () => critPeopleDetections.reduce((a, b) => a + b, 0),
    [critPeopleDetections]
  );

  const totalVehicleDetections = useMemo(
    () => vehicleDetections.reduce((a, b) => a + b, 0),
    [vehicleDetections]
  );

  const totalCritVehicleDetections = useMemo(
    () => critVehicleDetections.reduce((a, b) => a + b, 0),
    [critVehicleDetections]
  );

  const totalConeDetections = useMemo(
    () => coneDetections.reduce((a, b) => a + b, 0),
    [coneDetections]
  );

  const totalCritConeDetections = useMemo(
    () => critConeDetections.reduce((a, b) => a + b, 0),
    [critConeDetections]
  );

  const changeTooltipText =
    aggregatedIndexChange === 0
      ? null
      : getIndexChangeTooltipText({
          aggregatedIndex,
          percentChange: aggregatedIndexChange,
          comparisonCurrText,
          comparisonPrevText
        });

  const ProcessedChangeComponent = useMemo(
    () =>
      aggregatedIndexChange === 0
        ? null
        : ProcessedChangeLine({ aggregatedIndexChange, comparisonCurrText }),
    [aggregatedIndexChange, comparisonCurrText]
  );

  const navigateToDetections = (alertType: AlertType, detectionType: string) => {
    router.push({
      pathname: Routes.DETECTIONS,
      query: {
        ...router.query,
        [FilterLabels.DETECTION_TYPE]: detectionType,
        [FilterLabels.ALERT_TYPE]: alertType
      } satisfies QueryParams
    });
  };

  return (
    <AnalyticsCard
      header='Summary'
      loading={indexLoading || devicesLoading || !filters}
      error={indexError?.response?.data.message || devicesError?.response?.data.message}
      data-intercom-target='analytics-summary-card'
    >
      <div className='flex justify-between text-primary-text/75'>
        <div className='flex flex-col justify-around space-y-2'>
          <div
            className='group cursor-pointer font-medium'
            onClick={() =>
              navigateToDetections(AlertType.CRITICAL, Object.values(DetectionType).join(','))
            }
            id='analytics-card-critical-detections'
            data-pw='button-analytics-card-critical-detections'
          >
            <p
              className='text-left text-2xl group-hover:underline'
              data-pw='label-summary-critical-detections'
            >
              {(
                totalCritPeopleDetections +
                totalCritVehicleDetections +
                totalCritConeDetections
              ).toLocaleString()}
            </p>
            <p className='text-sm group-hover:underline'>Critical detections</p>
          </div>
          <div className='text-sm' data-pw='label-summary-critical-detections-split'>
            <div
              className={`flex items-center space-x-1 ${totalCritPeopleDetections > 0 ? 'cursor-pointer hover:underline' : ''}`}
              onClick={() =>
                totalCritPeopleDetections > 0 &&
                navigateToDetections(AlertType.CRITICAL, DetectionType.PERSON)
              }
            >
              <div className='h-2 w-2 rounded-full bg-enterprise' />
              <p>People: {totalCritPeopleDetections.toLocaleString()}</p>
            </div>
            <div
              className={`flex items-center space-x-1 ${totalCritVehicleDetections > 0 ? 'cursor-pointer hover:underline' : ''}`}
              onClick={() =>
                totalCritVehicleDetections > 0 &&
                navigateToDetections(AlertType.CRITICAL, DetectionType.VEHICLE)
              }
            >
              <div className='h-2 w-2 rounded-full bg-blue-500' />
              <p>Vehicles: {totalCritVehicleDetections.toLocaleString()}</p>
            </div>
            <div
              className={`flex items-center space-x-1 ${totalCritConeDetections > 0 ? 'cursor-pointer hover:underline' : ''}`}
              onClick={() =>
                totalCritConeDetections > 0 &&
                navigateToDetections(AlertType.CRITICAL, DetectionType.SAFETY_CONE)
              }
            >
              <div className='h-2 w-2 rounded-full bg-green-500' />
              <p>Cones: {totalCritConeDetections.toLocaleString()}</p>
            </div>
          </div>
        </div>

        <div className='flex flex-col justify-around space-y-2'>
          <div
            className='group cursor-pointer font-medium'
            onClick={() =>
              navigateToDetections(AlertType.GENERAL, Object.values(DetectionType).join(','))
            }
            id='analytics-card-general-detections'
            data-pw='button-analytics-card-general-detections'
          >
            <p
              className='text-left text-2xl group-hover:underline'
              data-pw='label-summary-general-detections'
            >
              {(
                totalPeopleDetections +
                totalVehicleDetections +
                totalConeDetections
              ).toLocaleString()}
            </p>
            <p className='text-sm group-hover:underline'>General detections</p>
          </div>
          <div className='text-sm' data-pw='label-summary-general-detections-split'>
            <div
              className={`flex items-center space-x-1 ${totalPeopleDetections > 0 ? 'cursor-pointer hover:underline' : ''}`}
              onClick={() =>
                totalPeopleDetections > 0 &&
                navigateToDetections(AlertType.GENERAL, DetectionType.PERSON)
              }
            >
              <div className='h-2 w-2 rounded-full bg-enterprise' />
              <p>People: {totalPeopleDetections.toLocaleString()}</p>
            </div>
            <div
              className={`flex items-center space-x-1 ${totalVehicleDetections > 0 ? 'cursor-pointer hover:underline' : ''}`}
              onClick={() =>
                totalVehicleDetections > 0 &&
                navigateToDetections(AlertType.GENERAL, DetectionType.VEHICLE)
              }
            >
              <div className='h-2 w-2 rounded-full bg-blue-500' />
              <p>Vehicles: {totalVehicleDetections.toLocaleString()}</p>
            </div>
            <div
              className={`flex items-center space-x-1 ${totalConeDetections > 0 ? 'cursor-pointer hover:underline' : ''}`}
              onClick={() =>
                totalConeDetections > 0 &&
                navigateToDetections(AlertType.GENERAL, DetectionType.SAFETY_CONE)
              }
            >
              <div className='h-2 w-2 rounded-full bg-green-500' />
              <p>Cones: {totalConeDetections.toLocaleString()}</p>
            </div>
          </div>
        </div>
        <hr className='h-[7.5rem] w-0.5 self-center bg-black/10' />
        <div className='flex flex-col justify-evenly'>
          <div className='p-2 font-medium'>
            <p className='text-2xl' data-pw='label-summary-blindsight-index'>
              {currIndexAverage}
            </p>
            <p className='text-sm'>Blindsight Index</p>
          </div>
          {comparison && aggregatedIndexChange !== 0 ? (
            <Tooltip title={ProcessedChangeComponent}>
              <span>{changeTooltipText}</span>
            </Tooltip>
          ) : null}
        </div>
        <hr className='h-[7.5rem] w-0.5 self-center bg-black/10' />
        <div className='self-center'>
          <p className='text-2xl font-medium' data-pw='label-summary-blindsight-device'>
            {devicesData?.results?.length ?? 'N/A'}
          </p>
          <p className='text-sm'>Blindsight device{devicesData?.results?.length !== 1 && 's'}</p>
        </div>
      </div>
    </AnalyticsCard>
  );
};
