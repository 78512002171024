import { useContext, useMemo, useState} from 'react';

import { MultiSelect } from '@fleet/components';

import { generateCSVData, generateGraphData, getBarColor, getCustomTooltip, legends } from './AnalyticsDetections.utils';
import { AnalyticsCard, AnalyticsGraph } from '../../molecules';
import { AlertType, DetectionType } from '../../../const';
import { AnalyticsContext } from '../../../providers';
import type { AggregatedAnalyticsDetections } from '../../../types';

interface AnalyticsDetectionsProps extends AggregatedAnalyticsDetections {
  periodEndAt: string;
}

const detectionTypeOptions = [
  { label: 'Any', value: 'ALL' },
  { label: 'General', value: AlertType.GENERAL },
  { label: 'Critical', value: AlertType.CRITICAL },
];

const objectTypeOptions = [
  { label: 'Any', value: 'ALL' },
  { label: 'People', value: DetectionType.PERSON },
  { label: 'Vehicles', value: DetectionType.VEHICLE },
  { label: 'Cones', value: DetectionType.SAFETY_CONE },
];
export const AnalyticsDetections: React.FC<AnalyticsDetectionsProps> = ({
  periodEndAt,
  ...aggregatedDetections
}) => {
  const { dateAggregation, count } = useContext(AnalyticsContext);

  const [selectedDetectionTypes, setSelectedDetectionTypes] = useState<DetectionType>('' as DetectionType);
  const [selectedObjectTypes, setSelectedObjectTypes] = useState<AlertType>('' as AlertType);

  const handleDetectionTypeChange = (value: string) => {
    setSelectedDetectionTypes((value === 'ALL' || value.includes('ALL') ? '' : value) as DetectionType);
  };

  const handleObjectTypeChange = (value: string) => {
    setSelectedObjectTypes((value === 'ALL' || value.includes('ALL') ? '' : value) as AlertType);
  };

  const filterDetections = (
    detections: AggregatedAnalyticsDetections,
    selectedDetectionTypes: string,
    selectedObjectTypes: string
  ) => {
    const detectionTypes = selectedDetectionTypes ? selectedDetectionTypes.split(',') : [];
    const objectTypes = selectedObjectTypes ? selectedObjectTypes.split(',') : [];

    const shouldInclude = (detectionType: string, objectType: string) =>
      (detectionTypes.length === 0 || detectionTypes.includes(detectionType)) &&
      (objectTypes.length === 0 || objectTypes.includes(objectType));

    const mapOrZero = (arr: number[], detectionType: string, objectType: string) =>
      shouldInclude(detectionType, objectType) ? arr : arr.map(() => 0);

    return {
      critPeopleDetections: mapOrZero(detections.critPeopleDetections, AlertType.CRITICAL, DetectionType.PERSON),
      peopleDetections: mapOrZero(detections.peopleDetections, AlertType.GENERAL, DetectionType.PERSON),
      critVehicleDetections: mapOrZero(detections.critVehicleDetections, AlertType.CRITICAL, DetectionType.VEHICLE),
      vehicleDetections: mapOrZero(detections.vehicleDetections, AlertType.GENERAL, DetectionType.VEHICLE),
      critConeDetections: mapOrZero(detections.critConeDetections, AlertType.CRITICAL, DetectionType.SAFETY_CONE),
      coneDetections: mapOrZero(detections.coneDetections, AlertType.GENERAL, DetectionType.SAFETY_CONE),
    };
  };

  const filteredDetections = useMemo(
    () => filterDetections(aggregatedDetections, selectedDetectionTypes, selectedObjectTypes),
    [aggregatedDetections, selectedDetectionTypes, selectedObjectTypes]
  );

  const graphData = useMemo(
    () => generateGraphData({
      aggregatedDetections: filteredDetections,
      periodEndAt,
      dateAggregation,
      count
    }),
    [filteredDetections, count, dateAggregation, periodEndAt]
  );

  const csvData = useMemo(
    () => generateCSVData({
      aggregatedDetections: filteredDetections,
      periodEndAt,
      dateAggregation,
      count,
    }),
    [filteredDetections, periodEndAt, dateAggregation, count]
  );

  const graphProps = useMemo(
    () => ({
      data: graphData,
      keys: ['People', 'Vehicles', 'Cones'],
      colors: getBarColor
    }),
    [graphData]
  );

  return (
    <AnalyticsCard header='Detections'>
      <div className="flex flex-row justify-end mb-4">
        <div>
          <MultiSelect
            label="Detection Type"
            options={detectionTypeOptions}
            value={selectedDetectionTypes}
            onChange={handleDetectionTypeChange}
            isSmall
          />
        </div>
        <div className="ml-4">
          <MultiSelect
            label="Object Type"
            options={objectTypeOptions}
            value={selectedObjectTypes}
            onChange={handleObjectTypeChange}
            isSmall
          />
        </div>
      </div>
      <AnalyticsGraph
        csvData={csvData}
        csvLabel='detections'
        count={count}
        yLabel='Detections'
        legends={legends}
        graphProps={graphProps}
        customTooltip={getCustomTooltip}
        periodEndAt={periodEndAt}
        dateAggregation={dateAggregation}
      />
    </AnalyticsCard>
  );
};