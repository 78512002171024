import { ArrowDownRightIcon } from '@heroicons/react/24/outline';
import type { BarDatum, BarTooltipProps } from '@nivo/bar';
import router from 'next/router';
import { useCallback, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { getCustomTooltip } from './SummaryGraph.utils';
import {
  DateOptions,
  dayJS,
  FilterLabels,
  ReviewStatus,
  Routes,
  SHORT_DEFAULT_FILTERS
} from '../../../const';
import { useBrandingTheme } from '../../../hooks';
import type { QueryParams } from '../../../types';
import { ResponsiveBarCanvas } from '../../../utils';

const getDateFromDay = (dayAbbreviation: string) => {
  if (!dayAbbreviation) throw new Error('Invalid day abbreviation provided.');

  const dayOfWeekMap: { [key: string]: number } = {
    Mo: 1,
    Tu: 2,
    We: 3,
    Th: 4,
    Fr: 5,
    Sa: 6,
    Su: 0
  };

  const endingDayIndex = dayOfWeekMap[dayAbbreviation];
  if (endingDayIndex === undefined) {
    throw new Error('Invalid day abbreviation provided.');
  }
  const today = new Date();
  const todayIndex = today.getDay();
  let daysDifference = (7 + todayIndex - endingDayIndex) % 7;
  if (daysDifference === 0) {
    daysDifference = 7;
  }
  const targetDate = new Date(today);
  targetDate.setDate(today.getDate() - daysDifference);
  const dateString = targetDate.toISOString();

  return dateString;
};

interface SummaryGraphProps {
  aggregatedData: (number | null)[];
  labelValue: number;
  labelValueColorClass: string;
  label: string;
  percentChange: number;
  changeTooltipText: React.ReactNode | string;
  className?: string;
  dataPw?: string;
  projectId?: string;
  isLink?: boolean;
  deviceId?: string;
  query?: QueryParams;
}

// TODO cleanup
export const SummaryGraph: React.FC<SummaryGraphProps> = ({
  aggregatedData,
  labelValue,
  labelValueColorClass,
  label,
  percentChange,
  changeTooltipText,
  className,
  dataPw,
  isLink = false,
  projectId,
  deviceId,
  query
}) => {
  const brandingTheme = useBrandingTheme();

  const filteredData = aggregatedData.flatMap(value => (value === null ? [] : [value]));

  const data = aggregatedData.map((value, index) => ({
    day: dayJS()
      .subtract(7 - index, 'days')
      .format('dd'),
    value: value === null ? 0 : value
  }));

  //const get max value from aggregatedData
  const maxValue = Math.max(...filteredData);

  const ProcessedChangeAndLabel = useMemo(() => {
    const processedChange =
      percentChange === Number.POSITIVE_INFINITY || percentChange >= 500 || percentChange <= -500
        ? ''
        : `${Math.round(percentChange)}% `;

    if (Number.isNaN(percentChange)) return null;

    const changeColor = percentChange < 0 ? 'text-success' : 'text-error';
    const iconRotation = percentChange < 0 ? null : '-rotate-90';

    return (
      <div className='group'>
        <div
          className={twMerge(
            'flex h-8 items-center space-x-2',
            query && query.alertType ? 'cursor-pointer' : ''
          )}
          onClick={() => query && query.alertType && navigateToDetections()}
        >
          <p
            className={twMerge('text-3xl font-medium leading-6', labelValueColorClass)}
            data-pw={dataPw}
          >
            {labelValue}
          </p>
          <p
            className={`text-tertiary-text ${isLink && (projectId || deviceId) ? 'group-hover:underline' : ''}`}
          >
            {label}
          </p>
        </div>
        <div
          className={twMerge(
            'flex items-center space-x-2',
            changeColor,
            query && query.alertType ? 'cursor-pointer group-hover:underline' : ''
          )}
          onClick={() => query && query.alertType && navigateToDetections()}
        >
          <ArrowDownRightIcon className={twMerge('h-4 w-4', iconRotation)} />
          <p data-pw='label-project-card-percentage-increase'>
            {processedChange}
            {DateOptions.LAST_7_DAYS.toLowerCase()}
          </p>
        </div>
      </div>
    );
  }, [percentChange, labelValue, label, labelValueColorClass, dataPw, isLink, projectId, deviceId]);

  const graphObjectProps = useMemo(
    () => ({
      axisBottom: { tickSize: 0, tickPadding: 2 },
      margin: { bottom: 14 },
      colors: [`rgb(${brandingTheme?.color})`]
    }),
    [brandingTheme?.color]
  );

  const customTooltip = useCallback(
    (props: BarTooltipProps<BarDatum>) => getCustomTooltip(props, label),
    [label]
  );

  const navigateToDetections = (date?: string) => {
    router.push({
      pathname: Routes.DETECTIONS,
      query: {
        ...SHORT_DEFAULT_FILTERS,
        [FilterLabels.REVIEW_STATUS]: [ReviewStatus.CONFIRMED, ReviewStatus.NOT_REVIEWED].join(','),
        ...(deviceId && { deviceIds: deviceId }),
        ...(projectId && { projectIds: projectId }),
        ...(date && { date }),
        ...query
      } satisfies QueryParams
    });
  };

  return (
    <div className={twMerge('flex h-full w-60 flex-col p-5 text-xs', className)}>
      {maxValue === 0 ? (
        <>
          <div
            className={twMerge(
              'flex h-[5.5rem] items-center space-x-2',
              query && query.alertType ? 'cursor-pointer' : ''
            )}
            onClick={() => query && query.alertType && navigateToDetections()}
          >
            <p className={twMerge('text-3xl font-medium leading-6', labelValueColorClass)}>
              {labelValue}
            </p>
            <p
              className={`text-tertiary-text ${isLink && (projectId || deviceId) ? 'hover:underline' : ''}`}
            >
              {label}
            </p>
          </div>
          <div className='mt-2 h-14 w-full' />
        </>
      ) : (
        <>
          {ProcessedChangeAndLabel}
          <div className='relative mt-2 flex h-14 w-full text-black' data-pw={`${dataPw}-chart`}>
            <p className='absolute right-full leading-3 text-tertiary-text'>{maxValue}</p>
            <ResponsiveBarCanvas
              data={data}
              indexBy='day'
              enableGridY={false}
              axisLeft={null}
              padding={0.1}
              enableLabel={false}
              tooltip={customTooltip}
              onClick={data => {
                if (query && query.alertType) {
                  const day = data?.data?.day?.toString();
                  navigateToDetections(getDateFromDay(day));
                }
              }}
              {...graphObjectProps}
            />
          </div>
        </>
      )}
    </div>
  );
};
