import { ArrowDownRightIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

import { DateAggregation, dayJS } from '../../../const';
import type { BlindsightIndexResponse } from '../../../hooks';
import {
  getCurrentAggregatedIndexAverage,
  getPreviousAggregatedIndexAverage
} from '../../../utils';

export const getAggregatedIndex = ({
  indexData,
  periodEndAt,
  count,
  dateAggregation
}: {
  indexData: BlindsightIndexResponse | undefined;
  periodEndAt: string;
  count: number;
  dateAggregation: DateAggregation;
}) => {
  const aggregatedIndex = new Array<number | null>(count * 2).fill(null);

  const diffUnit = dateAggregation === DateAggregation.WEEKLY ? 'week' : 'day';

  indexData?.results.map(({ periodStartAt, blindsightIndex }) => {
    const offset = Math.floor(
      count * 2 - dayJS({ date: periodEndAt }).diff(dayJS({ date: periodStartAt }), diffUnit, true)
    );
    aggregatedIndex[offset] = blindsightIndex;
  });

  return aggregatedIndex;
};

export const ProcessedChangeLine: React.FC<{
  aggregatedIndexChange: number;
  comparisonCurrText: string | undefined;
}> = ({ aggregatedIndexChange, comparisonCurrText }) => {
  const processedChange =
    aggregatedIndexChange === Number.POSITIVE_INFINITY ||
    aggregatedIndexChange >= 500 ||
    aggregatedIndexChange <= -500
      ? ''
      : `${Math.round(aggregatedIndexChange)}% `;

  if (Number.isNaN(aggregatedIndexChange)) return null;

  const changeColor = aggregatedIndexChange < 0 ? 'text-success' : 'text-error';
  const iconRotation = aggregatedIndexChange < 0 ? null : '-rotate-90';

  return (
    <div className={twMerge('flex items-center space-x-2 p-2', changeColor)}>
      <ArrowDownRightIcon className={twMerge('h-4 w-4', iconRotation)} />
      <p>
        {processedChange}
        {comparisonCurrText}
      </p>
    </div>
  );
};

export const getIndexChangeTooltipText = ({
  aggregatedIndex,
  percentChange,
  comparisonCurrText,
  comparisonPrevText
}: {
  aggregatedIndex: (number | null)[];
  percentChange: number;
  comparisonCurrText: string | undefined;
  comparisonPrevText: string | undefined;
}) => {
  const prevIndex = getPreviousAggregatedIndexAverage(aggregatedIndex);
  const currIndex = getCurrentAggregatedIndexAverage(aggregatedIndex);

  const percentDirection = `${percentChange > 0 ? 'Up' : 'Down'} ${
    percentChange === Number.POSITIVE_INFINITY ? '' : `${Math.round(Math.abs(percentChange))}%`
  }`;

  return (
    <>
      <p>
        Average {currIndex} {comparisonCurrText}
      </p>
      <p>
        {percentDirection} from {prevIndex} {comparisonPrevText}
      </p>
    </>
  );
};
