import { PhotoIcon } from '@heroicons/react/24/outline';
import { PlayIcon } from '@heroicons/react/24/solid';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useCallback, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import { snakeToSentenceCase } from '@fleet/utils';

import { AlertType, dayJS, TIME_DATE_FORMAT } from '../../../const';
import type { Alert } from '../../../hooks';
import { useAuthContext, useGetAlert, useGetDevices, useGetProjects } from '../../../hooks';
import type { QueryParams } from '../../../types';
import { trackEvent } from '../../../utils';

interface DetectionCardProps {
  alert: Alert;
  dataPw?: string;
}

export const DetectionCard: React.FC<DetectionCardProps> = ({
  alert: {
    detectionType,
    projectId,
    occurredAt,
    deviceId,
    videoThumbnailUrl,
    previewVideoUrl,
    alertType,
    alertId
  },
  dataPw
}) => {
  const { currentOrganizationProjectIds } = useAuthContext();
  const [preloaded, setPreloaded] = useState<boolean>(false);
  const router = useRouter();
  const query = router.query satisfies QueryParams;
  const [isHovering, setIsHovering] = useState(false);

  const { data: projectsData } = useGetProjects({
    projectIds: currentOrganizationProjectIds
  });
  const { data: devicesData } = useGetDevices({ deviceIds: deviceId });

  const hoverTimeout = useRef<NodeJS.Timeout>();

  const handleOnHover = useCallback(() => {
    hoverTimeout.current = setTimeout(() => setIsHovering(true), 300);
  }, []);

  const handleOnLeave = useCallback(() => {
    clearTimeout(hoverTimeout.current);
    setIsHovering(false);
  }, []);

  const handleClick = useCallback(() => {
    trackEvent('detection-card-clicked');
    clearTimeout(hoverTimeout.current);
    setIsHovering(false);
    router.replace({ query: { ...query, alertId } satisfies QueryParams });
  }, [alertId, hoverTimeout, query, router]);

  useGetAlert({ alertId: alertId as string }, !isHovering);

  const handlePreload = useCallback(() => setPreloaded(!preloaded), [preloaded]);

  const projectName =
    projectsData?.results.find(project => project.id === projectId)?.name ?? 'Unknown Project';

  const title = `${snakeToSentenceCase(detectionType)} detected${
    devicesData?.results?.[0]?.name ? ' by ' + devicesData?.results?.[0]?.name : ''
  } at ${projectName}`;

  const date = occurredAt ? dayJS({ date: occurredAt }).format(TIME_DATE_FORMAT) : 'Unknown date';

  return (
    <div
      onClick={handleClick}
      onMouseEnter={handlePreload}
      className='flex min-w-[12rem] cursor-pointer flex-col overflow-hidden rounded-lg border border-gray-200 bg-white transition-[transform,box-shadow] duration-300 hover:-translate-y-1 hover:scale-105 hover:shadow-xl'
      data-pw={dataPw}
    >
      <div className='relative block' onMouseEnter={handleOnHover} onMouseLeave={handleOnLeave}>
        {videoThumbnailUrl ? (
          <Image
            className='rounded-t-lg object-cover brightness-90 transition-[filter] duration-300 hover:brightness-75'
            src={videoThumbnailUrl}
            alt='detection preview image'
            width={1120}
            height={840}
            loading='lazy'
          />
        ) : (
          <div className='flex aspect-[4/3] w-full items-center justify-center bg-gray-300'>
            <PhotoIcon className='h-20 w-20 text-gray-500' />
          </div>
        )}

        {previewVideoUrl ? (
          <PlayIcon className='center-element z-10 h-10 w-10 fill-white opacity-75' />
        ) : null}

        {isHovering && previewVideoUrl ? (
          <div className='absolute inset-0 overflow-hidden rounded-t-lg'>
            <ReactPlayer
              url={previewVideoUrl}
              playing
              playsinline
              muted
              loop
              width='100%'
              height='100%'
            />
          </div>
        ) : null}
      </div>
      <div className='flex flex-grow flex-col justify-between space-y-1 p-3'>
        <p className='block text-base font-medium'>
          {alertType === AlertType.CRITICAL && (
            <span className='mr-1 rounded-xl border border-brand px-1 font-normal text-brand'>
              Critical
            </span>
          )}
          {title}
        </p>
        <p className='text-sm text-gray-400'>{date}</p>
      </div>
    </div>
  );
};
