import { TruckIcon, UserIcon } from '@heroicons/react/24/outline';
import { useCallback, useContext } from 'react';

import { ConeIconSVG } from '@fleet/components';

import { DetectionType, ENABLED_FILTERS_PER_ROUTE, FilterLabels } from '../../../../const';
import { useGetCurrentRoute } from '../../../../hooks';
import { SelectedFiltersContext } from '../../../../providers';
import { trackEvent } from '../../../../utils';
import { ToggleGroup, type ToggleGroupOption } from '../../../atoms';

const detectionTypeOptions: ToggleGroupOption<DetectionType>[] = [
  {
    label: 'Person',
    value: DetectionType.PERSON,
    icon: <UserIcon className='h-5 w-5' />
  },
  {
    label: 'Vehicle',
    value: DetectionType.VEHICLE,
    icon: <TruckIcon className='h-5 w-5' />
  },
  {
    label: 'Safety Cone',
    value: DetectionType.SAFETY_CONE,
    icon: <ConeIconSVG className='h-5 w-5' />
  }
];

export const DetectionTypeFilter: React.FC = () => {
  const { selectedFilters, setSelectedFilters } = useContext(SelectedFiltersContext);

  const currentRoute = useGetCurrentRoute();

  const handleOnChange = useCallback(
    (detectionType: string[]) => {
      trackEvent('detection-type-filter-changed', { detectionType });
      setSelectedFilters({
        ...selectedFilters,
        detectionType: detectionType.join(',')
      });
    },
    [selectedFilters, setSelectedFilters]
  );

  return (
    <ToggleGroup
      options={detectionTypeOptions}
      onChange={handleOnChange}
      value={selectedFilters.detectionType}
      // defaultValue={DEFAULT_FILTERS.detectionType}
      disabled={!ENABLED_FILTERS_PER_ROUTE[currentRoute].includes(FilterLabels.DETECTION_TYPE)}
    />
  );
};
