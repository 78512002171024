import { useAuth0 } from '@auth0/auth0-react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Dropdown, Tabs, UserMenuIconSVG } from '@fleet/components';

import { SwitchOrganizationsModal } from '../SwitchOrganizationsModal/SwitchOrganizationsModal';
import { Routes } from '../../../const';
import { useAuthContext, useBrandingTheme, useGetCurrentRoute } from '../../../hooks';
import { AuthType } from '../../../hooks/api/useGetAuthContext';

type Tab = {
  name: string;
  route: string;
  isActive?: boolean;
  id: string;
};

export const Header: React.FC = memo(() => {
  const { allOrganizations, globalAdmin, currentOrganization, authType } = useAuthContext();
  const [showSwitchOrgModal, setShowSwitchOrgModal] = useState(false);

  const router = useRouter();
  const currentRoute = useGetCurrentRoute();

  const { isLoading } = useAuth0();
  const theme = useBrandingTheme();

  const { logout } = useAuth0();
  const logoutUser = useCallback(
    () => logout({ logoutParams: { returnTo: window.location.origin + '/login' } }),
    [logout]
  );

  const navigateToHome = useCallback(() => {
    const query = { ...router.query };
    router.push({
      pathname: '/',
      query: query
    });
  }, [router]);

  const orgMenuDropdownOptions = useMemo(() => {
    const options = [];

    if (allOrganizations && allOrganizations.length > 1)
      options.push({ label: 'Switch Organisation', onClick: () => setShowSwitchOrgModal(true) });

    options.push(
      {
        label: 'User Details',
        onClick: () => router.push(Routes.ACCOUNT)
      },
      { label: 'Logout', onClick: logoutUser }
    );

    return options;
  }, [allOrganizations, logoutUser, router]);

  const brandLogoStyle: React.CSSProperties = useMemo(
    () => ({
      width: theme?.logo.width,
      height: theme?.logo.height
    }),
    [theme]
  );

  const headerTabs = useMemo(() => {
    let tabs: Tab[] = [
      { name: 'Detections', route: Routes.DETECTIONS, id: 'header-tab-detections' },
      { name: 'Action Center', route: Routes.ACTION_CENTER, id: 'header-tab-actioncenter' },
      { name: 'Analytics', route: Routes.ANALYTICS, id: 'header-tab-analytics' }
    ];

    if (authType === AuthType.USER) {
      tabs = [
        { name: 'Summary', route: Routes.SUMMARY, id: 'header-tab-summary' },
        ...tabs,
        {
          name: 'Manage',
          route: Routes.ORGANIZATION,
          isActive: [Routes.PROJECT, Routes.DEVICE].includes(currentRoute),
          id: 'header-tab-manage'
        }
      ];

      if (globalAdmin) {
        tabs.push({ name: 'Admin', route: Routes.ADMIN, id: 'header-tab-admin' });
      }
    }

    return tabs;
  }, [globalAdmin, currentRoute, authType]);

  if (!theme) return null;

  return (
    <div className='flex h-16 items-center justify-between bg-primary-element px-6'>
      <div className='flex items-center space-x-16 text-nowrap'>
        <Image
          src={theme.logo.src}
          onClick={navigateToHome}
          className='cursor-pointer'
          alt='logo'
          width={theme.logo.width}
          height={theme.logo.height}
          style={brandLogoStyle}
        />
        <Tabs tabs={headerTabs} currentRoute={currentRoute} />
      </div>

      {authType === AuthType.USER && (
        <div className='flex items-center space-x-2'>
          <Dropdown
            className='right-0 mt-1 min-w-fit divide-y divide-neutral-100 text-nowrap rounded-2xl border text-base *:py-2'
            options={orgMenuDropdownOptions}
          >
            {({ open }) => (
              <div
                className={twMerge(
                  'flex items-center rounded-2xl border border-gray-200 bg-white px-3 py-1 shadow-filter-button transition-transform duration-300',
                  open && 'bg-gray-50'
                )}
              >
                <p className='pr-2'>{currentOrganization?.name}</p>
                <UserMenuIconSVG className='h-4 w-4' />
              </div>
            )}
          </Dropdown>
          <SwitchOrganizationsModal
            isOpen={showSwitchOrgModal}
            onClose={() => setShowSwitchOrgModal(false)}
          />
        </div>
      )}
    </div>
  );
});
