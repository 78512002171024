import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition
} from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/16/solid';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';

interface SelectOption<ValueType = string> {
  label: string;
  value?: ValueType;
  customLabel?: string;
}

interface SelectProps<ValueType> {
  label: string;
  options: SelectOption<ValueType>[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  dataPw?: string;
  isSmall?: boolean;
}

export const MultiSelect = <ValueType extends string>({
  label,
  options,
  value,
  onChange,
  disabled,
  dataPw,
  isSmall = false
}: SelectProps<ValueType>) => {
  const selectedValues = value.split(',').filter(Boolean);

  const handleRemoveChip = (chipValue: string) => {
    const newValues = selectedValues.filter(v => v !== chipValue);
    onChange(newValues.join(','));
  };

  const sharedStyles = {
    label: isSmall ? 'text-xs mb-1 block text-neutral-400' : 'text-sm mb-1 block text-neutral-400',
    button: isSmall
      ? 'flex min-h-8 w-full items-center justify-between border border-neutral-300 px-2 py-1 text-xs rounded-lg'
      : 'flex min-h-12 w-full items-center justify-between border border-neutral-300 px-4 py-2 rounded-xl',
    chip: isSmall
      ? 'inline-flex items-center rounded-full bg-brand/20 px-1 py-0.5 text-xs text-brand'
      : 'inline-flex items-center rounded-full bg-brand/20 px-2 py-1 text-sm text-brand',
    icon: isSmall ? 'h-3 w-3 text-brand' : 'h-4 w-4 text-brand',
    chevron: isSmall ? 'h-3 w-3 ml-2' : 'h-5 w-5',
    option: isSmall
      ? 'truncate px-2 py-1 ui-active:bg-brand/10 flex justify-between items-center w-full text-xs'
      : 'truncate px-3 py-2 ui-active:bg-brand/10 flex justify-between items-center w-full',
  };

  return (
    <div className={twMerge('text-gray-700', disabled && 'pointer-events-none opacity-40')} data-pw={dataPw}>
      <Listbox value={selectedValues} onChange={(vals: string[]) => onChange(vals.join(','))} multiple>
        <Label className={sharedStyles.label}>{label}</Label>
        <div className="relative">
          <ListboxButton className={sharedStyles.button}>
            <div className='flex flex-wrap gap-1 max-w-full overflow-hidden'>
              {selectedValues.length > 0 && !selectedValues.includes('ALL') ? (
                selectedValues.map(val => {
                  const option = options.find(o => o.value === val);
                  return (
                    <span key={val} className={sharedStyles.chip} title={option?.label || val}>
                      {option?.label || val}
                      <button
                        type='button'
                        onClick={e => {
                          e.stopPropagation();
                          handleRemoveChip(val);
                        }}
                        className={`ml-1 ${sharedStyles.icon} text-brand hover:text-brand-dark`}
                        aria-label={`Remove ${option?.label || val}`}
                      >
                        <XMarkIcon className={sharedStyles.icon} />
                      </button>
                    </span>
                  );
                })
              ) : (
                <span>All</span>
              )}
            </div>
            <ChevronDownIcon className={`${sharedStyles.chevron} transition-transform duration-300 ui-open:rotate-180 ui-not-open:rotate-0`} />
          </ListboxButton>

          <Transition
            as={Fragment}
            enter="transition-[max-height,opacity] ease-in duration-300"
            enterFrom="opacity-0 max-h-0 border-0"
            enterTo="opacity-100 max-h-60 border border-t-0"
            leave="transition-[max-height,opacity] ease-out duration-300"
            leaveFrom="opacity-100 max-h-60 border border-t-0"
            leaveTo="opacity-0 max-h-0 border-0"
          >
            <ListboxOptions className="absolute z-10 w-full overflow-auto rounded-b-xl border bg-white focus:outline-none">
              {options.map(({ label, customLabel, value: optionValue }, idx) => (
                <Fragment key={optionValue ?? label}>
                  <ListboxOption value={optionValue ?? label} className="select-none">
                    <div className='flex items-center justify-between'>
                      <div className={sharedStyles.option}>
                        <div className="flex items-center justify-between w-full mr-3" dangerouslySetInnerHTML={{ __html: customLabel ?? label }} />
                        {selectedValues.includes(optionValue ?? label) && !selectedValues.includes('ALL') && <CheckIcon className={sharedStyles.icon} />}
                      </div>
                    </div>
                  </ListboxOption>
                  {idx < options.length - 1 && <hr className={`${isSmall ? 'mx-2' : 'mx-4'} my-1`} />}
                </Fragment>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};