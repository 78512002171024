import { ChevronUpIcon } from '@heroicons/react/24/solid';
import type { ColumnDef, Row, SortingState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

type DataWithOptionalClassName<T> = T & { className?: string };

type DataTableProps<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<DataWithOptionalClassName<T>, any>[];
  data: DataWithOptionalClassName<T>[];
  enableSorting?: boolean;
  defaultSorting?: SortingState;
  onRowClick?: (row: Row<DataWithOptionalClassName<T>>) => void;
};

export const DataTable = <T extends {}>({
  columns,
  data,
  enableSorting = false,
  defaultSorting = [],
  onRowClick
}: DataTableProps<T>) => {
  const [sorting, setSorting] = useState<SortingState>(defaultSorting);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting, // React to sorting changes
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), // Enable sorting row model
    enableSorting // Controlled by prop
  });

  return (
    <div className='border-1 border-separate rounded-xl border'>
      <table className='w-full table-auto text-left'>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  className={twMerge(
                    'whitespace-nowrap border-b px-5 py-4 font-extralight text-gray-500',
                    enableSorting && header.column.getCanSort() ? 'cursor-pointer select-none' : ''
                  )}
                  onClick={
                    enableSorting && header.column.getCanSort()
                      ? header.column.getToggleSortingHandler()
                      : undefined
                  }
                >
                  <span>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </span>
                  {enableSorting && header.column.getCanSort() && (
                    <ChevronUpIcon
                      className={twMerge(
                        'mb-1 ml-2 inline-block h-4 w-4',
                        !header.column.getIsSorted() && 'opacity-0',
                        header.column.getIsSorted() === 'asc' && 'rotate-180'
                      )}
                    />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => {
            return (
              <tr
                key={row.id}
                onClick={() => {
                  onRowClick && onRowClick(row);
                }}
                className={twMerge(
                  'font-light [&>td]:px-5 [&>td]:py-4',
                  index % 2 && 'bg-gray-50',
                  onRowClick && 'cursor-pointer hover:bg-brand/15',
                  row.original?.className || ''
                )}
              >
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} className='break-words'>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
