import { Bars2Icon } from '@heroicons/react/24/outline';
import Link from 'next/link';

import { PresienLogo } from '@fleet/components';

import { useSageBrandingTheme } from '../../hooks';

export const SageHeader: React.FC = () => {
  const sageTheme = useSageBrandingTheme();

  return (
    <div className='flex items-center justify-between rounded-xl bg-system-900 p-4 shadow-card-lg'>
      <Link href='/'>{sageTheme?.logo ?? <PresienLogo />}</Link>
      <div className='flex items-center space-x-4'>
        <button className='text-system-400'>Actions</button>
        <button className='text-system-100'>Chats</button>
        <button>
          <Bars2Icon className='size-4 text-system-200' />
        </button>
      </div>
    </div>
  );
};
