import { TrashIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import { Button } from '@fleet/components';
import { Input } from '@fleet/components';

export interface Recipients {
  name: string;
  email: string;
}

type RecipientsFieldsProps = {
  label: string;
  recipients: Recipients[];
  setRecipients: React.Dispatch<React.SetStateAction<Recipients[]>>;
  minimumRecipients?: number;
  className?: string;
};

export const RecipientsFields: React.FC<RecipientsFieldsProps> = ({
  label,
  recipients,
  setRecipients,
  minimumRecipients = 0,
  className = ''
}) => {
  const addRecipient = useCallback(() => {
    setRecipients(prevRecipients => {
      return [...prevRecipients, { name: '', email: '' }];
    });
  }, [setRecipients]);

  const removeRecipient = useCallback(
    (index: number) => {
      setRecipients(prevRecipients => prevRecipients.filter((_, i) => i !== index));
    },
    [setRecipients]
  );

  const handleInputChange = useCallback(
    (index: number, field: keyof Recipients, value: string) => {
      setRecipients(prevRecipients =>
        prevRecipients.map((recipient, i) =>
          i === index ? { ...recipient, [field]: value } : recipient
        )
      );
    },
    [setRecipients]
  );

  return (
    <div className={className}>
      <div className='flex items-center justify-between'>
        <div className='mr-3 font-medium leading-4 text-gray-500'>{label}</div>
        <Button className='mr-2' variant='solid' onClick={addRecipient}>
          Add +
        </Button>
      </div>
      <div className='pr-5'>
        {recipients.map((recipient, index) => (
          <div
            key={index}
            className={twMerge(
              'mt-2 flex items-center',
              index + 1 > minimumRecipients ? '' : 'mr-[34px]'
            )}
          >
            <div className='mr-1 w-full'>
              <Input
                className='mt-1'
                value={recipient.name}
                placeholder='Name'
                onChange={e => handleInputChange(index, 'name', e.target.value)}
              />
            </div>
            <div className='ml-1 w-full'>
              <Input
                className='mt-1'
                value={recipient.email}
                placeholder='Email'
                onChange={e => handleInputChange(index, 'email', e.target.value)}
              />
            </div>
            {index + 1 > minimumRecipients && (
              <TrashIcon
                className='ml-3 mt-1 size-12 cursor-pointer text-brand'
                onClick={() => removeRecipient(index)}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
