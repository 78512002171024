import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

interface ModalProps {
  header?: React.ReactNode;
  children: React.ReactNode;
  onClose?: () => void;
  isOpen: boolean;
  title?: string;
  className?: string;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  onClose,
  isOpen,
  title,
  className,
  header
}) => {
  const handleOnClose = useCallback(() => onClose?.() ?? void 0, [onClose]);
  return (
    <Transition show={isOpen}>
      <Dialog as='div' className='relative z-40' onClose={handleOnClose}>
        <TransitionChild
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-out duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-50' />
        </TransitionChild>

        <div className='fixed inset-0 flex items-start justify-center p-6 text-center'>
          <TransitionChild
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-90'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-90'
          >
            <DialogPanel
              className={twMerge(
                'max-h-full w-full max-w-2xl transform overflow-y-auto rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all',
                className
              )}
            >
              <DialogTitle
                as='div'
                className='mb-4 flex justify-between text-2xl font-medium leading-6 text-gray-700'
              >
                {header ?? <h3>{title}</h3>}
                {(header || title) && onClose ? (
                  <XMarkIcon
                    className='ml-4 mt-1 h-6 w-6 cursor-pointer text-neutral-400/80'
                    strokeWidth={2}
                    onClick={onClose}
                    data-pw='button-detection-modal-close'
                  />
                ) : null}
              </DialogTitle>
              {children}
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};
