import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import type { SelectOption } from '@fleet/components';
import { Button, Select } from '@fleet/components';
import { Loading } from '@fleet/components';
import { snakeToSentenceCase } from '@fleet/utils';

import { Modal } from '../../atoms';
import { ProjectsRoles } from '../../organisms';
import { OrganizationUserRole } from '../../../const';
import type { OrganizationUser } from '../../../hooks';
import { useAuthContext } from '../../../hooks';
import type { ProjectRole } from '../../../hooks/api/useAddUser';
import { useUpdateOrganizationUser } from '../../../hooks/api/useUpdateOrganizationUser';
import { getUserFullName } from '../../../utils/User';

interface EditOrganizationUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  organizationUser?: OrganizationUser;
}

const roleOptions: SelectOption<OrganizationUserRole>[] = Object.values(OrganizationUserRole)
  .map(option => ({
    label: snakeToSentenceCase(option),
    value: option
  }))
  .filter(option => option !== null) as SelectOption<OrganizationUserRole>[];

export const EditOrganizationUserModal: React.FC<EditOrganizationUserModalProps> = ({
  isOpen,
  onClose,
  organizationUser
}) => {
  const [selectedRole, setSelectedRole] = useState<OrganizationUserRole | undefined>();
  const [projectsRoles, setProjectsRoles] = useState<ProjectRole[]>([]);
  const { currentOrganizationId } = useAuthContext();
  const { trigger: updateOrgUser, isMutating } = useUpdateOrganizationUser({
    organizationId: currentOrganizationId || '',
    userId: organizationUser?.userId || ''
  });

  const onSubmit = useCallback(async () => {
    if (!selectedRole) return;
    try {
      const x = await updateOrgUser({ role: selectedRole, projectsRoles });
      toast.success('Organisation user updated successfully.');
      onClose();
    } catch {
      toast.error('Failed to update organisation user.');
    }
  }, [onClose, selectedRole, updateOrgUser, projectsRoles]);

  useEffect(() => {
    if (organizationUser && organizationUser.user?.projectUsers) {
      setSelectedRole(organizationUser?.role);
      setProjectsRoles(
        organizationUser.user.projectUsers?.map(projectUser => ({
          projectId: projectUser.projectId,
          role: projectUser.role
        }))
      );
    }
  }, [organizationUser]);

  const isModalOpen = useMemo(() => isOpen && !!organizationUser, [isOpen, organizationUser]);

  return (
    <Modal title='Edit Organisation User' isOpen={isModalOpen} onClose={onClose}>
      <div className='space-y-4 [&_input]:w-full'>
        <div>
          <span className='font-bold'>Name: </span>
          <span>{getUserFullName(organizationUser?.user) || 'No name'}</span>
        </div>

        <div>
          <span className='font-bold'>Email: </span>
          <span>{organizationUser?.user.email}</span>
        </div>

        <div>
          <span className='font-bold'>Phone number: </span>
          <span>{organizationUser?.user.phoneNumber || 'None'}</span>
        </div>

        {/* Project permissions */}
        <Select
          label='Organisation Role'
          options={roleOptions}
          value={selectedRole || ''}
          onChange={setSelectedRole}
        />
        <ProjectsRoles projectsRoles={projectsRoles} setProjectsRoles={setProjectsRoles} />
        {isMutating ? (
          <Loading />
        ) : (
          <div className='flex justify-between gap-4 pt-3'>
            <Button variant='mono' className='h-14 w-1/2 justify-center text-lg' onClick={onClose}>
              Cancel
            </Button>
            <Button
              type='submit'
              variant='solid'
              className='h-14 w-1/2 justify-center text-lg'
              onClick={onSubmit}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
