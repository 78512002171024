import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

import { DEFAULT_TIMEZONE } from './Config';
import { LocalStorageKeys } from './Enums';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(updateLocale);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export const dayJS = ({
  date,
  timezone
}: {
  date?: string | number | Dayjs | Date | null | undefined;
  timezone?: string;
} = {}) => {
  dayjs.updateLocale('en', { weekStart: 1 });

  if (timezone) {
    dayjs.tz.setDefault(timezone);
    return dayjs.tz(date);
  }
  if (typeof window === 'undefined') {
    dayjs.tz.setDefault(DEFAULT_TIMEZONE);
    return dayjs.tz(date);
  }

  const storedTimezone = window.localStorage.getItem(LocalStorageKeys.TIMEZONE);
  dayjs.tz.setDefault(storedTimezone?.replaceAll('"', '') ?? DEFAULT_TIMEZONE);

  if (storedTimezone?.includes('UTC')) return dayjs.utc(date);

  return dayjs(date).tz();
};

export const DAY_IN_S = 86_400;
export const startOfToday = dayJS().startOf('day');
export const startOfYesterday = dayJS().startOf('day').subtract(1, 'day');
export const sevenDaysAgo = dayJS().subtract(6, 'days').startOf('day');

export const WEEK_IN_S = 604_800;
export const startOfThisWeek = dayJS().startOf('week');
export const startOfLastWeek = dayJS().startOf('week').subtract(1, 'week');
export const oneWeekAgo = dayJS().startOf('day').subtract(1, 'week');
export const twoWeeksAgo = dayJS().startOf('day').subtract(2, 'week');

export const startOfThisMonth = dayJS().startOf('month');
export const startOfLastMonth = dayJS().startOf('month').subtract(1, 'month');
export const oneMonthAgo = dayJS().startOf('day').subtract(30, 'days');
export const twoMonthsAgo = dayJS().startOf('day').subtract(60, 'days');

export const oneYearAgo = dayJS().startOf('day').subtract(1, 'year');

export const TIME_FORMAT = 'h:mm:ss a';
export const TIME_DATE_FORMAT = 'h:mm:ss a Do MMMM YYYY';
export const TIME_DATE_TZ_FORMAT = 'h:mm:ss a Do MMMM YYYY zzzz';
export const DATE_FORMAT = 'Do MMMM YYYY';
export const SHORT_DATE_FORMAT = 'Do MMM YYYY';
