import type { Map } from 'leaflet';
import { memo, useMemo, useRef } from 'react';

import { snakeToSentenceCase } from '@fleet/utils';

import { dayJS, TIME_DATE_FORMAT } from '../../../const';
import type { Alert } from '../../../hooks';
import {
  useGetAlertMapMarkers,
  useGetMapContainer,
  useGetMarker,
  useGetReactLeafletGoogleLayer
} from '../../../hooks';
import { getFormattedTZDate, getFromEnvConfig, getRiskColors } from '../../../utils';

interface DetectionInfoProps {
  alert: Alert | undefined;
}

export const DetectionInfo: React.FC<DetectionInfoProps> = memo(({ alert }) => {
  const MapContainer = useGetMapContainer();
  const Marker = useGetMarker();
  const ReactLeafletGoogleLayer = useGetReactLeafletGoogleLayer();
  const markerIcons = useGetAlertMapMarkers();

  const {
    detectionType,
    occurredAt,
    sensor,
    minDistanceRange,
    riskState,
    alertType,
    geolocationLatitude,
    geolocationLongitude,
    speedKmph,
    device
  } = alert ?? {};


  const mapRef = useRef<Map>(null);

  const shiftTiles = () => {
    setTimeout(() => {
      mapRef.current?.panBy([0.1, 0]);
      mapRef.current?.panBy([-0.1, 0]);
    }, 1000);
  };

  const dateDisplay = useMemo(() => {
    if (!occurredAt) return 'Not available';

    return getFormattedTZDate({
      date: dayJS({ date: occurredAt }),
      dateFormat: TIME_DATE_FORMAT
    });
  }, [occurredAt]);

  const detectedObject = snakeToSentenceCase(detectionType);
  const sensorDetected = sensor?.split('_')[1] ?? 'X';
  const deviceSerial = device?.serialNumber ?? 'Unknown Serial';

  const getDistance = () => {
    if (!minDistanceRange?.min_m && !minDistanceRange?.max_m) return 'Not available';

    const { min_m, max_m } = minDistanceRange;

    if (!max_m) return `${Number(min_m.toFixed(1))} m or more`;
    if (!min_m) return `${Number(max_m.toFixed(1))} m or less`;
    if (min_m === max_m) return `${Number(min_m.toFixed(1))} m`;

    return `~ ${Number(((min_m + max_m) / 2).toFixed(1))} m`;
  };

  const bounds = useMemo(() => {
    if (!geolocationLatitude || !geolocationLongitude) return;

    return [[geolocationLatitude, geolocationLongitude]] satisfies [[number, number]];
  }, [geolocationLatitude, geolocationLongitude]);

  const eventHandlers = useMemo(
    () => ({
      tileloadstart: shiftTiles
    }),
    []
  );

  const getSpeed = () => {
    if (speedKmph === undefined || speedKmph === null) return 'Not available';
    if (speedKmph === 0) return '0 km/h';

    return `${speedKmph} km/h`;
  };

  const markerPosition = useMemo(() => {
    if (!geolocationLatitude || !geolocationLongitude) return [0, 0] satisfies [number, number];

    return [geolocationLatitude, geolocationLongitude] satisfies [number, number];
  }, [geolocationLatitude, geolocationLongitude]);

  return (
    <div className='flex h-full w-full space-x-4 text-sm leading-4'>
      <div className='min-w-[7rem] space-y-2 font-medium text-neutral-600'>
        <p>Detection Time</p>
        <p>Serial</p>
        <p>Sensor</p>
        <p>Object</p>
        <br />
        <p>Risk Level</p>
        <p>Distance</p>
        <p>Speed</p>
        <br />
        <p>Location</p>
      </div>
      <div className='flex w-4/5 flex-col space-y-2 pr-4 text-neutral-600/80 [&>p]:truncate'>
        <p data-intercom-target='alert-info-date'>{dateDisplay}</p>
        <p data-intercom-target='alert-info-serial'>{deviceSerial}</p>
        <p data-intercom-target='alert-info-sensor'>{sensorDetected}</p>
        <p data-intercom-target='alert-info-object-type'>{detectedObject}</p>
        <br />
        <p data-intercom-target='alert-info-risk-level'>
          {snakeToSentenceCase(riskState?.split('RISK_')[1])}
        </p>
        <p data-intercom-target='alert-info-distance'>{getDistance()}</p>
        <p data-intercom-target='alert-info-speed'>{getSpeed()}</p>
        <br />
        {geolocationLatitude &&
        geolocationLongitude &&
        markerIcons &&
        MapContainer !== undefined &&
        ReactLeafletGoogleLayer !== undefined &&
        Marker !== undefined ? (
          <div className='aspect-video overflow-hidden rounded-2xl'>
            <MapContainer bounds={bounds} zoom={18} zoomControl={false} ref={mapRef}>
              <ReactLeafletGoogleLayer
                apiKey={getFromEnvConfig('GOOGLE_MAPS_API_KEY')}
                type='satellite'
                maxZoom={20}
                eventHandlers={eventHandlers}
              />
              <Marker
                position={markerPosition}
                icon={markerIcons[getRiskColors(riskState, alertType).color]}
              />
            </MapContainer>
          </div>
        ) : (
          <p>Not available</p>
        )}
      </div>
    </div>
  );
});
