import type { SWRMutationConfig } from './useApiHooks';
import { useMutationApi } from './useApiHooks';
import type { CreateDetectionsReport } from './useCreateDetectionReport';

interface UpdateDetectionsReportParams {
  id: number;
}

export const useUpdateDetectionsReport = (
  { id }: UpdateDetectionsReportParams,
  swrConfig?: SWRMutationConfig<never>
) =>
  useMutationApi<CreateDetectionsReport>(
    'patch',
    `/internal-services/detections-report/${id}`,
    undefined,
    swrConfig
  );
