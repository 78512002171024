import { AlertType, RiskState } from '../const';
import type { MarkerColors } from '../types';

interface RiskColorsScheme {
  color: MarkerColors;
  className: string;
  hex: string;
}

export const getRiskColors = (
  riskState: RiskState | undefined,
  alertType?: AlertType
): RiskColorsScheme => {
  switch (riskState) {
    case RiskState.EXTREME_RISK:
      return { color: 'red', className: 'bg-red-500', hex: '#EF4444' };
    case RiskState.HIGH_RISK:
      return { color: 'orange', className: 'bg-orange-500', hex: '#F97316' };
    case RiskState.MODERATE_RISK:
      return { color: 'yellow', className: 'bg-yellow-300', hex: '#FDE047' };
    default:
      return alertType === AlertType.GENERAL
        ? { color: 'green', className: 'bg-green-500', hex: '#22C55E' }
        : { color: 'blue', className: 'bg-blue-500', hex: '#3B82F6' };
  }
};
