import { XMarkIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

export interface ChipProps {
  text: string | undefined;
  onClose?: () => void;
  className?: string;
  dataPw?: string;
}

export const Chip: React.FC<ChipProps> = ({ text, onClose, className, dataPw }) => {
  if (!text) return null;

  return (
    <div
      className={twMerge(
        'flex items-center truncate rounded-full border border-gray-200 px-4 py-2 text-sm text-gray-700 shadow-filter-button focus:outline-none active:outline-none',
        className
      )}
    >
      <p data-pw={dataPw} className='truncate'>
        {text}
      </p>
      {onClose ? (
        <div
          className='-m-2 ml-1 cursor-pointer rounded-3xl p-1 transition-colors hover:bg-gray-100 active:bg-gray-200'
          onClick={onClose}
        >
          <XMarkIcon className='h-5 w-5' />
        </div>
      ) : null}
    </div>
  );
};
