import { useMemo } from 'react';
import ReactPlayer from 'react-player';

import { VideoReviewIcon } from '@fleet/components';

export interface ChatItem {
  id: string;
  user: string;
  message: string | React.ReactNode;
}

export type InitialActionMode = 'question' | 'upload';

export interface SuggestedQuestion {
  icon?: React.ReactNode;
  title: string;
  description: string;
  onClick?: () => void;
}

export const VideoMessage: React.FC<{ videoURL: string }> = ({ videoURL }) => {
  const playerStyle: React.CSSProperties = useMemo(
    () => ({
      borderRadius: '0.5rem',
      overflow: 'hidden'
    }),
    []
  );

  return (
    <div className='space-y-2'>
      {/* <p className='inline text-sm text-system-200'>File uploaded </p>
      <p className='inline text-sm text-system-400'>TODO-NAME.mp4</p> */}
      <div className='w-5/6 rounded-lg p-2 shadow-button-border'>
        <ReactPlayer
          style={playerStyle}
          url={videoURL}
          controls
          loop
          playsinline
          muted
          width='100%'
          height='auto'
        />
      </div>
    </div>
  );
};

export const suggestedQuestions: SuggestedQuestion[] = [
  {
    icon: <VideoReviewIcon />,
    title: 'Title 1',
    description: 'Description 1',
    onClick: () => void 0
  },
  {
    icon: <VideoReviewIcon />,
    title: 'Title 2',
    description: 'Description 2',
    onClick: () => void 0
  },
  {
    icon: <VideoReviewIcon />,
    title: 'Title 3',
    description: 'Description 3',
    onClick: () => void 0
  }
];

export const ORG_FILES = ['Hard-Hat-Policy.pdf'];

export const JCB_ORG_FILES = [
  'JCB_Pedestrian_and_Vehicle_Marking_System.pdf',
  'JCB_Transport_and_Vehicle_Safety.pdf',
  'JCB_Hard_Hat_Policy.pdf'
];

export const REGULATIONS = [
  '🇦🇺 Safe Work Australia',
  '🇺🇸 US Occupational Safety and Health Administration',
  '🇺🇸 US Mine Safety and Health Administration',
  '🇬🇧 UK Health and Safety Executive'
];

export const TARONGA_GROUP_ORG_FILES = ['Taronga_Ventures_White_Shirt_Policy.pdf'];
