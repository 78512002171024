import type { BarDatum, BarTooltipProps, ComputedDatum } from '@nivo/bar';

import { DateAggregation } from '../../../const';
import type { AggregatedAnalyticsDetections } from '../../../types';
import { generateCSVDates, generateGraphDates } from '../../../utils';

export const generateGraphData = ({
  aggregatedDetections,
  periodEndAt,
  dateAggregation,
  count
}: {
  aggregatedDetections: AggregatedAnalyticsDetections;
  periodEndAt: string;
  dateAggregation: DateAggregation;
  count: number;
}) => {
  const {
    critPeopleDetections,
    peopleDetections,
    critVehicleDetections,
    vehicleDetections,
    critConeDetections,
    coneDetections
  } = aggregatedDetections;


  return aggregatedDetections.critPeopleDetections.map((_, index) => ({
    ...(dateAggregation === DateAggregation.DAILY
      ? { day: generateGraphDates({ count, index, periodEndAt, dateAggregation }) }
      : { week: generateGraphDates({ count, index, periodEndAt, dateAggregation }) }),
    People: peopleDetections[index] + critPeopleDetections[index],
    Vehicles: vehicleDetections[index] + critVehicleDetections[index],
    Cones: coneDetections[index] + critConeDetections[index]
  }));
};

export const generateCSVData = ({
  aggregatedDetections,
  org,
  project,
  device,
  periodEndAt,
  count,
  dateAggregation
}: {
  aggregatedDetections: AggregatedAnalyticsDetections;
  org?: string;
  project?: string;
  device?: string;
  periodEndAt: string;
  count: number;
  dateAggregation: DateAggregation;
}) => {
  const {
    critPeopleDetections,
    peopleDetections,
    critVehicleDetections,
    vehicleDetections,
    critConeDetections,
    coneDetections
  } = aggregatedDetections;

  return aggregatedDetections.critPeopleDetections.map((_, index) => ({
    ...(org ? { 'Organization name': org } : {}),
    ...(project ? { 'Project name': project } : {}),
    ...(device ? { 'Device name': device } : {}),
    ...generateCSVDates({ count, index, periodEndAt, dateAggregation }),
    'Total People Detections': peopleDetections[index] + critPeopleDetections[index],
    'Total Vehicle Detections': vehicleDetections[index] + critVehicleDetections[index],
    'Total Cone Detections': coneDetections[index] + critConeDetections[index]
  }));
};

export const legends = [
  { label: 'People', icon: <div className='h-2 w-2 rounded-full bg-enterprise' /> },
  { label: 'Vehicles', icon: <div className='h-2 w-2 rounded-full bg-blue-500' /> },
  { label: 'Cones', icon: <div className='h-2 w-2 rounded-full bg-green-500' /> }
];

export const getCustomTooltip = ({ data }: BarTooltipProps<BarDatum>) =>
  ['People', 'Vehicles', 'Cones'].map(key => (
    <p key={key}>
      {key}: {data[key]?.toLocaleString() ?? 0}
    </p>
  ));

export const getBarColor = (bar: ComputedDatum<BarDatum>) => {
  if (bar.id === 'People') return 'rgb(255, 125, 51)';
  if (bar.id === 'Vehicles') return 'rgb(59 130 246)';
  if (bar.id === 'Cones') return 'rgb(34 197 94)';

  return 'rgb(107 114 128)';
};
