import { PlusIcon } from '@heroicons/react/24/outline';
import type { SortingState } from '@tanstack/react-table';
import type { Row } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { useState } from 'react';

import { Loading } from '@fleet/components';
import { Button } from '@fleet/components';
import { snakeToSentenceCase } from '@fleet/utils';

import { DataTable } from '../../atoms';
import { CreateProjectModal } from '../../molecules';
import { OrganizationUserRole } from '../../../const';
import { type Project, useAuthContext, useGetProjects } from '../../../hooks';

const columnHelper = createColumnHelper<Project>();

const DEFAULT_SORTING: SortingState = [{ id: 'projectName', desc: false }];

export const OrganisationProjects: React.FC = () => {
  const { currentOrganizationId, user, currentOrganizationRole } = useAuthContext();
  const canEdit = currentOrganizationRole === OrganizationUserRole.ADMIN;
  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
  const { data, isLoading, mutate } = useGetProjects({
    organizationIds: currentOrganizationId
  });
  const router = useRouter();

  const columns = useMemo(
    () => [
      columnHelper.accessor(project => project.name, {
        header: 'Project name',
        cell: props => props.getValue(),
        id: 'projectName'
      }),
      columnHelper.accessor(project => project.fullAddress, {
        header: 'Address',
        cell: props => props.getValue() || 'No address'
      }),
      columnHelper.accessor(
        project => project.projectUsers?.find(pu => pu.userId === user?.userId)?.role,
        {
          header: 'Your role',
          cell: props => snakeToSentenceCase(props.getValue()) || 'No Role'
        }
      ),
      columnHelper.accessor(project => project.projectUsers?.length, {
        header: 'Users',
        cell: props => props.getValue()
      }),
      columnHelper.accessor(project => project.devices?.length, {
        header: 'Devices',
        cell: props => props.getValue()
      })
    ],
    [user]
  );

  const tableData = data?.results || [];

  const onRowClick = useCallback(
    (row: Row<Project>) => {
      router.push(`/projects/${row.original.id}`);
    },
    [router]
  );

  const onModalClose = useCallback(() => {
    setCreateProjectModalOpen(false);
    mutate();
  }, [mutate]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className='mb-5 flex h-11 items-center justify-between'>
        <h2 className='text-lg'>
          {tableData.length} Project{tableData.length > 1 && 's'}
        </h2>
        <Button
          icon={<PlusIcon className='h-5 w-4' />}
          variant='solid'
          disabled={!canEdit}
          onClick={() => {
            setCreateProjectModalOpen(true);
          }}
        >
          New Project
        </Button>
      </div>
      {tableData.length > 0 ? (
        <DataTable
          columns={columns}
          data={tableData}
          enableSorting
          defaultSorting={DEFAULT_SORTING}
          onRowClick={onRowClick}
        />
      ) : (
        <div className='text-center text-gray-500'>No projects to show.</div>
      )}
      <CreateProjectModal isOpen={createProjectModalOpen} onClose={onModalClose} />
    </>
  );
};
