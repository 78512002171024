// Deprecated, kept for reference. Auth is handled by AuthContextProvider and useRequiresAuth hook

import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

import { Button, Loading } from '@fleet/components';

import { Modal } from '../../atoms';
import { Notification } from '../../atoms/Notification/Notification';
import { NotificationType } from '../../atoms/Notification/Notification';
import { Header } from '../../molecules';
import { Routes } from '../../../const';
import { UpdateStatus, useGetUser, useUpdateCheck } from '../../../hooks';
import AuthContextProvider from '../../../providers/AuthContextProvider';
import { axiosInstance, getFromEnvConfig } from '../../../utils';
import { shouldUserUpdateDetails } from '../../../utils';

const ROUTES_WITHOUT_HEADER = new Set([Routes.SAGE]);

// Pages that don't require standard user authentication
const ROUTES_WITHOUT_AUTH = new Set([
  Routes.HEXAGON_APP,
  Routes.HEXAGON_DETECTION_DETAILS,
  Routes.HEXAGON_DEVICE_CONFIG
]);

export const AuthedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [authHeaderLoading, setLoading] = useState(true);
  const { getAccessTokenSilently, isLoading: auth0Loading, logout } = useAuth0();
  const { status } = useUpdateCheck({ type: 'interval', interval: 10_000 });

  const logoutUser = useCallback(
    () => logout({ logoutParams: { returnTo: window.location.origin } }),
    [logout]
  );

  const router = useRouter();

  const reloadPage = useCallback(() => window.location.reload(), []);

  const {
    data: user,
    error,
    isLoading: userLoading
  } = useGetUser(authHeaderLoading || Boolean(router.query.tempAccessToken));

  useEffect(() => {
    if (!user) return;

    window.pendo.initialize({
      visitor: {
        email: user.results.email,
        id: user.results.userId
      },
      account: {
        email: user.results.email,
        id: user.results.userId
      }
    });

    window.Intercom('boot', {
      app_id: getFromEnvConfig('INTERCOM_ID'),
      email: user.results.email,
      user_id: user.results.userId
    });
  }, [user]);

  useEffect(() => {
    const init = async () => {
      if (!router.isReady) return;
      let token = router.query.tempAccessToken;
      if (!token) {
        try {
          token = await getAccessTokenSilently();
        } catch {
          await logoutUser();
        }
      }

      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setLoading(false);
    };

    if (axiosInstance.defaults.headers.common['Authorization']) return setLoading(false);

    init();
  }, [getAccessTokenSilently, logoutUser, router.isReady, router.query]);

  useEffect(() => {
    if (error?.response?.status === 401 && error?.response?.data?.message === 'Unauthorized') {
      logoutUser();
    }
  }, [error, logoutUser]);

  useEffect(() => {
    if (status === UpdateStatus.AVAILABLE) {
      toast(
        () => (
          <Notification
            description='New version available'
            buttonLabel='Update'
            onButtonClick={reloadPage}
            type={NotificationType.Success}
          />
        ),
        { duration: Number.POSITIVE_INFINITY }
      );
    }
  }, [reloadPage, status]);

  useEffect(() => {
    if (!user) return;
    if (router.pathname !== Routes.ACCOUNT && shouldUserUpdateDetails(user.results)) {
      const toastId = 'update-profile-notification';
      toast(
        t => (
          <Notification
            description='Profile is incomplete'
            buttonLabel='Update'
            type={NotificationType.Warning}
            onButtonClick={() => {
              toast.dismiss(t.id);
              router.push(Routes.ACCOUNT);
            }}
            showCloseButton
            onCloseClick={() => toast.dismiss(toastId)}
          />
        ),
        { id: toastId, duration: 5000 }
      );
    }
  }, [router, user]);

  const toastClass = useMemo(() => ({ className: '!rounded-full !max-w-[600px]' }), []);

  if (authHeaderLoading || auth0Loading || (userLoading && !error)) return <Loading />;

  // Handles case where Auth0 user is not found in the database

  if (error?.response?.status === 401 && error?.response?.data?.message === 'User not found') {
    return (
      <Modal isOpen title='Access denied' className='mt-4'>
        <p>
          This email address is not authorised to access the Blindsight Enterprise Reporting
          application. Please confirm your login details and if you're still having issues accessing
          your account, contact support@presien.com for assistance.
        </p>
        <div className='flex justify-end'>
          <Button variant='solid' className='mt-4 text-lg' onClick={logoutUser}>
            Logout
          </Button>
        </div>
      </Modal>
    );
  }

  if (ROUTES_WITHOUT_AUTH.has(router.route as Routes)) {
    return children;
  }

  return (
    <AuthContextProvider>
      <Toaster position='top-right' toastOptions={toastClass} />
      {ROUTES_WITHOUT_HEADER.has(router.route as Routes) ? null : <Header />}
      {children}
    </AuthContextProvider>
  );
};
