import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { Button, Loading } from '@fleet/components';
import { snakeToSentenceCase } from '@fleet/utils';

import {
  DataTable,
  DeleteOrganizationUserModal,
  EditOrganizationUserModal,
  InviteOrganizationUserModal
} from '../..';
import { OrganizationUserRole, UserTypes } from '../../../const';
import { SUPPORT_EMAIL_DOMAINS } from '../../../const/Users';
import type { OrganizationUser } from '../../../hooks';
import { useAuthContext } from '../../../hooks';
import { useGetOrganizationUsers } from '../../../hooks/api/useGetOrganizationUsers';
import { timeAgoFromNow } from '../../../utils';
import { sortTimeAgoFromNowColumn } from '../../../utils';
import { getUserFullName } from '../../../utils/User';

const columnHelper = createColumnHelper<OrganizationUser>();

const DEFAULT_SORTING: SortingState = [{ id: 'lastActive', desc: false }];

export const OrganisationUsers: React.FC = () => {
  const [editOrganizationUser, setEditOrganizationUser] = useState<OrganizationUser | undefined>();
  const [deleteOrganizationUser, setDeleteOrganizationUser] = useState<
    OrganizationUser | undefined
  >();
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState<boolean>(false);

  const { currentOrganizationId, currentOrganizationRole, refetchAllQueries, user } =
    useAuthContext();

  const canEdit = currentOrganizationRole === OrganizationUserRole.ADMIN;

  const { data, mutate, isLoading } = useGetOrganizationUsers(
    { organizationId: currentOrganizationId },
    !currentOrganizationId
  );

  const columns = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: ColumnDef<OrganizationUser, any>[] = [
      columnHelper.accessor(
        link =>
          (link.user.email === user?.email
            ? `${getUserFullName(link.user)} (You)`
            : getUserFullName(link.user)) || 'No name',
        {
          // Change you here
          header: 'Name',
          cell: props => props.getValue(),
          id: 'name'
        }
      ),
      columnHelper.accessor(link => link?.user.email, {
        header: 'Email',
        cell: props => <div className='w-72'>{props.getValue() || ''}</div>,
        id: 'email'
      }),
      columnHelper.accessor(
        link => (link?.user.lastActive ? timeAgoFromNow(link?.user.lastActive) : 'Never'),
        {
          header: 'Last Active',
          cell: props => props.getValue(),
          id: 'lastActive',
          sortingFn: sortTimeAgoFromNowColumn
        }
      ),
      columnHelper.accessor(link => snakeToSentenceCase(link?.role), {
        header: 'Role',
        cell: props => props.getValue() || 'No Role',
        id: 'role'
      }),
      columnHelper.accessor(link => link.user.projectUsers?.length || 0, {
        header: 'Projects',
        cell: props => props.getValue(),
        id: 'projects'
      })
    ];
    if (canEdit)
      columns.push(
        columnHelper.display({
          header: 'Actions',
          cell: props => {
            return (
              <div className='flex space-x-2'>
                <PencilIcon
                  className='h-9 w-9 cursor-pointer p-2 text-brand'
                  onClick={() => setEditOrganizationUser(props.row.original)}
                />
                <TrashIcon
                  className='h-9 w-9 cursor-pointer p-2 text-brand'
                  onClick={() => setDeleteOrganizationUser(props.row.original)}
                />
              </div>
            );
          }
        })
      );
    return columns;
  }, [canEdit, user?.email]);

  const tableData = useMemo(() => {
    let orgUsers = data?.results || [];
    if (user?.type === UserTypes.CUSTOMER)
      orgUsers = orgUsers.filter(({ user }) => user.type === UserTypes.CUSTOMER);
    if (!SUPPORT_EMAIL_DOMAINS.some(domain => user?.email.endsWith(domain))) {
      orgUsers = orgUsers.filter(
        ({ user }) => !SUPPORT_EMAIL_DOMAINS.some(domain => user.email.endsWith(domain))
      );
    }

    return orgUsers.map(orgUsers => {
      return {
        ...orgUsers,
        ...(orgUsers.user.email === user?.email && { className: 'font-medium' })
      };
    });
  }, [data?.results, user?.email, user?.type]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className='mb-5 flex h-11 items-center justify-between'>
        <h2 className='text-lg'>{tableData.length} Organisation Users</h2>
        <Button
          icon={<PlusIcon className='h-5 w-4' />}
          variant='solid'
          disabled={!canEdit}
          onClick={() => setInviteUserModalOpen(true)}
        >
          Invite User
        </Button>
      </div>
      {tableData.length > 0 ? (
        <DataTable
          columns={columns}
          data={tableData}
          enableSorting
          defaultSorting={DEFAULT_SORTING}
        />
      ) : (
        <div className='text-center text-gray-500'>This organisation has no users.</div>
      )}
      <InviteOrganizationUserModal
        isOpen={inviteUserModalOpen}
        onClose={() => {
          mutate();
          setInviteUserModalOpen(false);
        }}
      />
      <EditOrganizationUserModal
        organizationUser={editOrganizationUser}
        isOpen={Boolean(editOrganizationUser && !deleteOrganizationUser)}
        onClose={() => {
          mutate();
          refetchAllQueries();
          setEditOrganizationUser(undefined);
        }}
      />
      <DeleteOrganizationUserModal
        organizationUser={deleteOrganizationUser}
        isOpen={Boolean(deleteOrganizationUser)}
        onClose={() => {
          mutate();
          refetchAllQueries();
          setDeleteOrganizationUser(undefined);
        }}
      />
    </>
  );
};
