import type { Row } from '@tanstack/react-table';
import { enAU } from 'date-fns/locale';
import { format as formatTZ, getTimezoneOffset } from 'date-fns-tz';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { NextRouter } from 'next/router';
import type { DateRange } from 'react-day-picker';

import { DateAggregation, sevenDaysAgo, startOfLastMonth } from '../const';
import {
  DateOptions,
  DAY_IN_S,
  dayJS,
  DEFAULT_TIMEZONE,
  LocalStorageKeys,
  SHORT_DATE_FORMAT
} from '../const';
import type { OrganizationUser, ProjectUser } from '../hooks';

/**
 * Usage Guide
 *
 * We store the users' timezone locally in local storage once they have selected a timezone, otherwise
 * if no timezone is found we default to the Sydney timezone set in config.ts.
 *
 * For any date functions, we should almost always use dayJS, which is a wrapper around the native
 * dayjs library function. We use this since it comes preconfigured with the base settings we need
 * and exposes only what should be exposed as constructor params.
 *
 * Helper functions for dates are also provided in this file, with the more complex functions
 * generally depending on dayJS. As a result, if any documention is required for certain params or returns
 * of a util function here that involves dayJS, you can refer to their documentation for examples and details.
 *
 * Functions also take object params so that we can easily cater the function usage to more specific use cases
 * in a cleaner way.
 */

/**
 * @returns the timezone stored in local storage, or the default timezone if not found
 */
export const getStoredTimezone = () => {
  if (typeof window === 'undefined') return DEFAULT_TIMEZONE;

  const storedTimezone = window.localStorage.getItem(LocalStorageKeys.TIMEZONE);
  return storedTimezone?.replaceAll('"', '') ?? DEFAULT_TIMEZONE;
};

/**
 * @param timezone the timezone to store in local storage
 */
export const setStoredTimezone = (timezone: string) => {
  if (typeof window === 'undefined') return;

  window.localStorage.setItem(LocalStorageKeys.TIMEZONE, JSON.stringify(timezone));
};

/**
 * @param date the date to format
 * @param dateFormat the format to use for the date
 * @returns the formatted Date string
 *
 * @example
 * getFormattedDate(new Date(), 'DD/MM/YYYY')
 * returns '01/01/2022'
 */
export const getFormattedDate = (date: Date | undefined, dateFormat: string) => {
  return dayJS({ date }).format(dateFormat);
};

/**
 * @param from the start date
 * @param to the end date
 * @param dateFormat the format to use for the date
 * @returns the formatted Date range string
 *
 * @example
 * getFormattedDateRange({ from: new Date(), to: new Date() }, 'DD/MM/YYYY')
 * returns '01/01/2022 - 01/01/2022'
 */
export const getFormattedDateRange = ({ from, to }: DateRange, dateFormat: string) => {
  if (JSON.stringify(from) === JSON.stringify(to) || !to) {
    return getFormattedDate(from, dateFormat);
  }

  return `${getFormattedDate(from, dateFormat)} - ${getFormattedDate(to, dateFormat)}`;
};

/**
 * @param date the date to format
 * @param dateFormat the format to use for the date
 * @param timezone the timezone to use for the format
 * @param dateAggregation the date aggregation to use for the format
 * @param includeTimezone
 * @returns the formatted Date string with timezone
 *
 * @example
 * getFormattedTZDate({
 *  date: dayJS({ date: new Date() }),
 *  dateFormat: 'DD/MM/YYYY',
 *  timezone: 'Australia/Sydney',
 *  dateAggregation: DateAggregation.DAILY
 * })
 * returns '01/01/2022 (AEDT)'
 */
export const getFormattedTZDate = ({
  date,
  dateFormat,
  timezone,
  dateAggregation,
  includeTimezone = true
}: {
  date: Dayjs;
  dateFormat: string;
  timezone?: string;
  dateAggregation?: DateAggregation;
  includeTimezone?: boolean;
}) => {
  if (dateAggregation === DateAggregation.WEEKLY) {
    const weekDate = date.endOf('day').add(1, 'millisecond');
    const formattedRange = `${weekDate.format(dateFormat)} - ${weekDate.add(6, 'days').format(dateFormat)}`;
    const timezoneInfo = includeTimezone
      ? ` (${formatTZ(weekDate.toDate(), 'z', {
          timeZone: timezone ?? getStoredTimezone(),
          locale: enAU
        })})`
      : '';
    return `${formattedRange}${timezoneInfo}`;
  }

  const timezoneInfo = includeTimezone
    ? ` (${formatTZ(date.toDate(), 'z', {
        timeZone: timezone ?? getStoredTimezone(),
        locale: enAU
      })})`
    : '';

  return `${date.format(dateFormat)}${timezoneInfo}`;
};

/**
 * @param value the date option to get the date values for
 * @param router the router to use for navigation for fallback dates
 * @param analyticsFallback whether to use the analytics fallback for date options
 * @returns the date values for the date option
 *
 * @example
 * mapDateLabelToValue(DateOptions.LAST_7_DAYS, router, true)
 * returns {
 *  startAt: '2022-01-01T00:00:00.000Z',
 *  reportingPeriodSecs: 604800,
 *  dateAggregation: DateAggregation.DAILY,
 *  comparison: true,
 *  comparisonPrevText: 'the previous last seven days',
 *  comparisonCurrText: 'the last seven days',
 *  count: 7
 * }
 */
export const mapDateLabelToValue = (
  value: DateOptions | string | undefined,
  // next-line: temp workaround for unsupported date options for analytics page
  router: NextRouter,
  analyticsFallback = false
): {
  startAt: string;
  reportingPeriodSecs: number;
  dateAggregation: DateAggregation;
  comparison?: boolean;
  comparisonPrevText?: string;
  comparisonCurrText?: string;
  count: number;
} => {
  // if (analyticsFallback && (value === DateOptions.TODAY || value === DateOptions.YESTERDAY)) {
  //   return {
  //     startAt: oneWeekAgo.toISOString(),
  //     reportingPeriodSecs: WEEK_IN_S,
  //     dateAggregation: DateAggregation.DAILY,
  //     comparison: true,
  //     comparisonPrevText: 'the previous last seven days',
  //     comparisonCurrText: 'the last seven days'
  //   };
  // }

  switch (value) {
    // case DateOptions.TODAY:
    //   return {
    //     startAt: startOfToday.toISOString(),
    //     reportingPeriodSecs: DAY_IN_S,
    //     dateAggregation: DateAggregation.DAILY
    //   };
    // case DateOptions.YESTERDAY:
    // case 'Last day':
    //   return {
    //     startAt: startOfYesterday.toISOString(),
    //     reportingPeriodSecs: DAY_IN_S,
    //     dateAggregation: DateAggregation.DAILY,
    //     comparison: true,
    //     comparisonPrevText: 'the day before',
    //     comparisonCurrText: 'yesterday'
    //   };
    // case DateOptions.THIS_WEEK:
    //   return {
    //     startAt: startOfThisWeek.toISOString(),
    //     reportingPeriodSecs: WEEK_IN_S,
    //     dateAggregation: DateAggregation.DAILY
    //   };
    case DateOptions.LAST_7_DAYS:
    case undefined:
      return {
        startAt: sevenDaysAgo.toISOString(),
        // analytics only includes last 7 days, while other pages show last 7 days plus today
        reportingPeriodSecs: DAY_IN_S * 7,
        dateAggregation: DateAggregation.DAILY,
        count: 7,
        comparison: true,
        comparisonPrevText: 'the previous last 7 days',
        comparisonCurrText: 'the last 7 days'
      };
    // case DateOptions.LAST_WEEK:
    //   return {
    //     startAt: startOfLastWeek.toISOString(),
    //     reportingPeriodSecs: WEEK_IN_S,
    //     dateAggregation: DateAggregation.DAILY,
    //     comparison: true,
    //     comparisonPrevText: 'the week before last week',
    //     comparisonCurrText: 'last week'
    //   };
    // case DateOptions.THIS_MONTH:
    //   return {
    //     startAt: startOfThisMonth.toISOString(),
    //     reportingPeriodSecs: startOfThisMonth.daysInMonth() * DAY_IN_S,
    //     dateAggregation: DateAggregation.DAILY
    //   };
    case DateOptions.LAST_MONTH:
      return {
        startAt: startOfLastMonth.toISOString(),
        reportingPeriodSecs: startOfLastMonth.daysInMonth() * DAY_IN_S,
        dateAggregation: DateAggregation.DAILY,
        count: startOfLastMonth.daysInMonth(),
        comparison: true,
        comparisonPrevText: 'the month before last month',
        comparisonCurrText: 'last month'
      };

    default: {
      const [from, to] = value?.split(' - ') ?? [];
      if (!from && !to) {
        router.replace({ query: { ...router.query, date: DateOptions.LAST_7_DAYS } });
        return {
          startAt: sevenDaysAgo.toISOString(),
          reportingPeriodSecs: 7,
          dateAggregation: DateAggregation.DAILY,
          count: 7
        };
      }

      if (Number.isNaN(new Date(from).getTime())) {
        router.replace({ query: { ...router.query, date: DateOptions.LAST_7_DAYS } });
        return {
          startAt: sevenDaysAgo.toISOString(),
          reportingPeriodSecs: 7,
          dateAggregation: DateAggregation.DAILY,
          count: 7
        };
      }

      if (Number.isNaN(new Date(to).getTime())) {
        return {
          startAt: dayJS({ date: from }).toISOString(),
          reportingPeriodSecs: DAY_IN_S,
          dateAggregation: DateAggregation.DAILY,
          count: 1
        };
      }

      const timeRangeSecs = dayJS({ date: to }).diff(dayJS({ date: from }), 'seconds');
      const dateAggregation =
        timeRangeSecs > DAY_IN_S * 31 ? DateAggregation.WEEKLY : DateAggregation.DAILY;

      const fromDayJS = dayJS({ date: from });

      if (dateAggregation === DateAggregation.WEEKLY) {
        const weekStart =
          // Day is monday
          fromDayJS.day() === 1 ? fromDayJS.toISOString() : fromDayJS.startOf('week').toISOString();
        const weekEndDayJS = fromDayJS.add(timeRangeSecs, 's').endOf('week').add(1, 'millisecond');

        const count = weekEndDayJS.diff(dayJS({ date: weekStart }), 'week');

        const reportingPeriodSecs = fromDayJS
          .add(timeRangeSecs, 's')
          .endOf('week')
          .diff(dayJS({ date: weekStart }), 'seconds');

        return {
          startAt: weekStart,
          reportingPeriodSecs,
          dateAggregation,
          count
        };
      }

      const dailyTimeRange = timeRangeSecs + DAY_IN_S;

      return {
        startAt: fromDayJS.toISOString(),
        reportingPeriodSecs: dailyTimeRange,
        dateAggregation,
        count: Math.ceil(dailyTimeRange / DAY_IN_S)
      };
    }
  }
};

/**
 * @param seconds the seconds to format
 * @returns the formatted duration string in hours, minutes and seconds
 *
 * @example
 * getFormattedDuration(109482)
 * returns '30h 24m 42s'
 */
export const getFormattedDuration = (seconds: number | string) => {
  const convertedSeconds = Number(seconds);

  if (Number.isNaN(convertedSeconds) || convertedSeconds === 0) return '0s';

  const hours = Math.floor(convertedSeconds / 3600);
  const minutes = Math.floor((convertedSeconds % 3600) / 60);
  const secondsLeft = convertedSeconds % 60;

  let duration = '';

  if (hours) duration += `${hours}h`;
  if (minutes) duration += ` ${minutes}m`;
  if (secondsLeft) duration += ` ${Math.floor(secondsLeft)}s`;

  return duration;
};

/**
 * @param count the number of total dates for the graph
 * @param index the index of the current date being passed in
 * @param periodEndAt the end date of the graph dates
 * @param dateAggregation the date aggregation to use for the format
 * @returns the formatted graph date/s string
 *
 * @example
 * generateGraphDates({
 *  count: 4,
 *  index: 0,
 *  periodEndAt: '2022-01-01T00:00:00.000Z',
 *  dateAggregation: DateAggregation.WEEKLY
 * })
 * returns '01/01 - 07/01'
 */
export const generateGraphDates = ({
  count,
  index,
  periodEndAt,
  dateAggregation
}: {
  count: number;
  index: number;
  periodEndAt: string;
  dateAggregation?: DateAggregation;
}) => {
  if (dateAggregation === DateAggregation.WEEKLY) {
    return dayJS({ date: periodEndAt })
      .endOf('day')
      .add(1, 'millisecond')
      .subtract(count - index, 'weeks')
      .format('D MMM YY');
  }

  return dayJS({ date: periodEndAt })
    .subtract(count - index, 'days')
    .format(count > 7 ? 'D MMM YY' : 'ddd');
};

/**
 * @param count the number of total dates for the CSV
 * @param index the index of the current date being passed in
 * @param periodEndAt the end date of the CSV dates
 * @param dateAggregation the date aggregation to use for the format
 * @returns the formatted CSV date string
 *
 * @example
 * generateCSVDates({
 *  count: 4,
 *  index: 0,
 *  periodEndAt: '2022-01-01T00:00:00.000Z',
 *  dateAggregation: DateAggregation.WEEKLY
 * })
 * returns '01/01 - 07/01'
 */
export const generateCSVDates = ({
  count,
  index,
  periodEndAt,
  dateAggregation
}: {
  count: number;
  index: number;
  periodEndAt: string;
  dateAggregation?: DateAggregation;
}) => {
  const key = `Date (${getTimezoneAbbreviation(getStoredTimezone())})`;

  if (dateAggregation === DateAggregation.WEEKLY) {
    const weekStart = dayJS({ date: periodEndAt })
      .endOf('day')
      .add(1, 'millisecond')
      .subtract(count - index, 'weeks');
    return {
      [key]: `${weekStart.format('YYYY-MM-DD')} - ${weekStart.add(6, 'days').format('YYYY-MM-DD')}`
    };
  }

  return {
    [key]: dayJS({ date: periodEndAt })
      .subtract(count - index, 'day')
      .format('YYYY-MM-DD')
  };
};

/**
 * @param timezone the timezone to get the offset for
 * @returns the timezone offset in hours and minutes
 *
 * @example
 * getTimezoneOffset('Australia/Sydney')
 * returns '+11:00'
 */
export const getFormattedTimezoneOffset = (timezone: string) => {
  const offset = getTimezoneOffset(timezone);
  const hours = Math.floor(offset / 3_600_000);
  const minutes = Math.floor((offset % 3_600_000) / 60_000);

  return `${hours < 0 ? '-' : '+'}${Math.abs(hours)}:${minutes < 10 ? '0' : ''}${minutes}`;
};

/**
 * @param timezone the timezone to get the abbreviation for
 * @returns the timezone abbreviation
 *
 * @example
 * getTimezoneAbbreviation('Australia/Sydney')
 * returns 'AEDT'
 */
export const getTimezoneAbbreviation = (timezone: string) => {
  return formatTZ(new Date(), 'z', { timeZone: timezone, locale: enAU });
};

/**
 * @param date the date being filtered with
 * @returns the formatted date filter label
 *
 * @example
 * displayDateFilterLabel('2022-01-01T00:00:00.000Z')
 * returns '01/01/2022'
 */
export const displayDateFilterLabel = (date: string | undefined) => {
  if (!date) return;
  if (date.includes('Today') || date.includes('Last')) return date;

  const [from, to] = date.split(' - ');

  if (!to || from === to) return getFormattedDate(new Date(from), SHORT_DATE_FORMAT);

  return getFormattedDateRange({ from: new Date(from), to: new Date(to) }, SHORT_DATE_FORMAT);
};

export const getNowUtc = () => dayjs.utc().format();

export const timeAgoFromNow = (date: string) => {
  const dateObj = dayjs(date).startOf('day');
  const today = dayjs().startOf('day');

  // Return today
  if (dateObj.isSame(today, 'day')) {
    return 'Today';
  }

  // If it's older than today but less than 46 days ago, return x days ago
  const daysAgo = today.diff(dateObj, 'days');
  if (daysAgo > 0 && daysAgo < 46) {
    return daysAgo === 1 ? 'a day ago' : `${daysAgo} days ago`;
  }

  // Otherwise, display "nth Month, Year"
  return dateObj.format('D MMM YYYY');
};

const getTimeAgoFromNowSortPriority = (value: string) => {
  if (value === 'Never') return 2;
  if (value === 'Today') return 0;
  return 1;
};

export const sortTimeAgoFromNowColumn = (
  rowA: Row<OrganizationUser | ProjectUser>,
  rowB: Row<OrganizationUser | ProjectUser>,
  columnId: string
) => {
  const valueA: string = rowA.getValue(columnId);
  const valueB: string = rowB.getValue(columnId);

  const priorityA = getTimeAgoFromNowSortPriority(valueA);
  const priorityB = getTimeAgoFromNowSortPriority(valueB);

  if (priorityA === priorityB) {
    // If both values have the same priority, compare actual date values for relative times
    if (priorityA === 1 && priorityB === 1) {
      return dayjs(rowA.original.user.lastActive).isAfter(dayjs(rowB.original.user.lastActive))
        ? -1
        : 1;
    }
    return 0; // They are the same
  }

  // Sort by priority
  return priorityA - priorityB;
};

export const generateTimeSlots = (startHour = 7, endHour = 9) => {
  const timeSlots = [];
  for (let hour = startHour; hour <= endHour; hour++) {
    // Push "hour:00:00" format
    timeSlots.push(`${String(hour).padStart(2, '0')}:00:00`);
    // Push "hour:30:00" format, except for the last hour
    if (hour < endHour) {
      timeSlots.push(`${String(hour).padStart(2, '0')}:30:00`);
    }
  }
  return timeSlots;
};
