import type { SWRMutationConfig } from './useApiHooks';
import { useMutationApi } from './useApiHooks';
import type { ProjectUserRole } from '../../const';

interface UpdateProjectUserRequest {
  role: ProjectUserRole;
}

interface UpdateProjectUserPathParams {
  projectId: string;
  userId: string;
}

export const useUpdateProjectUser = (
  { projectId, userId }: UpdateProjectUserPathParams,
  swrConfig?: SWRMutationConfig<never>
) =>
  useMutationApi<UpdateProjectUserRequest>(
    'patch',
    `/projects/${projectId}/users/${userId}`,
    undefined,
    swrConfig
  );
