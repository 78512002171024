import type { DivIcon, Icon, MarkerCluster } from 'leaflet';
import { useRouter } from 'next/router';
import { memo, useCallback } from 'react';

import { RiskState } from '../../../const';
import type { Alert } from '../../../hooks';
import { useGetAlertMapMarkers, useGetMarker, useGetMarkerClusterGroup } from '../../../hooks';
import type { QueryParams } from '../../../types';
import { getRiskColors } from '../../../utils';

interface MarkersProps {
  alerts: Alert[] | undefined;
}

export const Markers: React.FC<MarkersProps> = memo(({ alerts }) => {
  const router = useRouter();

  const markerIcons = useGetAlertMapMarkers();
  const Marker = useGetMarker();
  const MarkerClusterGroup = useGetMarkerClusterGroup();

  const handleOnClick = useCallback(
    (alert?: Alert) => {
      if (!alert) return;

      router.replace({ query: { ...router.query, alertId: alert.alertId } satisfies QueryParams });
    },
    [router]
  );

  const createClusterIcon = useCallback((cluster: MarkerCluster): Icon | DivIcon => {
    const childCount = cluster.getChildCount();
    const childMarkers = cluster.getAllChildMarkers();

    const markerRisks: (RiskState | null)[] = [];

    for (const marker of childMarkers) {
      //@ts-expect-error untyped marker icon options object
      const riskState = marker.options.icon?.options.riskState as RiskState | null;
      if (!markerRisks.includes(riskState)) markerRisks.push(riskState);
    }

    const getIconColor = () => {
      if (markerRisks.includes(RiskState.EXTREME_RISK)) return 'bg-red-500';
      if (markerRisks.includes(RiskState.HIGH_RISK)) return 'bg-orange-500';
      if (markerRisks.includes(RiskState.MODERATE_RISK)) return 'bg-yellow-300';

      if (!markerRisks.includes(null)) return 'bg-blue-500';

      return 'bg-green-500';
    };

    const iconClassName = `${getIconColor()} rounded-full flex justify-center items-center bg-opacity-90 hover:brightness-90`;

    const clusterIcon = L.divIcon({
      html: `${childCount}`,
      className: iconClassName,
      iconSize: L.point(40, 40)
    });

    return clusterIcon;
  }, []);

  if (!Marker || !MarkerClusterGroup || !alerts || !markerIcons) return null;

  return (
    <MarkerClusterGroup iconCreateFunction={createClusterIcon} chunkedLoading animate={false}>
      {alerts.map(alert => {
        const { alertId, geolocationLatitude, geolocationLongitude, riskState, alertType } = alert;

        if (!geolocationLatitude || !geolocationLongitude || !markerIcons) return null;

        const position = [geolocationLatitude, geolocationLongitude] satisfies [number, number];

        return (
          <Marker
            alt='detection-map-marker'
            key={alertId}
            position={position}
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            eventHandlers={{ click: () => handleOnClick(alert) }}
            icon={markerIcons[getRiskColors(riskState, alertType).color]}
          />
        );
      })}
    </MarkerClusterGroup>
  );
});
