import type { SWRMutationConfig } from './useApiHooks';
import { useMutationApi } from './useApiHooks';

interface CreateProjectRequest {
  name: string;
  organizationId: string;
}

export const useCreateProject = (swrConfig?: SWRMutationConfig<never>) =>
  useMutationApi<CreateProjectRequest>('post', `/projects`, undefined, swrConfig);
