import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import { Loading } from '@fleet/components';

import AuthContextProvider from '../../../providers/AuthContextProvider';
import { axiosInstance } from '../../../utils';

export enum AuthStatus {
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATED = 'unauthenticated',
  LOADING = 'loading'
}

interface AuthWrapperProps {
  children: React.ReactNode;
}

export const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const [authStatus, setStatus] = useState<AuthStatus>(AuthStatus.LOADING);
  const router = useRouter();

  const limitedAccessToken = useMemo(
    () => router.query.limitedAccessToken as string | undefined,
    [router.query.limitedAccessToken]
  );

  const {
    isAuthenticated: isAuth0Authenticated,
    isLoading: isAuth0Loading,
    getAccessTokenSilently,
    logout
  } = useAuth0();

  // const logoutUser = useCallback(
  //   () =>
  //     logout({
  //       logoutParams: {
  //         returnTo: typeof window === 'undefined' ? '' : window.location.origin + '/login'
  //       }
  //     }),
  //   [logout]
  // );

  useEffect(() => {
    if (!router.isReady) return;

    if (isAuth0Authenticated || limitedAccessToken) {
      setStatus(AuthStatus.AUTHENTICATED);
    } else if (!isAuth0Loading && !isAuth0Authenticated && !limitedAccessToken) {
      setStatus(AuthStatus.UNAUTHENTICATED);
    } else {
      setStatus(AuthStatus.LOADING);
    }
  }, [isAuth0Loading, isAuth0Authenticated, limitedAccessToken, router.isReady]);

  useEffect(() => {
    if (authStatus === AuthStatus.UNAUTHENTICATED) {
      router.push('/login');
    }
  }, [authStatus, router]);

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(
      async config => {
        const token = limitedAccessToken ?? (await getAccessTokenSilently());
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
      },
      (error: Error) => Promise.reject(error)
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
    };
  }, [getAccessTokenSilently, limitedAccessToken]);

  if (authStatus === AuthStatus.LOADING) {
    return <Loading />;
  }

  return <AuthContextProvider>{children}</AuthContextProvider>;
};
