import type { SWRMutationConfig } from './useApiHooks';
import { useMutationApi } from './useApiHooks';

interface MoveDevicesRequest {
  organizationId: string;
  projectId: string;
  devicesIds: string[];
}

export const useMoveDevices = (swrConfig?: SWRMutationConfig<never>) =>
  useMutationApi<MoveDevicesRequest>('patch', `/devices/move`, undefined, swrConfig);
