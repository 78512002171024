import type { BarDatum, BarTooltipProps } from '@nivo/bar';

import { AlertType, DateAggregation, dayJS } from '../../../const';
import { type AggregatedAlert } from '../../../hooks';
import { generateCSVDates, generateGraphDates } from '../../../utils';

export const generateAggregatedCriticalRatio = ({
  alertsData,
  count,
  periodEndAt,
  dateAggregation
}: {
  alertsData: AggregatedAlert[] | undefined;
  count: number;
  periodEndAt: string;
  dateAggregation: DateAggregation;
}) => {
  const aggregatedAlerts = new Array<number>(count).fill(0);
  const aggregatedCriticalAlerts = new Array<number>(count).fill(0);

  const diffUnit = dateAggregation === DateAggregation.WEEKLY ? 'week' : 'day';

  alertsData?.map(({ periodStartAt, alertType, ...rest }) => {
    const alertCount = rest.confirmedAlertCount + rest.notReviewedAlertCount;

    const offset = Math.floor(
      count - dayJS({ date: periodEndAt }).diff(dayJS({ date: periodStartAt }), diffUnit, true)
    );

    if (alertType === AlertType.GENERAL) {
      aggregatedAlerts[offset] += alertCount;
    } else if (alertType === AlertType.CRITICAL) {
      aggregatedCriticalAlerts[offset] += alertCount;
    }
  });

  return aggregatedCriticalAlerts.map((criticalAlerts, index) => {
    const alerts = aggregatedAlerts[index] + criticalAlerts;
    return alerts > 0 ? (criticalAlerts / alerts) * 100 : 0;
  });
};

export const generateGraphData = ({
  aggregatedCriticalRatio,
  periodEndAt,
  dateAggregation,
  count
}: {
  aggregatedCriticalRatio: number[];
  periodEndAt: string;
  dateAggregation: DateAggregation;
  count: number;
}) => {
  return aggregatedCriticalRatio.map((value, index) => ({
    ...(dateAggregation === DateAggregation.DAILY
      ? { day: generateGraphDates({ count, index, periodEndAt, dateAggregation }) }
      : { week: generateGraphDates({ count, index, periodEndAt, dateAggregation }) }),
    value
  }));
};

export const generateCSVData = ({
  aggregatedCriticalRatio,
  org,
  project,
  device,
  periodEndAt,
  count,
  dateAggregation
}: {
  aggregatedCriticalRatio: number[];
  org?: string;
  project?: string;
  device?: string;
  periodEndAt: string;
  count: number;
  dateAggregation: DateAggregation;
}) => {
  return aggregatedCriticalRatio.map((value, index) => ({
    ...(org ? { 'Organization name': org } : {}),
    ...(project ? { 'Project name': project } : {}),
    ...(device ? { 'Device name': device } : {}),
    ...generateCSVDates({ count, index, periodEndAt, dateAggregation }),
    'Critical Detection Ratio (%)': Math.round(value)
  }));
};

export const getCustomTooltip = ({ value }: BarTooltipProps<BarDatum>) => (
  <p>Critical Detection Ratio: {Math.round(value)}%</p>
);
