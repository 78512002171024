import { twMerge } from 'tailwind-merge';

import { Spinner } from '../../icons';

interface LoadingProps {
  className?: string;
  spinnerClassName?: string;
  loadingMessage?: string;
}

export const Loading: React.FC<LoadingProps> = ({
  className,
  spinnerClassName,
  loadingMessage
}) => (
  <div className={twMerge('flex h-full items-center justify-center', className)}>
    {loadingMessage && <span className='mr-2'>{loadingMessage}</span>}
    <Spinner className={spinnerClassName} />
  </div>
);
