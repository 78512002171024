import type { SWRMutationConfig } from './useApiHooks';
import { useMutationApi } from './useApiHooks';

interface DeleteProjectUserPathParams {
  projectId: string;
  userId: string;
}

export const useDeleteProjectUser = (
  { projectId, userId }: DeleteProjectUserPathParams,
  swrConfig?: SWRMutationConfig<never>
) => useMutationApi('delete', `/projects/${projectId}/users/${userId}`, undefined, swrConfig);
