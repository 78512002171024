import { twMerge } from 'tailwind-merge';

interface NoResultsProps {
  className?: string;
}

export const NoResults: React.FC<NoResultsProps> = ({ className }) => (
  <div className={twMerge('flex h-full items-center justify-center', className)}>
    <p>No Results</p>
  </div>
);
