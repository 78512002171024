import { useRouter } from 'next/router';
import type { ReactElement } from 'react';
import { useEffect } from 'react';

import { Loading } from '@fleet/components';

import { AuthWrapper } from '../components/atoms/AuthWrapper/AuthWrapper';
import { Routes } from '../const';
import { useAuthContext } from '../hooks';
import { AuthType } from '../hooks/api/useGetAuthContext';
import AppLayout from '../layouts/AppLayout';

export default function Index() {
  const { authType } = useAuthContext();
  const router = useRouter();

  useEffect(() => {
    let route = Routes.SUMMARY;
    if (authType === AuthType.LIMITED_ACCESS) {
      route = Routes.DETECTIONS;
    }

    const navigateToRoute = setTimeout(() => {
      const query = router.query;
      router.replace(
        {
          pathname: route,
          query: query
        },
        undefined,
        { shallow: true }
      );
    }, 1000);

    return () => clearTimeout(navigateToRoute);
  }, [router, authType]);

  return <Loading />;
}

Index.getLayout = function getLayout(page: ReactElement) {
  return (
    <AuthWrapper>
      <AppLayout>{page}</AppLayout>
    </AuthWrapper>
  );
};
