import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { DateRange } from 'react-day-picker';

import { DateOptions, ENABLED_FILTERS_PER_ROUTE, FilterLabels } from '../../../../const';
import { useGetCurrentRoute } from '../../../../hooks';
import { SelectedFiltersContext } from '../../../../providers';
import { displayDateFilterLabel, trackEvent } from '../../../../utils';
import type { SelectOption } from '../../../atoms';
import { Select } from '../../../atoms';
import { DatePicker } from '../../../molecules';

const dateOptions: SelectOption<DateOptions>[] = Object.values(DateOptions)
  .map(option => ({
    label: option,
    value: option
  }))
  .filter(option => option !== null) as SelectOption<DateOptions>[];

export const DateFilter: React.FC = () => {
  const { selectedFilters, setSelectedFilters } = useContext(SelectedFiltersContext);

  const currentRoute = useGetCurrentRoute();

  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<DateRange>();

  useEffect(() => {
    if (
      selectedFilters.date &&
      !Object.values(DateOptions).includes(selectedFilters.date as DateOptions)
    ) {
      const date = selectedFilters.date.split(' - ');
      const from = date[0] ? new Date(date[0]) : undefined;
      const to = date[1] ? new Date(date[1]) : undefined;
      setSelectedDate({ from, to });
    }
  }, [selectedFilters.date]);

  const closeDatePicker = useCallback(() => setDatePickerVisible(false), []);

  const handleDateSelect = useCallback((date: DateRange | undefined) => setSelectedDate(date), []);

  const handleDateChange = useCallback(
    (value: DateOptions) => {
      if (value === DateOptions.CUSTOM_RANGE) {
        return setDatePickerVisible(true);
      }

      trackEvent('date-filter-changed', { date: value });
      setSelectedFilters({ ...selectedFilters, date: value });
    },
    [selectedFilters, setSelectedFilters]
  );

  const handleDateConfirm = useCallback(() => {
    if (!selectedDate) return;
    const { from, to } = selectedDate;
    const dateFilter = to ? `${from?.toISOString()} - ${to?.toISOString()}` : from?.toISOString();

    setDatePickerVisible(false);
    trackEvent('date-filter-changed', { date: dateFilter });
    setSelectedFilters({ ...selectedFilters, date: dateFilter });
  }, [selectedDate, selectedFilters, setSelectedFilters]);

  const displayedValue = useMemo(() => {
    if (!selectedFilters.date) return 'Select date';

    return dateOptions.findIndex(option => option.label === selectedFilters.date) === -1
      ? displayDateFilterLabel(selectedFilters.date)
      : selectedFilters.date;
  }, [selectedFilters.date]);

  return (
    <>
      <Select<DateOptions>
        label='Date'
        options={dateOptions}
        onChange={handleDateChange}
        value={displayedValue ?? 'Select date'}
        disabled={!ENABLED_FILTERS_PER_ROUTE[currentRoute].includes(FilterLabels.DATE)}
      />
      <DatePicker
        open={datePickerVisible}
        onClose={closeDatePicker}
        selected={selectedDate}
        onSelect={handleDateSelect}
        onConfirm={handleDateConfirm}
      />
    </>
  );
};
