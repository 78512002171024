import { useRouter } from 'next/router';
import { Fragment, memo, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ExpandIconSVG, LayersIconSVG } from '@fleet/components';
import { Loading } from '@fleet/components';

import { DeviceSummarySection } from './DeviceSummarySection';
import { getLabelValueColorClass, SummaryGraph } from '../../molecules';
import {
  AlertType, FilterLabels, ReviewStatus,
  Routes, SHORT_DEFAULT_FILTERS,
  twoWeeksAgo,
  WEEK_IN_S
} from '../../../const';
import type { Project } from '../../../hooks';
import {
  AggregatedType,
  useGetAggregatedAlerts,
  useGetBlindsightIndex,
  useGetDevices,
  useOnClickOutside
} from '../../../hooks';
import type { QueryParams } from '../../../types';
import {
  getAggregatedAlerts,
  getAggregatedAlertsChange,
  getAggregatedCriticalAlertsChange,
  getAggregatedIndex,
  getAggregatedIndexChange,
  getAlertsChangeTooltipText,
  getCurrentAggregatedIndexAverage,
  getIndexChangeTooltipText,
  getPreviousAggregatedIndexAverage,
  getStoredTimezone
} from '../../../utils';

interface SummaryCardProps {
  project: Project;
}

export const ProjectSummaryCard: React.FC<SummaryCardProps> = memo(
  ({ project: { name: name, id: projectId, address, organizationId } }) => {
    const router = useRouter();

    const aggregatedParams = {
      organizationIds: organizationId,
      projectIds: projectId,
      startAt: twoWeeksAgo.toISOString(),
      reportingPeriodSecs: WEEK_IN_S * 2,
      timezone: getStoredTimezone()
    };

    const { data: devicesData, isLoading: devicesLoading } = useGetDevices({
      projectIds: projectId
    });

    const { data: alertsData, isLoading: alertsLoading } = useGetAggregatedAlerts({
      queryParams: {
        ...aggregatedParams,
        alertTypes: [AggregatedType.ANY]
      },
      variant: 'daily'
    });

    const { data: indexData, isLoading: indexLoading } = useGetBlindsightIndex({
      queryParams: aggregatedParams,
      variant: 'daily'
    });

    const [isOpen, setIsOpen] = useState(false);

    const accordionRef = useOnClickOutside<HTMLDivElement>(() => setIsOpen(false));
    const toggleAccordion = () => setIsOpen(currOpen => !currOpen);

    const deviceCount = devicesData && devicesData.results ? devicesData.results.length : 0;
    const maxHeight = isOpen && deviceCount > 0 ? `${11 * deviceCount + 3}rem` : '0';

    const cardStyle: React.CSSProperties = useMemo(() => ({ maxHeight }), [maxHeight]);

    const sortedDevices = useMemo(
      () =>
        devicesData?.results?.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        ),
      [devicesData]
    );

    const navigateToProjectDetections = () => {
      router.push({
        pathname: Routes.DETECTIONS,
        query: {
          ...SHORT_DEFAULT_FILTERS,
          [FilterLabels.REVIEW_STATUS]: [ReviewStatus.CONFIRMED, ReviewStatus.NOT_REVIEWED].join(','),
          projectIds: projectId,
        } satisfies QueryParams
      });
    };

    const navigateToProjectAnalytics = () => {
      router.push({
        pathname: Routes.ANALYTICS,
        query: {
          [FilterLabels.REVIEW_STATUS]: [ReviewStatus.CONFIRMED, ReviewStatus.NOT_REVIEWED].join(','),
          projectIds: projectId
        } satisfies QueryParams
      });
    };

    const aggregatedIndex = useMemo(() => getAggregatedIndex(indexData), [indexData]);
    const prevIndexAverage = useMemo(
      () => getPreviousAggregatedIndexAverage(aggregatedIndex),
      [aggregatedIndex]
    );
    const currIndexAverage = useMemo(
      () => getCurrentAggregatedIndexAverage(aggregatedIndex),
      [aggregatedIndex]
    );
    const aggregatedIndexChange = getAggregatedIndexChange(prevIndexAverage, currIndexAverage);

    const currAggregatedIndex = aggregatedIndex
      .slice(7, 14)
      .map(value => (value === null ? 0 : value));

    const { aggregatedAlerts, aggregatedCriticalAlerts } = useMemo(
      () => getAggregatedAlerts(alertsData),
      [alertsData]
    );
    const aggregatedAlertsChange = useMemo(
      () => getAggregatedAlertsChange(aggregatedAlerts),
      [aggregatedAlerts]
    );
    const aggregatedCriticalAlertsChange = useMemo(
      () => getAggregatedCriticalAlertsChange(aggregatedCriticalAlerts),
      [aggregatedCriticalAlerts]
    );

    const currAggregatedAlerts = aggregatedAlerts.slice(7, 14);
    const currAggregatedCriticalAlerts = aggregatedCriticalAlerts.slice(7, 14);

    const aggregatedAlertsSum = currAggregatedAlerts.reduce((a, b) => a + b, 0);
    const aggregatedCriticalAlertsSum = currAggregatedCriticalAlerts.reduce((a, b) => a + b, 0);

    const labelValueColorClass = getLabelValueColorClass(currIndexAverage);

    return (
      <div className='rounded-3xl border bg-white pl-6 text-base shadow-sm' ref={accordionRef}>
        {(() => {
          if (devicesLoading || alertsLoading || indexLoading) return <Loading className='my-6' />;

          return (
            <>
              <div className='flex h-44 w-full flex-row items-center'>
                {deviceCount > 0 ? (
                  <button
                    onClick={toggleAccordion}
                    className='mr-6 flex items-center space-x-2'
                    id='project-summary-card'
                    data-pw={`button-project-card-expand-${name}`}
                  >
                    <ExpandIconSVG
                      className={twMerge(
                        'transition-transform duration-300',
                        isOpen && 'rotate-90'
                      )}
                    />
                    <LayersIconSVG className='flex-[0_0_2.75rem]' />
                  </button>
                ) : (
                  <LayersIconSVG className='ml-5 mr-6 flex-[0_0_2.75rem]' />
                )}
                <div className='flex flex-grow items-center justify-between pr-6'>
                  <div className='flex w-60 flex-col space-y-2'>
                    <p className='truncate font-medium' data-pw='label-project-card-name'>
                      {name}
                    </p>
                    <p className='text-sm text-secondary-text' data-pw='label-project-card-address'>
                      {address?.trim() || 'No address'}
                    </p>
                    <div className='flex space-x-3 text-sm text-hyperlink'>
                      <button
                        onClick={navigateToProjectDetections}
                        id='project-summary-detections'
                        data-pw={`button-project-card-detections-${name}`}
                      >
                        Detections {'>'}
                      </button>
                      <button
                        onClick={navigateToProjectAnalytics}
                        id='project-summary-analytics'
                        data-pw={`button-project-card-summary-analytics-${name}`}
                      >
                        Detailed Analytics {'>'}
                      </button>
                    </div>
                  </div>
                  <div className='flex flex-grow items-center justify-around'>
                    <SummaryGraph
                      aggregatedData={currAggregatedIndex}
                      labelValue={currIndexAverage}
                      label='Blindsight Index'
                      percentChange={aggregatedIndexChange}
                      changeTooltipText={getIndexChangeTooltipText(
                        aggregatedIndex,
                        aggregatedIndexChange
                      )}
                      labelValueColorClass={labelValueColorClass}
                      // className={indexData?.results.length === 0 ? 'opacity-0' : undefined}
                      dataPw='label-project-card-blindsight-index'
                    />
                    <SummaryGraph
                      aggregatedData={currAggregatedCriticalAlerts}
                      labelValue={aggregatedCriticalAlertsSum}
                      label={`Critical detection${aggregatedCriticalAlertsSum === 1 ? '' : 's'}`}
                      percentChange={aggregatedCriticalAlertsChange}
                      changeTooltipText={getAlertsChangeTooltipText(
                        aggregatedCriticalAlerts,
                        aggregatedCriticalAlertsChange
                      )}
                      labelValueColorClass={labelValueColorClass}
                      dataPw='label-project-card-critical-detections'
                      projectId={projectId}
                      isLink={true}
                      query={{ alertType: AlertType.CRITICAL }}
                    />
                    <SummaryGraph
                      aggregatedData={currAggregatedAlerts}
                      labelValue={aggregatedAlertsSum}
                      label={`General Detection${aggregatedAlertsSum === 1 ? '' : 's'}`}
                      percentChange={aggregatedAlertsChange}
                      changeTooltipText={getAlertsChangeTooltipText(
                        aggregatedAlerts,
                        aggregatedAlertsChange
                      )}
                      labelValueColorClass={labelValueColorClass}
                      dataPw='label-project-card-general-detections'
                      projectId={projectId}
                      isLink={true}
                      query={{ alertType: AlertType.GENERAL }}
                    />
                  </div>
                  <div className='text-center text-tertiary-text'>
                    <p className='text-xl font-medium'>{devicesData?.results?.length ?? 'N/A'}</p>
                    <p className='text-xs'>Blindsight</p>
                    <p className='text-xs'>device{devicesData?.results?.length === 1 ? '' : 's'}</p>
                  </div>
                </div>
              </div>
              {sortedDevices ? (
                <div
                  style={cardStyle}
                  className={twMerge(
                    'ml-[5.5rem] overflow-hidden transition-[max-height] duration-500'
                  )}
                >
                  <hr
                    className={twMerge(
                      'h-0.5 bg-gray-200 transition-opacity duration-500',
                      isOpen ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                  {sortedDevices.map((device, index) => (
                    <Fragment key={device.id}>
                      <DeviceSummarySection device={device} />
                      {index !== sortedDevices.length - 1 && (
                        <hr className='ml-[4.25rem] h-0.5 bg-gray-200' />
                      )}
                    </Fragment>
                  ))}
                </div>
              ) : null}
            </>
          );
        })()}
      </div>
    );
  }
);
