type SortableObject = { [key: string]: any };

/**
 * Sorts an array of objects based on a given property.
 * @param {Array} array - The array to sort.
 * @param {string} key - The property to sort by.
 * @param {boolean} ascending - Whether to sort in ascending order. Defaults to true.
 * @returns {Array} - The sorted array.
 */
export const sortByKey = <T extends SortableObject>(
  array: T[],
  key: keyof T,
  ascending: boolean = true
): T[] => {
  return array.sort((a, b) => {
    if (a[key] < b[key]) return ascending ? -1 : 1;
    if (a[key] > b[key]) return ascending ? 1 : -1;
    return 0;
  });
};
