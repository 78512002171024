import { useApi } from './useApiHooks';
import type { OrganizationUser } from './useGetOrganizations';

export interface GetOrganizationUsersPathParams {
  organizationId?: string;
}

export interface GetOrganizationUsersResponse {
  results: OrganizationUser[];
}

export const useGetOrganizationUsers = (
  { organizationId }: GetOrganizationUsersPathParams,
  disableQuery = false
) =>
  useApi<GetOrganizationUsersResponse>(
    'get',
    disableQuery ? null : `/organizations/${organizationId}/users`
  );
