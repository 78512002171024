import type { SWRMutationConfig } from './useApiHooks';
import { useMutationApi } from './useApiHooks';
import type { OrganizationUserRole } from '../../const';

export interface ProjectRole {
  projectId: string;
  role: string;
}

export interface AddUserRequest {
  email: string;
  role: OrganizationUserRole;
  projectsRoles?: ProjectRole[];
}

interface AddUserPathParams {
  organizationId?: string;
}

export const useAddUser = (
  { organizationId }: AddUserPathParams,
  swrConfig?: SWRMutationConfig<never>
) =>
  useMutationApi<AddUserRequest>(
    'post',
    `/organizations/${organizationId}/users/v2`,
    undefined,
    swrConfig
  );
