import { useCallback, useContext, useEffect, useMemo } from 'react';

import { MultiSelect } from '@fleet/components';

import { ENABLED_FILTERS_PER_ROUTE, FilterLabels } from '../../../../const';
import { useAuthContext, useGetCurrentRoute, useGetDevices } from '../../../../hooks';
import { SelectedFiltersContext } from '../../../../providers';
import { trackEvent } from '../../../../utils';
import type { SelectOption } from '../../../atoms';

export const DeviceFilters: React.FC = () => {
  const { currentOrganizationId } = useAuthContext();
  const { selectedFilters, setSelectedFilters } = useContext(SelectedFiltersContext);
  const currentRoute = useGetCurrentRoute();

  const { data: devices } = useGetDevices({
    organizationIds: currentOrganizationId,
    projectIds: selectedFilters.projectIds ?? undefined,
  });

  const deviceOptions: SelectOption[] = useMemo(() => {
    const createCustomLabel = (name: string, project: { name?: string }) => (
      `<div>${name ?? 'Unknown Device'}</div>
       <div class="text-gray-400">${project.name ?? 'Unknown Project'}</div>`
    );

    if (!devices?.results || devices.results.length === 0) {
      return [{ label: 'No Devices', value: undefined }];
    }

    if (devices.results.length === 1) {
      const { name, id, project } = devices.results[0];
      return [{
        label: name ?? 'Unknown Device',
        value: id,
        customLabel: createCustomLabel(name, project)
      }];
    }

    const sortedDevices = devices.results.sort((a, b) => {
      const projectComparison = (a.project.name ?? '').localeCompare(b.project.name ?? '');
      if (projectComparison !== 0) return projectComparison;
      return (a.name ?? '').localeCompare(b.name ?? '');
    });

    return [
      { label: 'All', value: 'All' },
      ...sortedDevices.map(({ name, id, project }) => ({
        customLabel: createCustomLabel(name, project),
        label: name ?? 'Unknown Device',
        value: id,
      })),
    ];

  }, [devices?.results]);

  const handleDeviceChange = useCallback(
    (value: string) => {
      trackEvent('device-filter-changed', {});
      setSelectedFilters({
        ...selectedFilters,
        [FilterLabels.DEVICE]: value.includes('All') ? undefined : value,
      });
    },
    [selectedFilters, setSelectedFilters]
  );

  useEffect(() => {
    if (devices?.results) {
      const selectedDeviceIds = selectedFilters.deviceIds?.split(',') || [];
      const validDeviceIds = selectedDeviceIds.filter(id => devices?.results?.some(device => device.id === id));
      if (validDeviceIds.length !== selectedDeviceIds.length) {
        setSelectedFilters({
          ...selectedFilters,
          [FilterLabels.DEVICE]: validDeviceIds.join(',') || undefined,
        });
      }
    }
  }, [devices?.results, selectedFilters, setSelectedFilters]);

  const filterDisabled =
    !ENABLED_FILTERS_PER_ROUTE[currentRoute].includes(FilterLabels.PROJECT) ||
    !devices?.results ||
    devices.results.length < 2;

  return (
    <MultiSelect
      label='Device'
      options={deviceOptions}
      onChange={handleDeviceChange}
      value={selectedFilters.deviceIds || ''}
      disabled={filterDisabled}
      dataPw='button-filter-device'
    />
  );
};