import { useCallback, useState } from 'react';

import { Button, Input } from '@fleet/components';

import { Modal } from '../../atoms';

interface RejectDetectionModalProps {
  isOpen: boolean;
  isMutating: boolean;
  onClose: () => void;
  onSubmit: (reason: string) => Promise<void> | undefined;
}

export const RejectDetectionModal: React.FC<RejectDetectionModalProps> = ({
  isOpen,
  isMutating,
  onClose,
  onSubmit
}) => {
  const [rejectReason, setRejectReason] = useState<string>('');

  const onRejectReasonChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setRejectReason(e.target.value);
  }, []);

  const handleRejectModalClose = useCallback(() => {
    setTimeout(() => setRejectReason(''), 200);
    onClose();
  }, [onClose]);

  const handleRejectSubmit = useCallback(() => {
    onSubmit(rejectReason)?.finally(() => setRejectReason(''));
  }, [onSubmit, rejectReason]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleRejectModalClose}
      header='Reason for rejecting'
      className='mt-20'
    >
      <Input
        type='text'
        onChange={onRejectReasonChange}
        value={rejectReason}
        placeholder='Reason for rejecting'
        className='w-full'
        dataPw='input-detection-modal-reject-modal'
      />
      <div className='mt-4 flex justify-end space-x-2'>
        <Button
          className='w-fit self-end border-0 bg-primary-element py-3 text-base'
          onClick={handleRejectModalClose}
          disabled={isMutating}
        >
          Cancel
        </Button>
        <Button
          className='w-fit border-0 bg-primary-element-medium-dark py-3 text-base text-gray-200 transition-opacity hover:opacity-80 active:opacity-60'
          onClick={handleRejectSubmit}
          loading={isMutating}
          // Disable button if rejection reason is not atleast 8 non-space characters
          disabled={rejectReason.replaceAll(' ', '').length < 8}
          dataPw='button-detection-modal-reject-modal-submit'
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};
