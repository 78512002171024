import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { PlayIcon } from '@heroicons/react/24/solid';
import type { AxiosError } from 'axios';
import { type Map } from 'leaflet';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Button, Error } from '@fleet/components';

import { Markers } from '../../atoms';
import type { Alert, ApiError } from '../../../hooks';
import {
  useGetMapContainer,
  useGetReactLeafletGoogleLayer,
  useRetrieveFilters
} from '../../../hooks';
import type { MapState } from '../../../types';
import { getFromEnvConfig } from '../../../utils';

const getStylesFromState = (state: MapState) => {
  switch (state) {
    case 'minimized':
      return 'w-[4.5rem] brightness-[0.25]';
    case 'maximized':
      return 'w-full';
    default:
      return 'w-1/2';
  }
};

interface DetectionsMapProps {
  geoAlerts?: Alert[];
  minimizeMap?: () => void;
  maximizeMap?: () => void;
  onMapInteraction?: (bounds: string) => void;
  mapState: MapState;
  loading?: boolean;
  error?: AxiosError<ApiError, unknown>;
}

export const DetectionsMap: React.FC<DetectionsMapProps> = memo(
  ({ geoAlerts, minimizeMap, maximizeMap, onMapInteraction, mapState, loading, error }) => {
    const [tilesType, setTilesType] = useState<'roadmap' | 'satellite'>('satellite');
    const [mapRef, setMapRef] = useState<Map | null>(null);
    const [geoKeyUpdater, setGeoKeyUpdater] = useState(0);

    const filters = useRetrieveFilters();
    const { geolocationBounds, ...rest } = filters ?? {};

    const MapContainer = useGetMapContainer();
    const ReactLeafletGoogleLayer = useGetReactLeafletGoogleLayer();

    const styles = useMemo(() => getStylesFromState(mapState), [mapState]);

    const setRoadmapTiles = useCallback(() => setTilesType('roadmap'), []);
    const setSatelliteTiles = useCallback(() => setTilesType('satellite'), []);

    const zoomInMap = useCallback(() => mapRef?.zoomIn(), [mapRef]);
    const zoomOutMap = useCallback(() => mapRef?.zoomOut(), [mapRef]);

    useEffect(() => {
      if (mapRef === null) return;

      const handleMapInteraction = () => {
        if (mapState !== 'default') return;

        const { lat: nwLat, lng: nwLng } = mapRef.getBounds().getNorthWest();
        const { lat: seLat, lng: seLng } = mapRef.getBounds().getSouthEast();

        if (geolocationBounds === `${nwLat},${nwLng},${seLat},${seLng}`) return;

        onMapInteraction?.(`${nwLat},${nwLng},${seLat},${seLng}`);
      };

      const mapShiftInterval = setInterval(() => mapRef.panBy([0, 0], { animate: false }), 1000);

      mapRef.addOneTimeEventListener('mousedown', () => clearInterval(mapShiftInterval));

      mapRef.on('mouseup', handleMapInteraction);
      setTimeout(() => mapRef.on('zoomend', handleMapInteraction), 1000);

      return () => {
        mapRef.off('mouseup', handleMapInteraction);
        mapRef.off('zoomend', handleMapInteraction);
        clearInterval(mapShiftInterval);
      };
    }, [mapRef, mapState, onMapInteraction, geolocationBounds]);

    useEffect(() => {
      if (!geolocationBounds) setGeoKeyUpdater(prev => prev + 1);
    }, [geolocationBounds]);

    const bounds: [number, number][] = useMemo(() => {
      if (!geoAlerts || geoAlerts.length === 0)
        return [
          [-33.865_143, 151.2093],
          [-33.875_143, 151.2193]
        ];
      if (geolocationBounds) {
        const [nwLat, nwLng, seLat, seLng] = geolocationBounds.split(',').map(Number);
        return [
          [nwLat, nwLng],
          [seLat, seLng]
        ];
      }
      return geoAlerts
        .filter(alert => alert.geolocationLatitude !== null && alert.geolocationLongitude !== null)
        .map(alert => [alert.geolocationLatitude as number, alert.geolocationLongitude as number]);
    }, [geoAlerts, geolocationBounds]);

    if (!MapContainer || !ReactLeafletGoogleLayer || !bounds) return null;

    const overlayStyles = twMerge(
      'absolute z-20 flex h-full w-full items-center justify-center bg-black/75 text-white text-center text-4xl backdrop-blur-sm',
      mapState === 'minimized' && 'hidden'
    );
    const noLocationData = geoAlerts?.length === 0;

    return (
      <div
        className={twMerge('relative', styles)}
        onClick={mapState === 'minimized' ? maximizeMap : undefined}
      >
        {(() => {
          if (loading || !filters) return null;
          if (error)
            return <Error error={error.response?.data.message} className={overlayStyles} />;
          if (noLocationData) return <div className={overlayStyles}>No Location Data</div>;
        })()}
        <MapContainer
          className={twMerge('z-0', mapState === 'minimized' && 'pointer-events-none')}
          zoomControl={false}
          ref={setMapRef}
          key={`${geoKeyUpdater} ${mapState} ${JSON.stringify(rest)} ${loading}`}
          bounds={bounds}
        >
          <ReactLeafletGoogleLayer
            key={tilesType}
            apiKey={getFromEnvConfig('GOOGLE_MAPS_API_KEY')}
            type={tilesType}
            maxZoom={22}
            maxNativeZoom={18}
          />
          {mapState === 'minimized' ? null : <Markers alerts={geoAlerts} />}
        </MapContainer>
        <div className='absolute left-4 top-4 z-20 flex space-x-2'>
          {mapState === 'maximized' ? null : (
            <Button className='h-8 w-8 rounded-md p-2' onClick={maximizeMap} id='maximize-map'>
              <PlayIcon className='h-3 w-3 -rotate-180' />
            </Button>
          )}
          {mapState === 'minimized' ? null : (
            <Button className='h-8 w-8 rounded-md p-2' onClick={minimizeMap} id='minimize-map'>
              <PlayIcon className='h-3 w-3' />
            </Button>
          )}
        </div>
        {mapState === 'minimized' ? null : (
          <>
            <div className='absolute right-4 top-4 z-10 flex rounded-md bg-white'>
              <Button
                className={twMerge(
                  'rounded-l-md rounded-r-none border-r-0 px-2 py-1',
                  tilesType === 'roadmap' && 'bg-brand/50'
                )}
                onClick={setRoadmapTiles}
                id='roadmap-tiles'
              >
                Map
              </Button>
              <Button
                className={twMerge(
                  'rounded-l-none rounded-r-md border-l-0 px-2 py-1',
                  tilesType === 'satellite' && 'bg-brand/50'
                )}
                onClick={setSatelliteTiles}
                id='satellite-tiles'
              >
                Satellite
              </Button>
            </div>
            <div className='absolute right-4 top-14 z-10 space-y-2'>
              <Button className='rounded-md p-2' onClick={zoomInMap} id='zoom-in-map'>
                <PlusIcon className='h-4 w-4' strokeWidth={3} />
              </Button>
              <Button className='rounded-md p-2' onClick={zoomOutMap} id='zoom-out-map'>
                <MinusIcon className='h-4 w-4' strokeWidth={3} />
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
);
