import type { SWRMutationConfig } from './useApiHooks';
import { useMutationApi } from './useApiHooks';

interface DeleteOrganizationUserPathParams {
  organizationId?: string;
  userId?: string;
}

export const useDeleteOrganizationUser = (
  { organizationId, userId }: DeleteOrganizationUserPathParams = {},
  swrConfig?: SWRMutationConfig<never>
) =>
  useMutationApi(
    'delete',
    `/organizations/${organizationId}/users/${userId}`,
    undefined,
    swrConfig
  );
