import { useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface TooltipProps {
  children: React.ReactNode;
  title: React.ReactNode | string;
  className?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ children, title, className }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const onMouseEnter = useCallback((e: React.MouseEvent) => {
    setTooltipOpen(true);
    const boundingRect = (e.target as HTMLElement).getBoundingClientRect();
    setTooltipPosition({
      x: boundingRect.left + window.scrollX,
      y: boundingRect.top + window.scrollY
    });
  }, []);

  const onMouseLeave = useCallback(() => {
    setTooltipOpen(false);
    setTooltipPosition({ x: 0, y: 0 });
  }, []);

  const onMouseMove = useCallback((e: React.MouseEvent) => {
    setTooltipPosition({
      x: e.clientX + window.scrollX,
      y: e.clientY + window.scrollY
    });
  }, []);

  return (
    <>
      <div
        className='w-fit'
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
      >
        {title}
      </div>
      {tooltipOpen && (
        <div
          className={twMerge(
            'pointer-events-none absolute z-50 rounded-lg border border-gray-300 bg-white px-4 py-2 text-sm font-medium shadow-xl',
            className
          )}
          style={{ top: tooltipPosition.y + 5, left: tooltipPosition.x + 5 }}
        >
          {children}
        </div>
      )}
    </>
  );
};
