import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import type { SelectOption } from '@fleet/components';
import { Button, Select } from '@fleet/components';
import { snakeToSentenceCase } from '@fleet/utils';

import { Modal } from '../../atoms';
import { OrganizationUserRole, ProjectUserRole } from '../../../const';
import { type ProjectUser, useAuthContext } from '../../../hooks';
import { useUpdateProjectUser } from '../../../hooks/api/useUpdateProjectUser';
import type { AxiosErrorResponseBody } from '../../../utils';
import { getUserFullName, projectUserRoleOptions } from '../../../utils/User';

interface EditProjectUserModalProps {
  projectUser?: ProjectUser;
  isOpen: boolean;
  onClose: () => void;
}

const roleOptions: SelectOption<OrganizationUserRole>[] = Object.values(OrganizationUserRole)
  .map(option => ({
    label: snakeToSentenceCase(option),
    value: option
  }))
  .filter(option => option !== null) as SelectOption<OrganizationUserRole>[];

export const EditProjectUserModal: React.FC<EditProjectUserModalProps> = ({
  projectUser,
  isOpen,
  onClose
}) => {
  const { refetchAllQueries } = useAuthContext();
  const [selectedRole, setSelectedRole] = useState<ProjectUserRole>(ProjectUserRole.REVIEWER);
  const { trigger: updateProjectUser } = useUpdateProjectUser({
    projectId: projectUser?.projectId || '',
    userId: projectUser?.userId || ''
  });

  const onSubmit = useCallback(async () => {
    try {
      await updateProjectUser({ role: selectedRole });
      refetchAllQueries();
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error((error.response?.data as AxiosErrorResponseBody).message);
      }
      return;
    }
    toast.success('User updated successfully');
    onClose();
  }, [onClose, refetchAllQueries, selectedRole, updateProjectUser]);

  useEffect(() => {
    if (projectUser) setSelectedRole(projectUser.role);
  }, [projectUser]);

  return (
    <Modal title='Edit Project User' isOpen={isOpen} onClose={onClose}>
      <div className='space-y-4 [&_input]:w-full'>
        {/* <ControlledInput control={control} name='name' label='Name' disabled />
        <ControlledInput control={control} name='email' label='Email' disabled />
        <ControlledInput control={control} name='phone' label='Phone number' disabled /> */}

        <div>
          <span className='pr-2 font-bold'>Name:</span>
          <span>{getUserFullName(projectUser?.user) || 'No name'}</span>
        </div>
        <div>
          <span className='pr-2 font-bold'>Email:</span>
          <span>{projectUser?.user.email}</span>
        </div>
        <div>
          <span className='font-bold'>Phone number: </span>
          <span>{projectUser?.user.phoneNumber || 'None'}</span>
        </div>

        {/* Project permissions */}
        <Select
          label='Project Role'
          options={projectUserRoleOptions}
          value={selectedRole || ''}
          onChange={setSelectedRole}
        />
        <div className='flex justify-between gap-4 pt-3'>
          <Button variant='mono' className='h-14 w-1/2 justify-center text-lg' onClick={onClose}>
            Cancel
          </Button>
          <Button
            type='submit'
            variant='solid'
            className='h-14 w-1/2 justify-center text-lg'
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
