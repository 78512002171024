import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';

import type { SelectOption } from '@fleet/components';
import { DisplayOptDir, DisplayType, SearchableSelect } from '@fleet/components';
import { Button } from '@fleet/components';
import { Loading } from '@fleet/components';

import { OrganizationUserRole } from '../../../const';
import { useAuthContext } from '../../../hooks';
import { useGetProjects } from '../../../hooks';
import { useMoveDevices } from '../../../hooks/api/useMoveDevices';

type MoveDevicesPanel = {
  devices: string[];
  closeHandler: () => void;
  setRefreshDevices: (value: boolean) => void;
  setSelectedRows: (value: []) => void;
  isVisible: boolean;
};

export const MoveDevicesPanel: React.FC<MoveDevicesPanel> = ({
  devices,
  closeHandler,
  setRefreshDevices,
  setSelectedRows,
  isVisible
}) => {
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [selectedOrganization, setSelectedOrganization] = useState<string>('');
  const { trigger: moveDevices, isMutating } = useMoveDevices();
  const { roles, allOrganizations } = useAuthContext();

  const { data, isLoading } = useGetProjects({
    organizationIds: selectedOrganization || undefined
  });

  const organizationOptions = useMemo(() => {
    if (!roles || !allOrganizations) return [];

    const AdminOrganizations = new Set(
      Object.keys(roles?.organizations || {}).filter(
        org => roles?.organizations[org] === OrganizationUserRole.ADMIN
      )
    );
    return allOrganizations
      .filter(organization => AdminOrganizations.has(organization.id))
      .map(organization => ({ label: organization.name, value: organization.id }));
  }, [roles, allOrganizations]);

  const shouldDisplayLoading = useMemo(() => isMutating, [isMutating]);

  const onSave = useCallback(async () => {
    try {
      await moveDevices({
        organizationId: selectedOrganization || '',
        projectId: selectedProject,
        devicesIds: devices
      });
      toast.success('Devices moved successfully.');
      closeHandler();
      setRefreshDevices(true);
      setSelectedRows([]);
    } catch {
      toast.error('Failed to move devices.');
    }
  }, [
    moveDevices,
    closeHandler,
    selectedProject,
    devices,
    setRefreshDevices,
    setSelectedRows,
    selectedOrganization
  ]);

  const options: SelectOption[] = useMemo(() => {
    if (!selectedOrganization) return [];
    const optionData = data?.results || [];
    return optionData.map(row => ({ label: row.name, value: row.id }));
  }, [data, selectedOrganization]);

  useEffect(() => {
    if (isVisible) {
      setSelectedProject('');
      setSelectedOrganization('');
    }
  }, [isVisible]);

  const handleOrganizationChange = useCallback((organizationId: string) => {
    setSelectedOrganization(organizationId);
    setSelectedProject('');
  }, []);

  const saveButtonDisabled = useMemo(() => {
    return selectedProject === '' || selectedOrganization === '' || devices.length === 0;
  }, [selectedProject, selectedOrganization, devices]);

  return (
    <div
      className={twMerge(
        'fixed bottom-10 flex h-24 w-[972px] transform items-center rounded-lg bg-white py-4 opacity-100 shadow-floating-panel transition-transform duration-300',
        shouldDisplayLoading ? 'justify-center' : 'justify-between',
        isVisible ? 'translate-y-0' : 'translate-y-40'
      )}
    >
      {shouldDisplayLoading ? (
        <Loading />
      ) : (
        <>
          <div className='flex h-9 items-center border-r-2 px-6'>
            <div className='w-[14px] text-end'>{devices.length}</div>
            <div className='ml-2'>Devices selected</div>
          </div>
          <div className='flex h-9 w-[601px] items-center'>
            <div className='w-[350px] font-medium leading-4 text-gray-500'>Move Devices to</div>
            <SearchableSelect
              displayType={DisplayType.inline}
              displayOptDir={DisplayOptDir.top}
              placeholder='Select Organization'
              options={organizationOptions}
              value={selectedOrganization}
              onChange={handleOrganizationChange}
              className='mr-3'
            />
            <SearchableSelect
              displayType={DisplayType.inline}
              displayOptDir={DisplayOptDir.top}
              placeholder={isLoading ? 'Loading options...' : 'Select Project'}
              options={options}
              value={selectedProject}
              onChange={setSelectedProject}
              noOptionsMessage='No projects available'
            />
          </div>
          <div className='flex h-9 items-center'>
            <Button
              disabled={saveButtonDisabled}
              className='w-[82px] justify-center'
              variant='solid'
              onClick={onSave}
            >
              Save
            </Button>
          </div>
          <div
            className='flex h-9 cursor-pointer items-center pr-7 font-bold'
            onClick={closeHandler}
          >
            X
          </div>
        </>
      )}
    </div>
  );
};
