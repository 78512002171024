import type { ProjectRole } from './useAddUser';
import type { SWRMutationConfig } from './useApiHooks';
import { useMutationApi } from './useApiHooks';
import type { OrganizationUserRole } from '../../const';

interface UpdateOrganizationUserRequest {
  role: OrganizationUserRole;
  projectsRoles?: ProjectRole[];
}

interface UpdateOrganizationUserPathParams {
  organizationId: string;
  userId: string;
}

export const useUpdateOrganizationUser = (
  { organizationId, userId }: UpdateOrganizationUserPathParams,
  swrConfig?: SWRMutationConfig<never>
) =>
  useMutationApi<UpdateOrganizationUserRequest>(
    'patch',
    `/organizations/${organizationId}/users/${userId}/v2`,
    undefined,
    swrConfig
  );
