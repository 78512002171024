import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  getInsightActiveText,
  getInsightAddress,
  getInsightAlertFilters,
  getInsightDetails,
  getInsightSeverityValues,
  getInsightSummary
} from './InsightCard.utils';
import { DetectionModal } from '../../molecules';
import { dayJS, RiskState, Routes } from '../../../const';
import type { Alert, Insight } from '../../../hooks';
import { useGetAlerts, useGetProjects } from '../../../hooks';
import type { QueryParams } from '../../../types';

interface InsightCardProps {
  insight: Insight;
}

export const InsightCard: React.FC<InsightCardProps> = ({ insight }) => {
  const [alertsRequested, setAlertsRequested] = useState(false);
  const [alertsToReview, setAlertsToReview] = useState<Alert[]>();
  const [isOpen, setIsOpen] = useState(false);

  const router = useRouter();
  const { query } = router as { query: QueryParams };

  const isReviewInsight = insight.insightClass === 'INSIGHT_ALERT_REVIEW';

  const insightAlertFilters = useMemo(() => getInsightAlertFilters(insight), [insight]);

  const { data: projectsData } = useGetProjects({});

  const {
    data: extremeRiskAlerts,
    isLoading: extremeRiskLoading,
    mutate: mutateExtremeAlerts
  } = useGetAlerts(
    {
      ...insightAlertFilters,
      riskState: RiskState.EXTREME_RISK
    },
    !isReviewInsight
  );

  const {
    data: highAndAboveRiskAlerts,
    isLoading: veryHighLoading,
    mutate: mutateHighAndAboveAlerts
  } = useGetAlerts(
    {
      ...insightAlertFilters,
      riskState: RiskState.HIGH_RISK
    },
    !isReviewInsight
  );

  const {
    data: remainingAlerts,
    isLoading: remainingLoading,
    mutate: mutateRemainingAlerts
  } = useGetAlerts(insightAlertFilters, !isReviewInsight);

  const alertsLoading = extremeRiskLoading || veryHighLoading || remainingLoading;

  const processedExtremeRiskAlerts = useMemo(
    () => extremeRiskAlerts?.flatMap(({ results }) => results),
    [extremeRiskAlerts]
  );
  const processedVeryHighRiskAlerts = useMemo(
    () => highAndAboveRiskAlerts?.flatMap(({ results }) => results),
    [highAndAboveRiskAlerts]
  );
  const processedRemainingAlerts = useMemo(
    () =>
      remainingAlerts
        ?.flatMap(({ results }) => results)
        .filter(
          ({ riskState }) =>
            riskState !== RiskState.EXTREME_RISK && riskState !== RiskState.HIGH_RISK
        ),
    [remainingAlerts]
  );

  /**
   * UseEffect to generate the alerts to review for the current insight. The details for
   * the algorithm used to generate the alerts to review can be found here:
   * https://pfprobotics.atlassian.net/browse/BSIGHT-794
   */
  useEffect(() => {
    if (alertsLoading || !alertsRequested) return;

    const tempAlerts = new Array<Alert>();
    let currentDiff = 48;

    while (tempAlerts.length < 50 && currentDiff < 48 * 7) {
      if (processedExtremeRiskAlerts) {
        for (const alert of processedExtremeRiskAlerts) {
          if (tempAlerts.length === 50 || dayJS().diff(alert.occurredAt, 'hours') > currentDiff)
            continue;
          if (tempAlerts.some(({ alertId: id }) => id === alert.alertId)) continue;
          tempAlerts.push(alert);
        }
      }

      if (processedVeryHighRiskAlerts) {
        for (const alert of processedVeryHighRiskAlerts) {
          if (tempAlerts.length === 50 || dayJS().diff(alert.occurredAt, 'hours') > currentDiff)
            continue;
          if (tempAlerts.some(({ alertId: id }) => id === alert.alertId)) continue;
          tempAlerts.push(alert);
        }
      }

      if (processedRemainingAlerts) {
        for (const alert of processedRemainingAlerts) {
          if (tempAlerts.length === 50 || dayJS().diff(alert.occurredAt, 'hours') > currentDiff)
            continue;
          if (tempAlerts.some(({ alertId: id }) => id === alert.alertId)) continue;
          tempAlerts.push(alert);
        }
      }

      currentDiff += 48;
    }

    setAlertsToReview(tempAlerts);
  }, [
    alertsLoading,
    alertsRequested,
    processedExtremeRiskAlerts,
    processedRemainingAlerts,
    processedVeryHighRiskAlerts
  ]);

  const { backgroundColor, label } = getInsightSeverityValues(insight);

  const reviewAlerts = useCallback(() => setAlertsRequested(true), []);

  useEffect(() => {
    if (!router.isReady) return;

    if (query?.insightId === insight.insightId && query?.alertId) reviewAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  useEffect(() => {
    if (!alertsToReview || !alertsRequested) return;

    setAlertsRequested(false);

    if (alertsToReview.length === 0) return;

    router.replace({
      query: {
        ...query,
        alertId: alertsToReview[0].alertId,
        insightId: insight.insightId
      } satisfies QueryParams
    });
  }, [alertsToReview, alertsRequested, router, query, insight.insightId]);

  const onClose = useCallback(() => {
    mutateExtremeAlerts();
    mutateHighAndAboveAlerts();
    mutateRemainingAlerts();
    setIsOpen(false);

    setTimeout(() => {
      const newQuery = { ...query };
      delete newQuery.alertId;
      delete newQuery.insightId;
      router.replace({ query: newQuery satisfies QueryParams });
    }, 200);
  }, [mutateExtremeAlerts, mutateHighAndAboveAlerts, mutateRemainingAlerts, query, router]);

  useEffect(() => {
    if (query?.insightId === insight.insightId && !alertsLoading) setIsOpen(true);
  }, [query?.insightId, insight.insightId, alertsLoading]);

  const navigateToDetections = () => {
    router.push({
      pathname: Routes.DETECTIONS,
      query: {
        ...query,
        ...insightAlertFilters,
        date: insight.startedAt + ' - ' + dayJS().toISOString(),
        reviewMode: 'true'
      } satisfies QueryParams
    });
  };

  return (
    <div>
      {insight.insightClass === 'INSIGHT_ALERT_REVIEW' ? (
        <DetectionModal
          alerts={alertsToReview}
          isOpen={isOpen}
          onClose={onClose}
          loading={alertsLoading}
        />
      ) : null}
      <p
        className={twMerge(
          'ml-4 w-fit rounded-t-2xl border border-b-0 px-3 py-2 leading-4 text-white',
          backgroundColor
        )}
      >
        {label}
      </p>
      <div className='flex items-center justify-between space-y-1 rounded-2xl border bg-white px-6 py-3'>
        <div>
          <p className='text-secondary-text/50'>{getInsightAddress(insight, projectsData)}</p>
          <div>
            <p className='text-xl font-medium'>{getInsightSummary(insight)}</p>
            <p className='text-lg'>{getInsightDetails(insight)}</p>
          </div>
          <p className='text-secondary-text/50'>{getInsightActiveText(insight)}</p>
        </div>
        {insight.insightClass === 'INSIGHT_ALERT_REVIEW' ? (
          <button
            className={twMerge('flex h-fit items-center text-brand', alertsLoading && 'opacity-50')}
            onClick={navigateToDetections}
            disabled={alertsLoading}
            id='review-insight-alerts'
          >
            Review detections now
          </button>
        ) : null}
      </div>
    </div>
  );
};
