import type { NextRouter } from 'next/router';

import { DEFAULT_FILTERS, FilterLabels } from '../../../const';
import type { Filters, QueryParams } from '../../../types';
import { trackEvent } from '../../../utils';


export const removeFilter = (filter: FilterLabels, filters: Filters, router: NextRouter) => {
  trackEvent('filter-removed', { filter });
  const { [filter]: _, ...newFilters } = filters;

  if (filter === FilterLabels.PROJECT && 'deviceIds' in newFilters) {
    delete newFilters.deviceIds;
  }

  const differentFilters = Object.fromEntries(
    Object.entries(newFilters).filter(
      ([key, value]) => value !== DEFAULT_FILTERS[key as keyof typeof DEFAULT_FILTERS]
    )
  );

  const query = Object.fromEntries(
    Object.entries(router.query).filter(
      ([key]) => differentFilters[key] !== undefined
    )
  ) as QueryParams;

  router.replace({ query: { ...query, ...differentFilters } });
};

export const filterCloseDisabled = (filter: FilterLabels, filters: Filters) => {
  return (
    Object.keys(DEFAULT_FILTERS).includes(filter) &&
    DEFAULT_FILTERS[filter as keyof typeof DEFAULT_FILTERS] === filters[filter]
  );
};
