import type { SWRMutationConfig } from './useApiHooks';
import { useMutationApi } from './useApiHooks';
import type { ProjectUserRole } from '../../const';

interface AddProjectUserRequest {
  role: ProjectUserRole;
}

interface AddProjectUserPathParams {
  projectId: string;
  userId: string;
}

export const useAddProjectUser = (
  { projectId, userId }: AddProjectUserPathParams,
  swrConfig?: SWRMutationConfig<never>
) =>
  useMutationApi<AddProjectUserRequest>(
    'post',
    `/projects/${projectId}/users/${userId}`,
    undefined,
    swrConfig
  );
