import { ExclamationTriangleIcon, PlayIcon } from '@heroicons/react/24/outline';
import { useCallback, useContext } from 'react';

import {
  AlertType,
  DEFAULT_FILTERS,
  ENABLED_FILTERS_PER_ROUTE,
  FilterLabels
} from '../../../../const';
import { useGetCurrentRoute } from '../../../../hooks';
import { SelectedFiltersContext } from '../../../../providers';
import { trackEvent } from '../../../../utils';
import { ToggleGroup, type ToggleGroupOption } from '../../../atoms';

const alertTypeOptions: ToggleGroupOption<AlertType>[] = [
  {
    label: 'Critical',
    value: AlertType.CRITICAL,
    icon: <ExclamationTriangleIcon className='h-5 w-5' />
  },
  {
    label: 'General',
    value: AlertType.GENERAL,
    icon: <PlayIcon className='h-5 w-5 -rotate-90' />
  }
];

export const AlertTypeFilter: React.FC = () => {
  const { selectedFilters, setSelectedFilters } = useContext(SelectedFiltersContext);

  const currentRoute = useGetCurrentRoute();

  const handleOnChange = useCallback(
    (alertType: string[]) => {
      trackEvent('alert-type-filter-changed', { alertType });

      setSelectedFilters({
        ...selectedFilters,
        alertType: alertType.join(',')
      });
    },
    [selectedFilters, setSelectedFilters]
  );

  return (
    <ToggleGroup<string>
      label='Detection Type'
      options={alertTypeOptions}
      onChange={handleOnChange}
      value={selectedFilters.alertType}
      defaultValue={DEFAULT_FILTERS.alertType}
      disabled={!ENABLED_FILTERS_PER_ROUTE[currentRoute].includes(FilterLabels.ALERT_TYPE)}
    />
  );
};
