import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';

import { Button, Input } from '@fleet/components';

import { Modal } from '../../atoms';
import { useAuthContext } from '../../../hooks';
import { useCreateProject } from '../../../hooks/api/useCreateProject';
import type { AxiosErrorResponseBody } from '../../../utils';

interface CreateProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateProjectModal: React.FC<CreateProjectModalProps> = ({ isOpen, onClose }) => {
  const [name, setName] = useState<string>('');
  const { trigger: createProject } = useCreateProject();
  const { currentOrganizationId } = useAuthContext();

  const onSubmit = useCallback(async () => {
    try {
      const result = await createProject({
        name,
        organizationId: currentOrganizationId || ''
      });
      toast.success('Project created successfully.');
      setName('');
      onClose();
    } catch (error: unknown) {
      const message =
        error instanceof AxiosError
          ? (error.response?.data as AxiosErrorResponseBody).message
          : 'Failed to create project.';
      toast.error(message);
    }
  }, [createProject, name, currentOrganizationId, onClose]);

  return (
    <Modal title='Create Project' isOpen={isOpen} onClose={onClose}>
      <div className='space-y-4 [&_input]:w-full'>
        <Input label='Project Name *' value={name} onChange={e => setName(e.target.value)} />
        <div className='flex justify-between gap-4 pt-3'>
          <Button variant='mono' className='h-14 w-1/2 justify-center text-lg' onClick={onClose}>
            Cancel
          </Button>
          <Button variant='solid' className='h-14 w-1/2 justify-center text-lg' onClick={onSubmit}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
