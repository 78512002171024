import type { BarDatum, ResponsiveBarCanvasProps } from '@nivo/bar';
import { useContext, useMemo } from 'react';

import {
  generateAggregatedCriticalRatio,
  generateCSVData,
  generateGraphData,
  getCustomTooltip
} from './AnalyticsCriticalDetectionRatio.utils';
import { AnalyticsCard, AnalyticsGraph } from '../../molecules';
import { dayJS } from '../../../const';
import { AggregatedType, useGetAggregatedAlerts, useAuthContext } from '../../../hooks';
import { AnalyticsContext } from '../../../providers';
import { getStoredTimezone } from '../../../utils';

export const AnalyticsCriticalDetectionRatio: React.FC = () => {
  const { currentOrganizationId } = useAuthContext();
  const { analyticsFilters, dateAggregation, count } = useContext(AnalyticsContext);

  const {
    data: alertData,
    isLoading,
    error
  } = useGetAggregatedAlerts({
    queryParams: {
      timezone: getStoredTimezone(),
      alertTypes: [AggregatedType.ANY],
      organizationIds: currentOrganizationId,
      ...analyticsFilters
    },
    variant: dateAggregation
  });

  const periodEndAt = dayJS({ date: analyticsFilters.startAt })
    .add(analyticsFilters.reportingPeriodSecs, 's')
    .toISOString();

  const aggregatedCriticalRatio = useMemo(
    () =>
      generateAggregatedCriticalRatio({
        alertsData: alertData?.results,
        count,
        periodEndAt,
        dateAggregation
      }),
    [alertData?.results, count, dateAggregation, periodEndAt]
  );

  const graphData = useMemo(
    () => generateGraphData({ aggregatedCriticalRatio, periodEndAt, dateAggregation, count }),
    [aggregatedCriticalRatio, count, dateAggregation, periodEndAt]
  );

  const csvData = useMemo(
    () =>
      generateCSVData({
        aggregatedCriticalRatio,
        periodEndAt,
        count,
        dateAggregation
      }),
    [aggregatedCriticalRatio, count, dateAggregation, periodEndAt]
  );

  const graphProps: ResponsiveBarCanvasProps<BarDatum> = useMemo(
    () => ({
      maxValue: 100,
      data: graphData
    }),
    [graphData]
  );

  return (
    <AnalyticsCard
      header='Critical Detection Ratio'
      loading={isLoading}
      error={error?.response?.data.message}
    >
      <AnalyticsGraph
        csvData={csvData}
        csvLabel='critical_detection_ratio'
        count={count}
        yLabel='% Critical Detection Ratio'
        graphProps={graphProps}
        customTooltip={getCustomTooltip}
        periodEndAt={periodEndAt}
        dateAggregation={dateAggregation}
      />
    </AnalyticsCard>
  );
};
