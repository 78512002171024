import Link from 'next/link';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { Routes } from '../../../const';
import { useAuthContext } from '../../../hooks';

interface NoProjectsAssignedMessageProps {
  className?: string;
}

export const NoProjectsAssignedMessage: React.FC<NoProjectsAssignedMessageProps> = ({
  className
}) => {
  const { currentOrganization } = useAuthContext();
  return (
    <div className={twMerge('flex h-full flex-col items-center justify-center gap-2', className)}>
      <div>{`You are not a member of any projects in ${currentOrganization?.name}.`}</div>
      <div>Please contact your organisation administrator to be added to a project.</div>
      <div>
        <Link className='text-brand' href={Routes.ORGANIZATION}>
          Click here
        </Link>
        {' to see a list of organisation members.'}{' '}
      </div>
    </div>
  );
};
