import { useCallback } from 'react';
import toast from 'react-hot-toast';

import { Button } from '@fleet/components';

import { Modal } from '../../atoms';
import type { OrganizationUser } from '../../../hooks';
import { useDeleteOrganizationUser } from '../../../hooks/api/useDeleteOrganizationUser';
import { getUserFullName } from '../../../utils/User';

interface DeleteOrganizationUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  organizationUser?: OrganizationUser;
}

export const DeleteOrganizationUserModal: React.FC<DeleteOrganizationUserModalProps> = ({
  isOpen,
  onClose,
  organizationUser
}) => {
  const { trigger: removeUserFromOrganization } = useDeleteOrganizationUser(organizationUser);
  const onSubmit = useCallback(async () => {
    try {
      await removeUserFromOrganization();
      toast.success('Organisation user removed successfully.');
      onClose();
    } catch {
      toast.error('Failed to remove organisation user.');
    }
  }, [onClose, removeUserFromOrganization]);
  if (!organizationUser) return null;

  return (
    <Modal title='Remove Organisation User' isOpen={isOpen} onClose={onClose}>
      <div className='pb-2'>
        {`Are you sure you want to remove ${getUserFullName(organizationUser?.user) || organizationUser?.user.email} from the organisation?`}
      </div>
      <div className='flex justify-between gap-4 pt-3'>
        <Button variant='mono' className='h-14 w-1/2 justify-center text-lg' onClick={onClose}>
          Cancel
        </Button>
        <Button
          type='submit'
          variant='solid'
          className='h-14 w-1/2 justify-center text-lg'
          onClick={onSubmit}
        >
          Remove
        </Button>
      </div>
    </Modal>
  );
};
