import { ArrowDownRightIcon } from '@heroicons/react/24/outline';
import type { BarDatum, ComputedDatum } from '@nivo/bar';
import { twMerge } from 'tailwind-merge';

import { DateAggregation, dayJS } from '../../../const';
import type { DeviceMetrics } from '../../../hooks';
import type { AggregatedAnalyticsRuntime } from '../../../types';
import { generateCSVDates, generateGraphDates } from '../../../utils';

export const generateAggregatedRuntime = ({
  deviceMetrics,
  count,
  periodEndAt,
  dateAggregation
}: {
  deviceMetrics: DeviceMetrics[] | undefined;
  count: number;
  periodEndAt: string;
  dateAggregation: DateAggregation;
}): AggregatedAnalyticsRuntime => {
  const aggregatedPoweredOn = new Array<number>(count).fill(0);
  const aggregatedDetectionsEnabled = new Array<number>(count).fill(0);
  const aggregatedDetectionsDisabled = new Array<number>(count).fill(0);

  const diffUnit = dateAggregation === DateAggregation.WEEKLY ? 'week' : 'day';

  deviceMetrics?.map(({ periodStartAt, disabledTimeSecs, enabledTimeSecs, runTimeSecs }) => {
    const offset = Math.floor(
      count - dayJS({ date: periodEndAt }).diff(dayJS({ date: periodStartAt }), diffUnit, true)
    );

    aggregatedPoweredOn[offset] += runTimeSecs;
    aggregatedDetectionsEnabled[offset] += enabledTimeSecs;
    aggregatedDetectionsDisabled[offset] += disabledTimeSecs;
  });

  return { aggregatedPoweredOn, aggregatedDetectionsEnabled, aggregatedDetectionsDisabled };
};

export const generateGraphData = ({
  currDisabledTime,
  currEnabledTime,
  currRunTime,
  dateAggregation,
  count,
  periodEndAt
}: {
  currDisabledTime: number[];
  currEnabledTime: number[];
  currRunTime: number[];
  dateAggregation: DateAggregation;
  count: number;
  periodEndAt: string;
}) => {
  return currDisabledTime.map((_, index) => ({
    ...(dateAggregation === DateAggregation.DAILY
      ? { day: generateGraphDates({ count, index, periodEndAt, dateAggregation }) }
      : { week: generateGraphDates({ count, index, periodEndAt, dateAggregation }) }),
    'Detections disabled': currDisabledTime[index] / 3600,
    'Detections enabled': currEnabledTime[index] / 3600,
    'Device powered': currRunTime[index] / 3600
  }));
};

export const generateCSVData = ({
  currDisabledTime,
  currEnabledTime,
  currRunTime,
  org,
  project,
  device,
  periodEndAt,
  dateAggregation,
  count
}: {
  currDisabledTime: number[];
  currEnabledTime: number[];
  currRunTime: number[];
  org?: string;
  project?: string;
  device?: string;
  periodEndAt: string;
  count: number;
  dateAggregation: DateAggregation;
}) => {
  return currDisabledTime.map((_, index) => ({
    ...(org ? { 'Organization name': org } : {}),
    ...(project ? { 'Project name': project } : {}),
    ...(device ? { 'Device name': device } : {}),
    ...generateCSVDates({ count, index, periodEndAt, dateAggregation }),
    'Detections disabled (s)': currDisabledTime[index],
    'Detections enabled (s)': currEnabledTime[index],
    'Device powered (s)': currRunTime[index]
  }));
};

export const ProcessedChangeAndLabel = ({
  percentChange,
  labelValue,
  label,
  comparisonCurrText
}: {
  percentChange: number;
  labelValue: string;
  label: string;
  comparisonCurrText: string | undefined;
}) => {
  if (Number.isNaN(percentChange)) return null;

  const processedChange =
    percentChange === Number.POSITIVE_INFINITY || percentChange >= 500 || percentChange <= -500
      ? ''
      : `${Math.round(percentChange)}% `;

  const iconRotation = percentChange < 0 ? null : '-rotate-90';

  return (
    <div className='text-sm'>
      <div className='flex h-8 items-center space-x-2'>
        <p className={twMerge('text-xl')}>{labelValue}</p>
        <p className='whitespace-nowrap text-tertiary-text'>{label}</p>
      </div>
      <div className={twMerge('flex items-center space-x-2')}>
        {percentChange === 0 ? null : (
          <ArrowDownRightIcon className={twMerge('h-4 w-4', iconRotation)} />
        )}
        <p>
          {processedChange}
          {comparisonCurrText}
        </p>
      </div>
    </div>
  );
};

export const legends = [
  {
    label: 'Detections disabled',
    icon: <div className='h-2 w-2 rounded-full bg-gray-500' />
  },
  {
    label: 'Detections enabled',
    icon: <div className='h-2 w-2 rounded-full bg-enterprise' />
  }
];

export const getBarColor = (bar: ComputedDatum<BarDatum>) => {
  if (bar.id === 'Detections enabled') return 'rgb(255, 125, 51)';

  return 'rgb(107 114 128)';
};
