import { Input } from '@headlessui/react';
import { CheckIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';

import { Spinner } from '@fleet/components';

type PrefixItem = {
  text: string;
  href?: string;
  className?: string;
};

type InlineTextEditor = {
  fieldValue: string;
  setFieldValue: (value: string) => void;
  defaultValue: string;
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
  rules?: {
    [key: string]: string;
  };
  saveValue: () => void;
  isMutating: boolean;
  canEdit: boolean;
  prefixItems?: PrefixItem[];
  emptyValueText?: string;
  validatePopup?: boolean;
  readModeClassName?: string;
  editModeClassName?: string;
  editIconClassName?: string;
};

export const InlineTextEditor: React.FC<InlineTextEditor> = ({
  fieldValue,
  setFieldValue,
  defaultValue,
  isEditMode,
  setIsEditMode,
  rules,
  saveValue,
  isMutating,
  canEdit,
  prefixItems = [],
  emptyValueText = '',
  validatePopup = false,
  readModeClassName = '',
  editModeClassName = '',
  editIconClassName = ''
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const saveButtonRef = useRef<SVGSVGElement>(null);
  const [error, setError] = useState<string[]>([]);

  const validate = useCallback(
    (value: string) => {
      if (!rules) return true;
      setError([]);
      const fieldErrors: string[] = [];
      for (const rule of Object.keys(rules)) {
        if (rule === 'required' && !value) {
          fieldErrors.push(rules[rule]);
        }
      }
      setError(prevError => [...prevError, ...fieldErrors]);
      return fieldErrors.length === 0;
    },
    [rules]
  );

  const handleCloseButton = useCallback(() => {
    setFieldValue(defaultValue);
    setIsEditMode(false);
  }, [defaultValue, setIsEditMode, setFieldValue]);

  const handleEditButton = useCallback(() => {
    setError([]);
    setIsEditMode(true);
    setTimeout(() => {
      if (inputRef.current) inputRef.current.focus();
    }, 10);
  }, [setIsEditMode]);

  const handleOrganisationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    validate(event.target.value);
    setFieldValue(event.target.value);
  };

  const saveFieldValue = useCallback(() => {
    if (!canEdit) return;
    if (!validate(fieldValue)) {
      if (validatePopup) {
        toast.error(error.join('\n'));
      }
      return;
    }
    saveValue();
  }, [fieldValue, saveValue, validate, canEdit, error, validatePopup]);

  const handleInputKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') saveFieldValue();
    if (event.key === 'Escape') handleCloseButton();
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const isInputClick = inputRef.current && inputRef.current.contains(event.target as Node);
      const isButtonClick =
        saveButtonRef.current && saveButtonRef.current.contains(event.target as Node);
      if (!isInputClick && !isButtonClick) {
        handleCloseButton();
      }
    },
    [handleCloseButton]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const PrefixItemsComponent = useMemo(() => {
    return (
      prefixItems.length > 0 &&
      prefixItems.map((prefixLink, index) =>
        prefixLink.href ? (
          <Link
            key={index}
            className={twMerge('py-2 pl-2 text-2xl font-extralight', prefixLink.className || '')}
            href={prefixLink.href}
          >
            {prefixLink.text} /
          </Link>
        ) : (
          <span
            key={index}
            className={twMerge('py-2 pl-2 text-2xl font-extralight', prefixLink.className || '')}
          >
            {prefixLink.text}
          </span>
        )
      )
    );
  }, [prefixItems]);

  return (
    <>
      {isEditMode ? (
        <>
          <div className='flex'>
            {PrefixItemsComponent}
            <div className='flex-grow'>
              <Input
                className={twMerge(
                  'ml-[2px] w-full rounded-md border border-neutral-200 py-[7px] pl-1 text-2xl shadow transition-[border,box-shadow] ease-out focus:border-brand/75 focus:outline-none focus:ring-1 focus:ring-brand/75',
                  error.length > 0 &&
                    'border-red-500 focus:border-red-500/75 focus:ring-red-500/75',
                  editModeClassName
                )}
                value={fieldValue}
                onChange={handleOrganisationChange}
                onKeyDown={handleInputKeydown}
                ref={inputRef}
              />
              {error.length > 0 && !validatePopup && (
                <p className='absolute mt-1 text-sm text-red-500'>{error.join('<br />')}</p>
              )}
            </div>
          </div>

          <div className='float-end mt-1 flex'>
            {isMutating ? (
              <Spinner className='mr-2 mt-1 size-6' />
            ) : (
              <>
                <XMarkIcon
                  className='size-9 cursor-pointer rounded-md border border-neutral-200 p-2 text-brand shadow'
                  onClick={handleCloseButton}
                />
                <CheckIcon
                  className='ml-1 size-9 cursor-pointer rounded-md border border-neutral-200 p-2 text-brand shadow'
                  onClick={saveFieldValue}
                  ref={saveButtonRef}
                />
              </>
            )}
          </div>
        </>
      ) : (
        <div className='inline-flex items-center'>
          {PrefixItemsComponent}
          <h1 className={twMerge('ml-[2px] py-2 pl-[5px] text-2xl', readModeClassName)}>
            {fieldValue || emptyValueText}
          </h1>
          {canEdit && (
            <PencilIcon
              className={twMerge('h-9 w-9 cursor-pointer p-2 text-brand', editIconClassName)}
              onClick={handleEditButton}
            />
          )}
        </div>
      )}
    </>
  );
};
