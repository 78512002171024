import { Error, Loading } from '@fleet/components';

interface AnalyticsCardProps extends React.HTMLAttributes<HTMLDivElement> {
  header: string;
  loading?: boolean;
  error?: string | string[];
  children: React.ReactNode;
  className?: string;
}

export const AnalyticsCard: React.FC<AnalyticsCardProps> = ({
  header,
  loading,
  error,
  children,
  className = '',
  ...rest
}) => {
  return (
    <div className={`flex space-x-8 rounded-2xl border bg-white p-8 ${className}`} {...rest}>
      <div className='w-40 p-3 text-xl uppercase text-gray-600'>
        <p>{header}</p>
      </div>
      <div className='min-w-0 flex-grow self-center'>
        {(() => {
          if (loading) return <Loading />;
          if (error) return <Error error={error} />;

          return children;
        })()}
      </div>
    </div>
  );
};
