import { useApi } from './useApiHooks';
import type { ProjectBase } from './useGetProjects';
import { generateURLSearchParams } from '../../utils';

export interface Device {
  id: string;
  name: string;
  deviceUnique: string;
  organizationId: string;
  projectId: string;
  serialNumber: string;
  hardwareVersion: string;
  softwareVersion: string;
  gsmImei: string;
  gsmIccid: string;
  lastSeen: string;
  project: ProjectBase;
  presence: boolean;
  xid?: number;
}

export interface GetDevicesQueryFilters {
  organizationIds?: string;
  projectIds?: string;
  deviceIds?: string;
}

export interface GetDevicesQuery extends GetDevicesQueryFilters {
  select?: (keyof Device)[];
}

export interface GetDevicesResponse {
  results?: Device[];
}

export const useGetDevices = (queryParams: GetDevicesQuery | undefined) => {
  const searchParams = generateURLSearchParams(queryParams);

  return useApi<GetDevicesResponse>(
    'get',
    queryParams === undefined ? null : `/devices${searchParams}`
  );
};
